/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';

import Card from '../../components/lesson/card';
import { categoriesDefaultInfo } from './category/constants/Category.constants';
import courseService from '../../services/course.service';
import NoDataFound from '../../components/commons/NoDataFound';
import { isModuleAllowed, sortByCompleted } from '../../utils/Utils';
import lessonService from '../../services/lesson.service';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import { useProfileContext } from '../../contexts/profileContext';
import SubHeading from '../../components/subheading';
import CardSkeleton from '../../components/lesson/CardSkeleton';
import { useTenantContext } from '../../contexts/TenantContext';
import StatsAndFilter, {
  FAVORITES_FILTER_OPTIONS_LIST,
} from '../../components/lesson/StatsAndFilter';

function LessonCard(props) {
  const { lesson, setLesson, topics, setRefresh } = props;
  const [icon, setIcon] = useState('');
  const params = useUrlSearchParams();
  const LearnViewTypes = params?.get('viewType');

  const { tenant } = useTenantContext();

  useEffect(() => {
    const { category } = lesson;

    const categoryInfo = category;

    if (categoryInfo) {
      const slug = categoryInfo.title
        .toLocaleLowerCase()
        .trim()
        .replace(/ /g, '-');

      const icon = categoriesDefaultInfo[slug]?.icon || 'summarize';
      setIcon(icon);
    }
  }, []);

  return (
    <Card
      item={lesson}
      setItem={setLesson}
      icon={icon}
      topics={topics}
      learnViewTypes={LearnViewTypes}
      sectionType={
        Object.hasOwn(lesson, 'is_learning_path') ? 'course' : 'lesson'
      }
      hasCataLogAccess={isModuleAllowed(
        tenant.modules,
        'learns_lesson_catalog'
      )}
      setRefresh={setRefresh}
    />
  );
}

function MyLessonsSection({
  data,
  setData,
  title,
  topics,
  setRefresh,
  loading,
  showFilter = false,
  filterOptionSelected,
  handleFilterSelect,
}) {
  if (loading) {
    return (
      <>
        <SubHeading title={title} headingStyle="mt-0 mb-0" />
        <div className="px-3_1 py-3">
          <CardSkeleton count={3} cols="row-cols-md-3" className="px-2" />
        </div>
      </>
    );
  }

  return (
    <>
      {data?.length > 0 && (
        <>
          <div
            className={`d-flex align-items-center justify-content-between ${
              showFilter ? 'mb-3' : 'mb-0'
            }`}
          >
            <SubHeading title={title} headingStyle="mt-0 mb-0" />
            {showFilter && (
              <StatsAndFilter
                handleFilterSelect={handleFilterSelect}
                filterOptionSelected={filterOptionSelected}
                loading={loading}
                type="Favorites"
                lessonKey={
                  title === 'Lessons' ? 'total_lessons' : 'total_courses'
                }
              />
            )}
          </div>
          <div className="px-3_1">
            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3">
              {data.map((lessonCourse, indx) => (
                <Col key={indx} className="mb-3 p-3 lesson-card-resize">
                  <LessonCard
                    lesson={lessonCourse}
                    setLesson={setData}
                    topics={topics}
                    setRefresh={setRefresh}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default function MyLessons({ topics, setRefresh }) {
  const limit = 1000; // default items count for this page
  const [loading, setLoading] = useState(true);
  const [lessons, setLessons] = useState([]);
  const [courses, setCourses] = useState([]);
  const { profileInfo } = useProfileContext();
  const [, setOwnerAccess] = useState(false);
  const [filterOptionSelected, setFilterOptionSelected] = useState(
    FAVORITES_FILTER_OPTIONS_LIST[0]
  );

  const getFiltersRequestByOption = (option, type) => {
    const { key } = option;
    const query = {};

    switch (key) {
      case 'latest':
        query.order = [
          ['progress.completed_at', 'asc nulls first'],
          ['progress.last_attempted_at', 'desc nulls last'],
          ['updated_at', 'desc'],
        ];
        break;
      case 'asc':
      case 'desc':
        if (type === 'Course') {
          query.order = [['name', key]]; // Sort by name for courses
        } else if (type === 'Lesson') {
          query.order = [['title', key]]; // Sort by title for lessons
        }
        break;
      default:
        // Default filter (e.g., 'All')
        query.order = [
          ['progress.completed_at', 'asc nulls first'],
          ['progress.last_attempted_at', 'desc nulls last'],
          ['updated_at', 'desc'],
        ];
        break;
    }

    return query;
  };

  async function getFavoriteLessonsAndCourses() {
    setLoading(true);

    const lessonsFilter = getFiltersRequestByOption(
      filterOptionSelected,
      'Lesson'
    );
    const coursesFilter = getFiltersRequestByOption(
      filterOptionSelected,
      'Course'
    );

    try {
      const [lessonsResponse, coursesResponse] = await Promise.all([
        lessonService.getLessons({
          page: 1,
          limit,
          self: true,
          favorites: 'required',
          progress: 'include',
          ...lessonsFilter,
        }),
        courseService.getCourses({
          page: 1,
          limit,
          self: true,
          favorites: 'required',
          progress: 'include',
          ...coursesFilter,
        }),
      ]);

      setLessons(sortByCompleted(lessonsResponse?.data));
      setCourses(sortByCompleted(coursesResponse?.data, 'courseTracking'));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getFavoriteLessonsAndCourses();
  }, [filterOptionSelected]);

  const handleFilterSelect = (e, option) => {
    e.preventDefault();
    setFilterOptionSelected(option);
  };

  const handleDataUpdate = (favoriteItem, type) => {
    if (type === 'lesson') {
      setLessons((prev) =>
        prev.filter((lesson) => lesson.id !== favoriteItem.id)
      );
    } else if (type === 'course') {
      setCourses((prev) =>
        prev.filter((course) => course.id !== favoriteItem.id)
      );
    }
    setRefresh((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (profileInfo?.role) {
      setOwnerAccess(profileInfo.role.owner_access);
    }
  }, [profileInfo]);

  return (
    <>
      <div className="px-3 position-relative page-header-divider">
        <SubHeading title="Favorites" headingStyle="m-0 p-0" />
      </div>

      <div className="mt-3 px-3">
        <MyLessonsSection
          data={courses}
          setData={(item) => handleDataUpdate(item, 'course')}
          loading={loading}
          topics={topics}
          setRefresh={setRefresh}
          title="Courses"
          handleFilterSelect={handleFilterSelect}
          filterOptionSelected={filterOptionSelected}
          showFilter={courses.length > 0 || lessons.length === 0}
        />

        <MyLessonsSection
          data={lessons}
          setData={(item) => handleDataUpdate(item, 'lesson')}
          loading={loading}
          topics={topics}
          setRefresh={setRefresh}
          title="Lessons"
          handleFilterSelect={handleFilterSelect}
          filterOptionSelected={filterOptionSelected}
          showFilter={courses.length === 0 && lessons.length > 0}
        />

        {!loading && lessons.length === 0 && courses.length === 0 && (
          <NoDataFound
            title="Your currently have no favorites"
            titleStyle="text-gray-search"
            description="To get started, explore available categories from the left menu."
            icon="manage_search"
            containerStyle="text-gray-search my-6 py-6"
          />
        )}
      </div>
    </>
  );
}
