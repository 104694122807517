import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { isModuleAllowed, isPermissionAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';
import { Badge } from 'react-bootstrap';
import TooltipComponent from '../lesson/Tooltip';
import MaterialIcon from './MaterialIcon';

const Counter = ({ moduleName, active, count, maxCount = 10 }) => {
  return (
    <>
      {count > 0 ? (
        <Badge
          style={{ width: 24, height: 24 }}
          className={`nav-count bg-primary-soft rounded-circle d-flex align-items-center justify-content-center ml-1 fs-9 p-1
          `}
        >
          {count > maxCount ? (
            <TooltipComponent title={`Total ${moduleName}: ${count}`}>
              <div className="d-flex gap-0">
                <span>{maxCount}</span>
                <span>+</span>
              </div>
            </TooltipComponent>
          ) : (
            count
          )}
        </Badge>
      ) : null}
    </>
  );
};

const TabItem = ({
  item,
  activeTab,
  toggle,
  animateStyle,
  setAnimateStyle,
  itemClasses = 'px-3 py-2 position-relative d-flex align-items-center',
  tabActiveClass = 'active',
  showCount,
  maxCount,
  tabCounts,
  newCustomTabs,
}) => {
  return (
    <NavItem
      onMouseOver={(e) => {
        setAnimateStyle({
          transform: `translateX(${e.target.offsetLeft}px)`,
          width: e.target.offsetWidth,
          height: e.target.offsetHeight,
        });
      }}
      onMouseLeave={() => setAnimateStyle({ ...animateStyle, width: 0 })}
      key={item.tabId}
      className={`pb-0 pt-1 tab-title ${
        activeTab === item.tabId ? 'nav-item-active' : ''
      }`}
    >
      <NavLink
        className={`${itemClasses} ${item.clazz} ${classnames({
          [tabActiveClass]: activeTab === item.tabId,
        })}`}
        onClick={() => {
          toggle(item);
        }}
      >
        {item.title}
        {showCount && (
          <Counter
            active={activeTab === item.tabId}
            moduleName={item.title}
            count={tabCounts[item.tabId || item.id] || 0}
            maxCount={maxCount}
          />
        )}
        {newCustomTabs && item.iconAdd && activeTab !== item.tabId && (
          <div className="nav-item-icon" onClick={item.iconOnClick}>
            {item.iconToolTip ? (
              <TooltipComponent title={item.iconToolTip}>
                <MaterialIcon icon="add_circle" size="fs-5" />
              </TooltipComponent>
            ) : (
              <MaterialIcon icon="add_circle" size="fs-5" />
            )}
          </div>
        )}
      </NavLink>
    </NavItem>
  );
};

const AnimatedTabs = ({
  containerClasses,
  tabClasses,
  tabItemClasses,
  tabActiveClass,
  tabsData,
  activeTab,
  toggle,
  permissionCheck,
  requiredAdminAccess,
  borderClasses = 'border-bottom-0',
  showCount = false,
  maxCount,
  tabCounts = {},
  newCustomTabs = false,
}) => {
  const [animateStyle, setAnimateStyle] = useState({});
  const { tenant } = useTenantContext();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const scrollContainerRef = useRef(null);
  const scrollContentRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    // Access the inner Nav element after the component mounts
    if (scrollContentRef.current) {
      navRef.current = scrollContentRef.current.querySelector('.nav-tabs');
    }
  }, [scrollContentRef]);

  useEffect(() => {
    const updateMaxItemsInView = () => {
      const containerWidth = scrollContainerRef.current?.offsetWidth || 0;
      const navWidth = navRef.current?.offsetWidth || 0;

      // If all tabs fit in the container, don't show arrows
      if (navWidth <= containerWidth) {
        setShowArrows(false);
      } else {
        setShowArrows(true);
      }
    };

    // Initial calculation and recalculate on window resize
    updateMaxItemsInView();
    window.addEventListener('resize', updateMaxItemsInView);

    return () => window.removeEventListener('resize', updateMaxItemsInView);
  }, [tabsData, scrollContentRef, navRef]);

  const handleScroll = (direction) => {
    const container = scrollContentRef.current;
    const navWidth = navRef.current?.offsetWidth || 0;

    // Calculate new scroll position based on direction
    let newPosition = scrollPosition + direction * container.offsetWidth;

    // Ensure the scroll position stays within bounds
    if (newPosition < 0) {
      newPosition = 0;
    } else if (newPosition > navWidth - container.offsetWidth) {
      newPosition = navWidth - container.offsetWidth;
    }
    setScrollPosition(newPosition);
    container.scrollTo({ left: newPosition, behavior: 'smooth' });
  };

  return (
    <div
      className={`horizontal-scroll-container horizontal-scroll-tabs ${
        showArrows ? 'horizontal-scroll-tabs-active' : ''
      } ${containerClasses} `}
      ref={scrollContainerRef}
    >
      {showArrows && (
        <div
          className={`arrow-scroll left-arrow ${
            scrollPosition === 0 ? 'faded' : ''
          }`}
          onClick={() => handleScroll(-1)}
        >
          <MaterialIcon icon="chevron_left" size="fs-3" />
        </div>
      )}
      <div
        className={`scrollable-content w-max-content ${borderClasses}`}
        ref={scrollContentRef}
      >
        <Nav
          className={`border-none position-relative w-max-content ${tabClasses} ${
            newCustomTabs ? 'new-custom-tabs' : ''
          }`}
          tabs
        >
          {!newCustomTabs && animateStyle.width > 0 && (
            <div
              className="position-absolute rounded bg-primary-soft"
              style={{
                transition: 'all ease-in 150ms',
                ...animateStyle,
              }}
            ></div>
          )}
          {tabsData.map((item) => (
            <>
              {permissionCheck && item.permission ? (
                <>
                  {isPermissionAllowed(
                    item.permission.collection,
                    item.permission.action
                  ) &&
                    isModuleAllowed(tenant?.modules, item?.module) && (
                      <TabItem
                        showCount={showCount}
                        maxCount={maxCount}
                        activeTab={activeTab}
                        item={item}
                        toggle={toggle}
                        animateStyle={animateStyle}
                        setAnimateStyle={setAnimateStyle}
                        itemClasses={tabItemClasses}
                        newCustomTabs={newCustomTabs}
                        tabCounts={tabCounts}
                        tabActiveClass={tabActiveClass}
                      />
                    )}
                </>
              ) : (
                <>
                  {requiredAdminAccess === true ? (
                    <TabItem
                      showCount={showCount}
                      maxCount={maxCount}
                      activeTab={activeTab}
                      item={item}
                      toggle={toggle}
                      tabCounts={tabCounts}
                      animateStyle={animateStyle}
                      setAnimateStyle={setAnimateStyle}
                      itemClasses={tabItemClasses}
                      newCustomTabs={newCustomTabs}
                      tabActiveClass={tabActiveClass}
                    />
                  ) : (
                    <>
                      {item?.byModule ? (
                        <>
                          {isModuleAllowed(tenant?.modules, item.byModule) ? (
                            <TabItem
                              showCount={showCount}
                              maxCount={maxCount}
                              activeTab={activeTab}
                              item={item}
                              toggle={toggle}
                              tabCounts={tabCounts}
                              animateStyle={animateStyle}
                              setAnimateStyle={setAnimateStyle}
                              itemClasses={tabItemClasses}
                              newCustomTabs={newCustomTabs}
                              tabActiveClass={tabActiveClass}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <TabItem
                          showCount={showCount}
                          maxCount={maxCount}
                          activeTab={activeTab}
                          item={item}
                          toggle={toggle}
                          tabCounts={tabCounts}
                          animateStyle={animateStyle}
                          setAnimateStyle={setAnimateStyle}
                          itemClasses={tabItemClasses}
                          newCustomTabs={newCustomTabs}
                          tabActiveClass={tabActiveClass}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ))}
        </Nav>
      </div>

      {showArrows && (
        <div
          className={`arrow-scroll right-arrow ${
            scrollPosition >=
            navRef.current?.offsetWidth -
              scrollContainerRef.current?.offsetWidth
              ? 'faded'
              : ''
          }`}
          onClick={() => handleScroll(1)}
        >
          <MaterialIcon icon="chevron_right" size="fs-3" />
        </div>
      )}
    </div>
  );
};

export default AnimatedTabs;
