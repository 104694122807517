import React, { useEffect, useReducer, useState } from 'react';
import { Card } from 'reactstrap';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import ValidationErrorText from '../../../components/commons/ValidationErrorText';

export default function KnowledgeAssessmentQuestionnaire({
  assessmentQuestions,
  finishQuestionnaire,
  setProgress,
  setCurrentQuestion,
}) {
  const [questions, setQuestions] = useState([...assessmentQuestions]);
  const defaultErrorMsg = 'Please make a selection to proceed.';

  const [assessmentQuestionnaire, updateAssessmentQuestionnaire] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      currentIndex: 0,
      currentQuestion: questions[0],
      error: false,
      errorMsg: defaultErrorMsg,
    }
  );

  useEffect(() => {
    setCurrentQuestion(assessmentQuestionnaire?.currentIndex || 0);
  }, [assessmentQuestionnaire]);

  const updateProgress = () => {
    setProgress(
      Math.round(
        ((assessmentQuestionnaire.currentIndex + 1) / questions.length) * 100
      )
    );
  };

  const answered = () => {
    updateAssessmentQuestionnaire({
      errorMsg: defaultErrorMsg,
    });
    return assessmentQuestionnaire.currentQuestion?.choices?.some(
      (opt) => opt.isSelected
    );
  };

  const validateMaxChoices = () => {
    const { currentQuestion } = assessmentQuestionnaire || {};

    if (!currentQuestion?.max) return false;

    const selectedChoices =
      currentQuestion?.choices?.filter((opt) => opt.isSelected) || [];

    const exceedsMax = selectedChoices.length !== currentQuestion.max;

    if (exceedsMax) {
      updateAssessmentQuestionnaire({
        errorMsg: `Please select ${currentQuestion?.max} or more choices.`,
      });
    }

    return exceedsMax;
  };

  useEffect(() => {
    updateAssessmentQuestionnaire({
      currentQuestion: questions[assessmentQuestionnaire.currentIndex],
      error: false,
    });
    updateProgress();
  }, [assessmentQuestionnaire.currentIndex]);

  const getCorrectAnswer = (q) => {
    const { choices } = q;
    const correctAnswers = choices.filter((ch) => ch.correctAnswer);

    // if its a question where we need to select max options
    const answersToUse = q.max
      ? choices.filter((ch) => ch.isSelected)
      : correctAnswers;

    return answersToUse.map((c) => c.answer).join(', ');
  };

  const handleNext = () => {
    // validate option selected from current question
    if (!answered() || validateMaxChoices()) {
      updateAssessmentQuestionnaire({ error: true });
      return;
    }

    if (assessmentQuestionnaire.currentIndex + 1 < questions.length) {
      updateAssessmentQuestionnaire({
        currentIndex: assessmentQuestionnaire.currentIndex + 1,
      });
    } else {
      // if its public then minus first one because we are handling it below
      const submissionRequest = {
        finalResult: questions.map((q) => {
          const selectedChoice = q.choices
            .filter((c) => c.isSelected)
            .map((c) => c.answer)
            .join(', ');
          return {
            id: q.id,
            title: q.title,
            answer: {
              correctAnswer: getCorrectAnswer(q), // correct answer(s) of question
              userSelected: selectedChoice, // what user selected
            },
          };
        }),
      };

      finishQuestionnaire({
        ...submissionRequest,
      });
    }
  };
  const handlePrev = () => {
    updateAssessmentQuestionnaire({
      currentIndex: assessmentQuestionnaire.currentIndex - 1,
    });
  };

  const markCurrentSelected = (choices, selectedOption) => {
    return choices.map((choice) => ({
      ...choice,
      isSelected: selectedOption.id === choice.id,
    }));
  };

  const markMultiple = (choices, selectedOption) => {
    return choices.map((choice) => ({
      ...choice,
      isSelected:
        selectedOption.id === choice.id
          ? !choice.isSelected
          : choice.isSelected,
    }));
  };

  const saveAnswer = (selectedOption, index, quest) => {
    let updatedQuestion = {};

    if (quest.type === 'single') {
      updatedQuestion = {
        ...assessmentQuestionnaire.currentQuestion,
        choices: markCurrentSelected(
          assessmentQuestionnaire.currentQuestion.choices,
          selectedOption
        ),
      };
    } else {
      // multiple selection and save
      updatedQuestion = {
        ...assessmentQuestionnaire.currentQuestion,
        choices: markMultiple(
          assessmentQuestionnaire.currentQuestion.choices,
          selectedOption
        ),
      };
    }
    updateAssessmentQuestionnaire({ currentQuestion: updatedQuestion });

    setQuestions(
      [...questions].map((question) =>
        question.id === updatedQuestion.id ? { ...updatedQuestion } : question
      )
    );
  };

  const NextButton = () => {
    return (
      <div className="d-flex align-items-center font-weight-medium gap-1 px-3">
        <span>Next</span>
        <MaterialIcon icon={'arrow_forward_ios'} clazz="font-size-md" />
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-center m-auto align-items-center w-100">
      <div className="w-100 px-md-0 px-sm-0 p-lg-4">
        <h1
          className="mb-3 m-auto"
          dangerouslySetInnerHTML={{
            __html: assessmentQuestionnaire.currentQuestion.title,
          }}
        />
        {assessmentQuestionnaire.currentQuestion?.choices.map((opt, index) => (
          <Card
            onClick={() =>
              saveAnswer(opt, index, assessmentQuestionnaire.currentQuestion)
            }
            key={opt.id}
            className={`mb-2 p-2 option-hover ${
              opt.isSelected ? 'selected' : ''
            }`}
          >
            <div className="d-flex gap-2 align-items-center">
              <MaterialIcon
                icon={
                  !opt.isSelected
                    ? assessmentQuestionnaire.currentQuestion.type === 'single'
                      ? 'radio_button_unchecked'
                      : 'check_box_outline_blank'
                    : assessmentQuestionnaire.currentQuestion.type === 'single'
                    ? 'radio_button_checked'
                    : 'check_box'
                }
                clazz={`font-size-2xl checked-icon ${
                  opt.isSelected ? 'text-primary' : ''
                }`}
              />
              <p className="mb-0 text-left">{opt.answer}</p>
            </div>
          </Card>
        ))}

        {assessmentQuestionnaire.currentQuestion.type === 'multiple' ? (
          <p className="fs-8 mt-1 text-left">Select one or more answers</p>
        ) : null}
        {assessmentQuestionnaire.error && (
          <div
            className={`transition fadeIn ${
              assessmentQuestionnaire.error ? 'opacity-1' : 'opacity-0'
            }`}
          >
            <ValidationErrorText
              text={assessmentQuestionnaire.errorMsg}
              extraClass="my-0 bg-soft-red rounded p-2 position-relative text-left"
            />
          </div>
        )}

        <div className="navigation d-flex align-items-center mt-6 justify-content-between">
          {assessmentQuestionnaire.currentIndex + 1 > 1 && (
            <ButtonIcon
              icon="arrow_back_ios"
              label="Back"
              color="outline-primary"
              classnames="btn-sm font-weight-medium pl-2 pr-3"
              iconClass="font-size-md font-weight-medium"
              onclick={handlePrev}
            />
          )}
          {assessmentQuestionnaire.currentIndex + 1 <= questions.length && (
            <ButtonIcon
              label={
                assessmentQuestionnaire.currentIndex + 1 < questions.length ? (
                  <NextButton />
                ) : (
                  'Finish'
                )
              }
              color="primary"
              classnames={`btn-sm ml-auto d-flex ${
                assessmentQuestionnaire.currentIndex + 1 < questions.length
                  ? 'pl-3 pr-2'
                  : 'px-3'
              }`}
              onclick={handleNext}
            />
          )}
        </div>
      </div>
    </div>
  );
}
