import React, { useEffect, useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
} from 'reactstrap';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import InputValidation from '../commons/InputValidation';
import {
  DATE_FORMAT,
  DATE_FORMAT_DASHED_TZ,
  DATE_FORMAT_EJS,
  setDateFormat,
} from '../../utils/Utils';
import ReactDatepicker from '../inputs/ReactDatpicker';
import moment from 'moment/moment';
import ContentTab from './partials/ContentTab';
import AssigneesTab from './partials/AssigneesTab';
import assignmentService from '../../services/assignment.service';
import Asterick from '../commons/Asterick';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import MaterialIcon from '../commons/MaterialIcon';
import DropdownSelect from '../DropdownSelect';

const ModalCreateAssignment = ({
  onUpdate,
  setOpenAssignModal,
  dataToEdit,
  isEdit,
  setModified,
}) => {
  const [selectedContentData, setSelectedContentData] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [activeTab, setActiveTab] = useState('assign_content');
  const [assignmentTitle, setAssignmentTitle] = useState('');
  const [optionalEmailMessage, setOptionalEmailMessage] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [teamCount, setTeamCount] = useState(0);
  const [peopleCount, setPeopleCount] = useState(0);
  const defaultAssignmentForm = {
    title: '',
    content: [],
    assignees: [],
    dueAt: '',
    message: '',
  };
  const [assignmentForm, setAssignmentForm] = useState(defaultAssignmentForm);
  const [reminderFieldValue, setReminderFieldValue] = useState(
    'will not receive a reminder'
  );
  const [reminder, setReminder] = useState(false);

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: defaultAssignmentForm,
  });

  const handleContentDataChange = (data) => {
    setSelectedContentData(data);
    setAssignmentForm({ ...assignmentForm, content: data });
  };
  const handleAssigneesDataChange = (data) => {
    setSelectedAssignees(data);
    setAssignmentForm({ ...assignmentForm, assignees: data });
  };

  useEffect(() => {
    const setDisAbled =
      (activeTab === 'assign_content' && selectedContentData.length === 0) ||
      (activeTab === 'assign_assignees' && selectedAssignees.length === 0) ||
      (activeTab === 'assign_scheduling' && dueDate === '') ||
      !assignmentTitle;

    setIsBtnDisabled(setDisAbled);
  }, [
    activeTab,
    selectedContentData,
    selectedAssignees,
    dueDate,
    assignmentTitle,
  ]);

  useEffect(() => {
    if (isEdit) {
      setAssignmentTitle(dataToEdit.title);
      setOptionalEmailMessage(dataToEdit.message);
      setDueDate(new Date(setDateFormat(dataToEdit.dueAt)));
      setAssignmentForm({ ...assignmentForm, content: selectedContentData });
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      setAssignmentForm({
        ...assignmentForm,
        content: selectedContentData,
        assignees: selectedAssignees,
        title: assignmentTitle,
        message: optionalEmailMessage,
        dueAt: moment(dueDate).format(DATE_FORMAT_DASHED_TZ),
      });
    }

    // Calculate the count of selected people and teams
    const counts = selectedAssignees.reduce((acc, item) => {
      const itemType = item.type;
      acc[itemType] = (acc[itemType] || 0) + 1;
      return acc;
    }, {});
    setTeamCount(counts.team || 0);
    setPeopleCount(counts.people || 0);
  }, [selectedContentData, assignmentTitle, selectedAssignees, dueDate]);

  const toggle = (tab) => {
    if (selectedContentData.length > 0) {
      if (activeTab !== tab) {
        if (tab === 'assign_assignees' && assignmentTitle.trim() === '') {
          setErrorMessage('Assignment title should not be empty');
          return;
        }
        if (tab === 'assign_review' && dueDate === '') {
          setErrorMessage('Due date should not be empty');
          return;
        }
        setActiveTab(tab);
      }
    }
  };

  const getButtonText = () => {
    switch (activeTab) {
      case 'assign_content':
        return 'Next: Select Assignees';
      case 'assign_assignees':
        return 'Next: Schedule Assignment';
      case 'assign_scheduling':
        return 'Next: Review and Assign';
      case 'assign_review':
        return isEdit ? 'Update Assignment' : 'Send Assignment';
    }
  };

  const onDueDateChange = (value, type) => {
    if (value) {
      if (type === 'dueDate') {
        setDueDate(value);
        setAssignmentForm({
          ...assignmentForm,
          dueAt: moment(value).format(DATE_FORMAT_DASHED_TZ),
        });
      }
    }
  };

  const handleTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setDueDate(tomorrow);
    setAssignmentForm({
      ...assignmentForm,
      dueAt: moment(tomorrow).format(DATE_FORMAT_DASHED_TZ),
    });
  };

  const handleValueChange = (e) => {
    const { value } = e.target;
    setAssignmentTitle(value);
    setAssignmentForm({ ...assignmentForm, title: value });
  };

  const handleChange = (e) => {
    const target = e.target;
    setOptionalEmailMessage(target.value);
    setAssignmentForm({ ...assignmentForm, message: target.value });
  };

  const handleSendAssignment = async (type) => {
    const tabOrder = [
      'assign_content',
      'assign_assignees',
      'assign_scheduling',
      'assign_review',
    ];
    const currentIndex = tabOrder.indexOf(activeTab);
    let nextTab = '';
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (assignmentTitle.trim() === '' && type === 'assign_content') {
      setErrorMessage('Assignment title should not be empty');
      return;
    } else if (assignmentForm.dueAt === '' && type === 'assign_scheduling') {
      setErrorMessage('Due date should not be empty');
      return;
    } else if (
      assignmentForm.dueAt !== '' &&
      type === 'assign_scheduling' &&
      dueDate < currentDate
    ) {
      setErrorMessage('The due date should not be earlier than today.');
      return;
    } else {
      nextTab = tabOrder[currentIndex + 1];
    }

    if (currentIndex < tabOrder.length - 1) {
      toggle(nextTab);
    }

    // Make the final call to create Assignment
    if (type === 'assign_review') {
      setIsBtnDisabled(true);

      // Making separate Arrays for Assignees
      const teamArray = selectedAssignees
        .filter((item) => item.type === 'team')
        .map((item) => ({ teamId: item.teamId }));

      const peopleArray = selectedAssignees
        .filter((item) => item.type === 'people')
        .map((item) => ({ userId: item.userId }));

      let assignmentID = '';
      let modAssign = `Assignment Created`;

      if (!isEdit) {
        // Request to create an Assignment
        const response = await assignmentService.createAssignment({
          title: assignmentForm?.title,
          status: 'active',
          dueAt: assignmentForm?.dueAt,
          message: assignmentForm?.message,
        });
        assignmentID = response.assignmentId;
      } else {
        assignmentID = dataToEdit.assignmentId;

        const updateTitleDueDate = {
          title: assignmentForm.title,
          dueAt: assignmentForm.dueAt,
          message: assignmentForm?.message,
        };

        await assignmentService.updateAssignmentById(
          assignmentID,
          updateTitleDueDate
        );
        modAssign = `Assignment Saved`;
      }

      // Request to create/update an Assignment Content for Courses & Lessons
      if (assignmentID) {
        await assignmentService.createAssignmentContent(
          assignmentID,
          assignmentForm.content
        );
      }

      // Request to assign people/teams to an Assignment
      const assignObject = {
        teams: teamArray,
        users: peopleArray,
        resendReminderIfAlreadyAssigned: reminder,
      };

      if (assignmentID) {
        await assignmentService.upsertAssignedEntries(
          assignmentID,
          assignObject
        );
      }

      setOpenAssignModal(false);
      onUpdate(modAssign);
      setModified((prevState) => prevState + 1);
    }
  };

  const removeSelectedData = (dataToRemove, setData) => {
    setData((prevData) => prevData.filter((item) => item !== dataToRemove));
  };

  const handleRemoveItems = (dataToRemove, setData) => {
    removeSelectedData(dataToRemove, setData);
  };

  return (
    <div className="row">
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <div className="col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 pl-2">
        <TabContent activeTab={activeTab} className="pt-3 pb-3">
          <TabPane tabId="assign_content">
            <div className="content-box-wrp">
              <h2 className="mb-4">What are you assigning?</h2>
              <FormGroup>
                <Label for="title">
                  Assignment Title <Asterick />
                </Label>
                <InputValidation
                  name="title"
                  type="input"
                  placeholder="Assignment Title"
                  value={assignmentTitle}
                  validationConfig={{
                    required: true,
                    inline: false,
                    onChange: handleValueChange,
                  }}
                  errors={errors}
                  register={register}
                />
              </FormGroup>
              <ContentTab
                onContentDataChange={handleContentDataChange}
                selectedContentData={selectedContentData}
                setSelectedContentData={setSelectedContentData}
                dataToEdit={dataToEdit}
                isEdit={isEdit}
              />
            </div>
          </TabPane>
          <TabPane tabId="assign_assignees">
            <AssigneesTab
              onAssigneesDataChange={handleAssigneesDataChange}
              selectedAssignees={selectedAssignees}
              setSelectedAssignees={setSelectedAssignees}
              dataToEdit={dataToEdit}
              isEdit={isEdit}
            />
          </TabPane>
          <TabPane tabId="assign_scheduling">
            <div className="content-box-wrp">
              <h2 className="mb-4">When is this content due?</h2>
              <div className="card">
                <ul className="list-group border-1 list-group-flush">
                  <li className="list-group-item">
                    <h4 className="mb-2">
                      Due Date <Asterick />
                    </h4>
                    <div className="row mt-2 align-items-center">
                      <div className="col-xl-3">
                        <ReactDatepicker
                          id={'dueDate'}
                          name={'dueDate'}
                          value={dueDate}
                          format={DATE_FORMAT_EJS}
                          minDate={new Date()}
                          autoComplete="off"
                          todayButton="Today"
                          validationConfig={{}}
                          placeholder={'Select Date'}
                          className="form-control bg-transparent"
                          onChange={(date) => onDueDateChange(date, 'dueDate')}
                        />
                      </div>
                      <div className="col-xl-3">
                        <a
                          className="btn-link decoration-underline cursor-pointer text-primary"
                          onClick={handleTomorrowDate}
                        >
                          Tomorrow
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="assign_review">
            <div className="content-box-wrp">
              <h2 className="mb-4">Ready to assign?</h2>
              <p>
                Before sending assignments, confirm the details bellow. You can
                always revisit an earlier step if you need to make adjustments.
              </p>
              <div className="card p-3">
                <p className="mb-1">
                  <strong>{selectedContentData.length} content items</strong>{' '}
                  will be assigned on{' '}
                  <strong>{moment(new Date()).format('MMMM DD, YYYY')}</strong>
                  {(peopleCount > 0 || teamCount > 0) && <span> to </span>}
                  {peopleCount > 0 && <strong>{peopleCount} people</strong>}
                  {peopleCount > 0 && teamCount > 0 && <span> and </span>}
                  {teamCount > 0 && <strong>{teamCount} team(s)</strong>}.
                </p>
                <p className="d-flex gap-2 align-items-center justify-content-between dropdown-autwidth">
                  People who already have this content assigned
                  <DropdownSelect
                    placeholder="will not receive a reminder"
                    customClasses={'overflow-y-auto max-h-300 w-70'}
                    data={[
                      'will not receive a reminder',
                      'will receive a reminder',
                    ].map((item, i) => {
                      return {
                        id: i,
                        name: item,
                      };
                    })}
                    onHandleSelect={(selectedItem) => {
                      if (selectedItem.name === 'will receive a reminder') {
                        setReminderFieldValue(selectedItem.name);
                        setReminder(true);
                      } else {
                        setReminderFieldValue(selectedItem.name);
                        setReminder(false);
                      }
                    }}
                    select={reminderFieldValue}
                    validationConfig={{
                      required: false,
                    }}
                  />
                </p>
                <FormGroup>
                  <Label className="pl-0">
                    <strong>Optional Assignment Email Message</strong>
                  </Label>
                  <InputValidation
                    name="name"
                    type="textarea"
                    placeholder="Message"
                    value={optionalEmailMessage}
                    validationConfig={{
                      required: true,
                      onChange: handleChange,
                      maxLength: {
                        value: 255,
                        message: 'Description cannot exceed 255 characters.',
                      },
                    }}
                    errors={errors}
                    register={register}
                  />
                </FormGroup>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
      <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 right-col border-left p-0 bg-white position-relative">
        {assignmentTitle && (
          <>
            <div className="mb-0 p-2 border-bottom">
              <h4 className="mt-2">Assignment Title</h4>
              <div className="bg-primary-soft text-primary text-left d-flex gap-2 p-2 mt-1 mb-0 justify-content-between rounded">
                {assignmentTitle}
              </div>
            </div>
          </>
        )}
        <Nav tabs vertical>
          <NavItem className="w-100">
            <NavLink
              className={
                `border-bottom ` +
                classnames({ active: activeTab === 'assign_content' })
              }
              onClick={() => {
                toggle('assign_content');
              }}
            >
              <h4 className="d-flex justify-content-between align-items-center mb-0">
                Content
                <span className="badge badge-pill badge-secondary">
                  {selectedContentData.length}
                </span>
              </h4>
              {selectedContentData.map((course, index) => (
                <div
                  className="bg-primary-soft text-left d-flex gap-2 p-2 mt-1 justify-content-between rounded"
                  key={index}
                >
                  {course.name}
                  <MaterialIcon
                    icon="close"
                    clazz="cursor-pointer"
                    onClick={() =>
                      handleRemoveItems(course, setSelectedContentData)
                    }
                  />
                </div>
              ))}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                `border-bottom ` +
                classnames({ active: activeTab === 'assign_assignees' })
              }
              onClick={() => {
                toggle('assign_assignees');
              }}
            >
              <h4 className="d-flex justify-content-between align-items-center mb-0">
                Assignees
                <span className="badge badge-pill badge-secondary">
                  {selectedAssignees.length}
                </span>
              </h4>
              <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
                {selectedAssignees.map((singleAssignee, index) => (
                  <div
                    className="bg-primary-soft text-left d-flex gap-2 p-2 mt-1 justify-content-between rounded"
                    key={index}
                  >
                    {singleAssignee.name}
                    <MaterialIcon
                      icon="close"
                      clazz="cursor-pointer"
                      onClick={() =>
                        handleRemoveItems(singleAssignee, setSelectedAssignees)
                      }
                    />
                  </div>
                ))}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                `border-bottom ` +
                classnames({ active: activeTab === 'assign_scheduling' })
              }
              onClick={() => {
                toggle('assign_scheduling');
              }}
            >
              <h4 className="d-flex justify-content-between align-items-center m-0">
                Scheduling
              </h4>
              {dueDate && dueDate !== '' && (
                <div className="bg-primary-soft text-left d-flex gap-2 p-2 mt-1 mb-0 justify-content-between rounded">
                  <span>Due Date:</span> {moment(dueDate).format(DATE_FORMAT)}
                </div>
              )}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                `border-bottom ` +
                classnames({ active: activeTab === 'assign_review' })
              }
              onClick={() => {
                toggle('assign_review');
              }}
            >
              <h4 className="d-flex justify-content-between align-items-center m-0">
                Review and Assign
              </h4>
            </NavLink>
          </NavItem>
        </Nav>
        <div className="btn-assignment pb-2 pl-2 position-absolute bottom-0 w-100">
          <button
            className={`btn btn-primary w-100 ${
              isBtnDisabled ? 'disabled not-allowed' : ''
            }`}
            disabled={isBtnDisabled}
            onClick={() => handleSendAssignment(activeTab)}
          >
            {getButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateAssignment;
