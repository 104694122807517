import React, { useContext, useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import Categories from '../views/settings/Resources/Categories';
import ManageLessons from '../pages/ManageLessons';
import { TabsContext } from '../contexts/tabsContext';
import Courses from '../views/settings/Resources/Courses';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import PageTitle from '../components/commons/PageTitle';
import Assignments from '../components/Assignments/Assignments';
import { useProfileContext } from '../contexts/profileContext';
import { isModuleAllowed } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import lessonService from '../services/lesson.service';
import courseService from '../services/course.service';
import categoryService from '../services/category.service';
import assignmentService from '../services/assignment.service';

const Resources = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [itemsOption, setItemsOptions] = useState([]);
  const { activatedTab, setActivatedTab } = useContext(TabsContext);
  const { profileInfo } = useProfileContext();
  const { tenant } = useTenantContext();
  const [modified, setModified] = useState(0);

  const roleInfo = profileInfo?.role;
  const isAdmin = roleInfo?.admin_access;

  useEffect(() => {
    if (activatedTab[location.pathname]) {
      setActiveTab(activatedTab[location.pathname]);
    }
  }, []);
  const [tabCounts, setTabCounts] = useState({});

  // setModified((prevState) => prevState + 1);

  const tabsData = [
    {
      title: 'Lessons',
      module: 'settings_learn',
      component: <ManageLessons setModified={setModified} />,
      icon: 'list_alt',
      tabId: 1,
      permission: {
        collection: 'lessons',
        action: 'view',
      },
    },
    {
      title: 'Courses',
      module: 'settings_learn',
      component: <Courses setModified={setModified} />,
      icon: 'summarize',
      tabId: 2,
      permission: {
        collection: 'courses',
        action: 'view',
      },
    },
    {
      title: 'Categories',
      module: 'settings_learn',
      component: <Categories setModified={setModified} />,
      icon: 'category',
      tabId: 3,
      permission: {
        collection: 'categories',
        action: 'view',
      },
    },
    {
      title: 'Assign',
      component: <Assignments setModified={setModified} />,
      tabId: 4,
      byModule: 'learns_assignments',
    },
  ];

  const getCurrentUser = async () => {
    try {
      if (profileInfo.role.owner_access) {
        setItemsOptions(tabsData);
      } else {
        const newItems = tabsData.filter(
          (item) => item.title !== 'Customization'
        );

        setItemsOptions(newItems);
      }
      if (
        !isModuleAllowed(tenant.modules, 'learns_lesson_catalog') &&
        !profileInfo.role.admin_access
      ) {
        const filteredData = tabsData.filter(
          (item) =>
            item.title !== 'Lessons' &&
            item.title !== 'Courses' &&
            item.title !== 'Categories'
        );
        setItemsOptions(filteredData);
        setActiveTab(5);
      }
      if (profileInfo?.role?.admin_access) {
        const hideAssigmentTab = tabsData.filter(
          (item) => item.title !== 'Assign'
        );
        setItemsOptions(hideAssigmentTab);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);

      setActivatedTab({
        [location.pathname]: tab.tabId,
      });
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, [profileInfo]);

  const getCounts = async () => {
    const pagination = { page: 1, limit: 1 };
    const restrictByCondition = isAdmin ? 'externalPublic' : '';

    const countRequests = [
      {
        id: 1,
        label: 'Lessons',
        apiCall: () =>
          lessonService.getLessons({
            ...pagination,
            ...(restrictByCondition && { restrictBy: restrictByCondition }),
          }),
      },
      {
        id: 2,
        label: 'Courses',
        apiCall: () =>
          courseService.getCourses({
            ...pagination,
            ...(restrictByCondition && { restrictBy: restrictByCondition }),
            deleted: false,
          }),
      },
      {
        id: 3,
        label: 'Categories',
        apiCall: () =>
          categoryService.GetCategories(
            {},
            {
              ...pagination,
              ...(isAdmin
                ? { tenantId: tenant?.id, restrictBy: 'internal' }
                : {}),
            }
          ),
      },
      {
        id: 4,
        label: 'Assignments',
        apiCall: () =>
          assignmentService.getAssignments({
            ...pagination,
            retrieveAssigned: false,
          }),
      },
    ];

    // Filter out "Assignments" if the admin_access condition is true
    const filteredCountRequests = profileInfo?.role?.admin_access
      ? countRequests.filter((request) => request.label !== 'Assignments')
      : countRequests;

    try {
      const responses = await Promise.allSettled(
        filteredCountRequests.map((request) => request.apiCall())
      );

      const updatedCounts = filteredCountRequests?.map((request, index) => {
        const isFulfilled = responses[index].status === 'fulfilled';
        const responseValue = isFulfilled ? responses[index].value : null;
        const count = responseValue?.pagination?.count || 0;

        return {
          id: request.id,
          label: request.label,
          count,
        };
      });
      const newTabCounts = { ...tabCounts };
      updatedCounts.forEach((cnt, index) => {
        newTabCounts[cnt.id] = cnt.count;
      });
      setTabCounts(newTabCounts);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getCounts();
    }
  }, [profileInfo?.id, modified]);

  return (
    <>
      <PageTitle page={itemsOption.find((t) => t.tabId === activeTab)?.title} />

      <AnimatedTabs
        showCount
        maxCount={99}
        tabsData={itemsOption}
        activeTab={activeTab}
        toggle={toggle}
        permissionCheck={true}
        tabCounts={tabCounts}
        tabClasses="px-3"
        borderClasses="bg-white border-bottom"
      />

      <TabContent>
        <TabPane className="position-relative">
          {tabsData.find((item) => item.tabId === activeTab)?.component}
        </TabPane>
      </TabContent>
    </>
  );
};

export default Resources;
