export const ModulesList = [
  {
    id: 1,
    name: 'Home',
    label: 'Home',
    group: 'Home',
  },
  {
    id: 8,
    name: 'Accounts',
    label: 'Accounts',
    group: 'Accounts',
    children: [
      {
        id: 59,
        name: 'Companies',
        label: 'Companies',
        grandchildren: [
          {
            id: 59,
            name: 'Timeline',
            label: 'Timeline',
          },
          {
            id: 60,
            name: 'Notes',
            label: 'Notes',
          },
          {
            id: 61,
            name: 'Files',
            label: 'Files',
          },
          {
            id: 65,
            name: 'Related Companies',
            label: 'Related Companies',
          },
          {
            id: 9,
            name: 'Sidebar',
            label: 'Sidebar',
            great_grandchildren: [
              {
                id: 10,
                name: 'Generate Pre-Call Plan',
                label: 'Generate Pre-Call Plan',
              },
            ],
          },
        ],
      },
      {
        id: 11,
        name: 'Contacts',
        label: 'Contacts',
        grandchildren: [
          {
            id: 62,
            name: 'Timeline',
            label: 'Timeline',
          },
          {
            id: 63,
            name: 'Notes',
            label: 'Notes',
          },
          {
            id: 64,
            name: 'Files',
            label: 'Files',
          },
          {
            id: 12,
            name: 'Sidebar',
            label: 'Sidebar',
            great_grandchildren: [
              {
                id: 13,
                name: 'Generate Pre-Call Plan',
                label: 'Generate Pre-Call Plan',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 7,
    name: 'Client Portal',
    label: 'Client Portal (Digital Sales Room)',
    group: 'Client Portal',
  },
  {
    id: 2,
    name: 'Engagement',
    label: 'Engagement',
    group: 'Engagement',
    children: [
      {
        id: 3,
        name: 'Treasury Management',
        label: 'Treasury Management',
      },
      {
        id: 4,
        name: 'Working Capital',
        label: 'Working Capital',
      },
      {
        id: 411,
        name: 'Working Capital Loan Overview',
        label: 'Working Capital Loan Overview',
      },
      {
        id: 5,
        name: 'Merchant Services',
        label: 'Merchant Services',
      },
      {
        id: 6,
        name: 'Account Structure',
        label: 'Account Structure',
      },
      {
        id: 7,
        name: 'Fraud',
        label: 'Fraud',
      },
      {
        id: 711,
        name: 'Widgets Library',
        label: 'Widgets Library',
      },
      {
        id: 712,
        name: 'Commercial Card',
        label: 'Commercial Card',
      },
    ],
  },
  {
    id: 14,
    name: 'Pipelines',
    label: 'Opportunities',
    group: 'Opportunities',
    children: [
      {
        id: 55,
        name: 'Timeline',
        label: 'Timeline',
      },
      {
        id: 56,
        name: 'Notes',
        label: 'Notes',
      },
      {
        id: 57,
        name: 'Files',
        label: 'Files',
      },
      {
        id: 58,
        name: 'Products',
        label: 'Products',
      },
      {
        id: 59,
        name: 'Stage History',
        label: 'Stage History',
      },
    ],
  },
  {
    id: 15,
    name: 'Activities',
    label: 'Activities',
    group: 'Activities',
    children: [
      {
        id: 66,
        name: 'Company Activities',
        label: 'Companies Activities',
      },
      {
        id: 67,
        name: 'Contact Activities',
        label: 'Contacts Activities',
      },
      {
        id: 68,
        name: 'Pipeline Activities',
        label: 'Opportunities Activities',
      },
    ],
  },
  {
    id: 16,
    name: 'Prospecting',
    label: 'Prospecting',
    group: 'Prospecting',
    children: [
      {
        id: 17,
        name: 'Company',
        label: 'Companies',
        grandchildren: [
          {
            id: 18,
            name: 'Import',
            label: 'Import',
          },
          {
            id: 19,
            name: 'Export',
            label: 'Export',
          },
        ],
      },
      {
        id: 20,
        name: 'Peoples',
        label: 'Peoples',
        grandchildren: [
          {
            id: 21,
            name: 'Import',
            label: 'Import',
          },
          {
            id: 22,
            name: 'Export',
            label: 'Export',
          },
        ],
      },
      {
        id: 23,
        name: 'Prospect Lookup Sidebar',
        label: 'Prospect Lookup Sidebar',
      },
    ],
  },
  {
    id: 24,
    name: 'AI Assist',
    label: 'AI Assist',
    group: 'AI Assist',
    children: [
      {
        id: 25,
        name: 'Write',
        label: 'Write',
      },
      {
        id: 26,
        name: 'Summarize',
        label: 'Summarize',
      },
      {
        id: 27,
        name: 'Ask',
        label: 'Ask',
      },
    ],
  },
  {
    id: 28,
    name: 'Reporting',
    label: 'Reporting',
    group: 'Reporting',
    children: [
      {
        id: 78,
        name: 'Checklists',
        label: 'Checklists',
      },
      {
        id: 33,
        name: 'Training',
        label: 'Training',
      },
    ],
  },
  {
    id: 36,
    name: 'Learns',
    label: 'Learn',
    group: 'Learn',
    children: [
      {
        id: 37,
        name: 'Lesson Catalog',
        label: 'Lesson Catalog',
      },
      {
        id: 371,
        name: 'Assignments',
        label: 'Assignments',
      },
      {
        id: 79,
        name: 'Self Assessment',
        label: 'Self Assessment',
        group: 'Self Assessment',
      },
      {
        id: 791,
        name: 'Knowledge Assessment',
        label: 'Knowledge Assessment',
        group: 'Knowledge Assessment',
      },
      {
        id: 792,
        name: 'AI Advisor',
        label: 'Ask AI Advisor',
        group: 'AI Advisor',
      },
    ],
  },
  {
    id: 53,
    name: 'Checklist',
    label: 'Checklists',
    group: 'Checklists',
  },
  {
    id: 38,
    name: 'Settings',
    label: 'Settings',
    group: 'Settings',
    children: [
      {
        id: 39,
        name: 'Profile',
        label: 'Profile',
      },
      {
        id: 40,
        name: 'Branding',
        label: 'Branding',
      },
      {
        id: 41,
        name: 'Users and Controls',
        label: 'Users and Controls',
      },
      {
        id: 42,
        name: 'Learn',
        label: 'Learn',
      },
      {
        id: 43,
        name: 'Notifications',
        label: 'Notifications',
      },
      {
        id: 44,
        name: 'Products',
        label: 'Products',
      },
      {
        id: 45,
        name: 'Pipeline and Stages',
        label: 'Opportunities and Stages',
      },
      {
        id: 46,
        name: 'Fields',
        label: 'Fields',
        grandchildren: [
          {
            id: 47,
            name: 'Module Fields',
            label: 'Module Fields',
          },
          {
            id: 48,
            name: 'Pipeline Fields',
            label: 'Opportunities Fields',
          },
        ],
      },
      {
        id: 49,
        name: 'Checklist',
        label: 'Checklists',
      },
      {
        id: 50,
        name: 'Data Mapper',
        label: 'Data Mapper',
      },
      {
        id: 51,
        name: 'Integrations',
        label: 'Integrations',
      },
    ],
  },
];
