import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';

import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import ModalCreateCategory from '../../../components/modal/ModalCreateCategory.component';
import DeleteCategoryButton from '../../../components/categories/DeleteCategoryButton';
import CategoriesTable from '../../../components/categories/CategoriesTable';
import categoryService from '../../../services/category.service';
import LayoutHead from '../../../components/commons/LayoutHead';
import { sortingTable } from '../../../utils/sortingTable';
import TableSelectedCount from '../../../components/prospecting/v2/common/TableSelectedCount';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import { DataFilters } from '../../../components/DataFilters';
import {
  SEARCH_CATEGORIES,
  DELETE_CATEGORY,
  SERVER_ERROR,
  paginationDefault,
} from '../../../utils/constants';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { useProfileContext } from '../../../contexts/profileContext';
import { useTenantContext } from '../../../contexts/TenantContext';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../Deals/contacts/utils';

const Categories = ({ setModified }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [filterSelected, setFilterSelected] = useState({});
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [categories, setCategories] = useState([]);
  const [editId, setEditId] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoriesToDelete, setCategoriesToDelete] = useState([]);

  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState(['updated_at', 'DESC']);
  const [showLoading, setShowLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const { profileInfo } = useProfileContext();
  const { tenant } = useTenantContext();
  const roleInfo = profileInfo?.role;
  const isAdmin = roleInfo?.admin_access;

  const getCategories = async (count) => {
    setLoading(true);
    try {
      const response = await categoryService.GetCategories(
        { order, ...filterSelected },
        {
          ...(isAdmin ? { tenantId: tenant?.id, restrictBy: 'internal' } : ''),
          page: paginationPage.page,
          limit: paginationPage.limit,
        }
      );

      const { data, pagination: dataPagination } = response || {};
      setCategories(data || []);
      setPagination(dataPagination || paginationDefault);

      setDataInDB(Boolean(dataPagination?.totalPages));
      setShowLoading(false);
      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setErrorMessage(SERVER_ERROR);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getCategories(true);
    }
  }, [paginationPage, order, filterSelected, profileInfo]);

  const onSuccess = (message) => {
    setSuccessMessage(message);
  };

  const onError = (message) => {
    setErrorMessage(message);
  };

  const handleEdit = (row) => {
    if (isAdmin || (!isAdmin && !row.isPublic)) {
      setEditId(row.id);
      setShowCreateModal(true);
    }
  };

  const handleDelete = (category) => {
    setCategoriesToDelete([category]);
    // only allow deleting if category has 0 lessons and courses
    if (!category.totalLessons && !category.totalCourses) {
      setShowDeleteModal(true);
    } else {
      setErrorMessage(
        'Can’t delete category. Move all Lessons and Courses to another Category before deleting.'
      );
    }
  };

  const handleDeleteCategory = async () => {
    const category = categoriesToDelete[0];
    await categoryService.deleteCategory(category?.id);
    setCategoriesToDelete([]);
    setShowDeleteModal(false);
    getCategories();
    setModified((prevState) => prevState + 1);
    setErrorMessage(DELETE_CATEGORY);
  };

  const sortTable = ({ name }) => sortingTable({ name, order, setOrder });

  const clearSelection = () => {
    setSelectAll(false);
    setSelectedCategories([]);
  };

  const permissions = {
    collection: 'categories',
    action: 'create',
  };

  return (
    <div>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      <Card className="mb-0 card-0 rounded-0">
        <Card.Header className="flex-column align-items-unset px-0">
          <div className="d-flex align-items-center px-3">
            <DataFilters
              filterSelected={filterSelected}
              setFilterSelected={setFilterSelected}
              searchPlaceholder={SEARCH_CATEGORIES}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />

            <div className="d-flex align-items-center gap-2">
              {selectedCategories.length > 0 && (
                <TableSelectedCount
                  list={selectedCategories}
                  containerPadding="pr-0"
                  btnClick={selectedCategories}
                  btnClass="btn-sm text-white"
                  btnIcon="add"
                  btnLabel="Delete"
                  btnColor="success"
                  onClear={clearSelection}
                  customButton={
                    <DeleteCategoryButton
                      setSelectedCategories={setSelectedCategories}
                      selectedCategories={selectedCategories}
                      getCategories={getCategories}
                      categories={categories}
                      clearSelection={clearSelection}
                    />
                  }
                />
              )}
              <LayoutHead
                onHandleCreate={() => {
                  setShowCreateModal(true);
                }}
                buttonLabel={'Add Category'}
                orientationDelete
                dataInDB={isAdmin ? dataInDB : false}
                permission={permissions}
                alignTop="mb-0"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {showLoading && <TableSkeleton cols={4} rows={12} />}

          {!showLoading && (
            <CategoriesTable
              dataSource={categories}
              tableLoading={loading}
              paginationInfo={pagination}
              onPageChange={(newPage) => {
                changePaginationPage(newPage, setPaginationPage);
                setSelectedCategories([]);
              }}
              onLimitChange={(perPage) => {
                changePaginationLimit(perPage, setPaginationPage);
                setSelectedCategories([]);
              }}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              dataInDB={dataInDB}
              setShowCreateModal={setShowCreateModal}
              sortingTable={sortTable}
              sortingOrder={order}
              permission={permissions}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
          )}
        </Card.Body>
      </Card>

      <ModalCreateCategory
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        onSuccess={onSuccess}
        onError={onError}
        editId={editId}
        setEditId={setEditId}
        getCategories={getCategories}
        setModified={setModified}
      />

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        setSelectedCategories={setCategoriesToDelete}
        event={handleDeleteCategory}
        itemsConfirmation={categoriesToDelete}
        itemsReport={[]}
      />
    </div>
  );
};

export default Categories;
