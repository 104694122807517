export const categoriesDefaultInfo = {
  'working-capital': {
    name: 'Working Capital',
    icon: 'savings ',
  },
  ach: {
    name: 'ACH',
    icon: 'account_balance  ',
  },
  merchant: {
    name: 'Merchant',
    icon: 'store',
  },
  'business-cards': {
    name: 'Business Cards',
    icon: 'credit_card',
  },
  lockbox: {
    name: 'Lockbox',
    icon: 'mail',
  },
  hrm: {
    name: 'HRM',
    icon: 'health_and_safety',
  },
  wires: {
    name: 'Wires',
    icon: 'bolt',
  },
  'sales-strategy': {
    name: 'Sales Strategy',
    icon: 'school',
  },
  identifee: {
    name: 'Identifee',
    icon: 'web',
  },
  'agile-mindset': {
    name: 'Agile Mindset',
    icon: 'psychology',
  },
  'faster-payments': {
    name: 'Faster Payments',
    icon: 'price_check',
  },
  'fraud-&-risk': {
    name: 'Fraud & Risk',
    icon: 'crisis_alert',
  },
};

export const removeAndReplaceParams = (
  location,
  paramsToRemove,
  replacements
) => {
  const searchParams = new URLSearchParams(location.search);
  const viewType = searchParams.get('viewType');

  // Handle viewType=custom e.g for My Organizations
  if (viewType === 'custom') {
    const keysToRemove = [];
    searchParams.forEach((_, key) => {
      if (key !== 'viewType') {
        keysToRemove.push(key);
      }
    });

    // remove all other keys
    keysToRemove.forEach((key) => searchParams.delete(key));

    // add ref=my-organization
    searchParams.set('ref', 'my-organization');

    return `${location.pathname}?${searchParams.toString()}`;
  }

  // remove the customViewType parameters
  paramsToRemove.forEach((param) => searchParams.delete(param));

  // replace keys for category page
  Object.entries(replacements).forEach(([oldKey, newKey]) => {
    if (searchParams.has(oldKey)) {
      const value = searchParams.get(oldKey);
      searchParams.delete(oldKey);
      searchParams.set(newKey, value);
    }
  });

  // if viewType is neither 'custom', 'my-assignments', 'my-learning'
  // and if theres no 'id' for category in the URL, redirect to my-organization
  const hasId = searchParams.has('id');
  if (
    viewType !== 'custom' &&
    viewType !== 'my-assignments' &&
    viewType !== 'my-learning' &&
    !hasId
  ) {
    searchParams.set('viewType', 'custom');
    searchParams.set('ref', 'my-organization');
  }

  return `${location.pathname}?${searchParams.toString()}`;
};
