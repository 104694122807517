import React, { createContext, useContext, useState, useMemo } from 'react';
import useIsTenant from '../hooks/useIsTenant';

const FLAG_NAMES = {
  DISABLE_REPORT_FILE_PARSING: 'DISABLE_REPORT_FILE_PARSING',
  DISABLE_REPORT_TREASURY_FEES: 'DISABLE_REPORT_TREASURY_FEES',
};

const TenantFlagsContext = createContext();

export const TenantFlagsContextProvider = ({ children }) => {
  const { isComericaBank, isFFB } = useIsTenant();

  // for now hardcoding based on tenants, will update this once we have tenant module config logic is placed
  const [flags, setFlags] = useState(() => ({
    [FLAG_NAMES.DISABLE_REPORT_FILE_PARSING]: isComericaBank === true,
    [FLAG_NAMES.DISABLE_REPORT_TREASURY_FEES]: isFFB === true,
  }));

  const value = useMemo(
    () => ({ flags, setFlags, FLAG_NAMES }),
    [flags, FLAG_NAMES]
  );

  return (
    <TenantFlagsContext.Provider value={value}>
      {children}
    </TenantFlagsContext.Provider>
  );
};

export const useTenantFlags = () => useContext(TenantFlagsContext);
