import AccountsWidget from '../widgets/AccountsWidget';
import BalanceTrendsWidget from '../widgets/BalanceTrendsWidget';
import {
  DisclaimerData,
  DisclaimerDataFFB,
  DPOFTIData,
  DSOFTIData,
  ElectronicPaymentsToGrowthData,
  FraudMitigationData,
  HoursSpentWeeklyData,
  HSWRData,
} from '../../../reportbuilder/constants/widgetsConstants';
import FactorsThatImpactDSODPOWidget from '../../../reportbuilder/widgets/horizontal/FactorsThatImpactDSODPOWidget';
import React, { useState } from 'react';
import ElectronicPaymentsForGrowthWidget from '../../../reportbuilder/widgets/horizontal/ElectronicPaymentsForGrowthWidget';
import HoursSpentWeeklyReconcilingPaymentsWidget from '../../../reportbuilder/widgets/horizontal/HoursSpentWeeklyReconcilingPaymentsWidget';
import { FormCheck } from 'react-bootstrap';
import { usePagesContext } from '../../../../contexts/pagesContext';
import {
  AccountSchematicReportSections,
  AccountSchematicReportSectionsWidgets,
  AccountStructureTabMap,
} from '../account.structure.constants';
import QRCodeWidget from '../../../reportbuilder/widgets/horizontal/QRCodeWidget';
import HoursSpentWeeklyWidget from '../../../reportbuilder/widgets/horizontal/HoursSpentWeeklyWidget';
import DisclaimerWidget from '../../../reportbuilder/widgets/horizontal/DisclaimerWidget';
import useIsTenant from '../../../../hooks/useIsTenant';

const WithActiveToggle = ({ children, widgets, name, handleChange }) => {
  const checked = widgets[name]?.isActive;
  return (
    <div className="position-relative w-100">
      {children}
      <div className="position-absolute right-0 m-2_1 top-0">
        <FormCheck
          type="switch"
          id={name}
          name={name}
          onChange={handleChange}
          custom={true}
          checked={checked}
          label="Active"
        />
      </div>
    </div>
  );
};
const Widgets = ({ report, setReport, errorFields, setErrorFields }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const { isFFB } = useIsTenant();
  const jsonKey = AccountStructureTabMap.Widgets.key;
  const [widgets, setWidgets] = useState(
    pageContext.AccountStructureReportModal.Widgets ||
      AccountSchematicReportSectionsWidgets
  );
  const handleActiveChange = (e) => {
    const { name, checked } = e.target;
    const updatedWidgets = {
      ...widgets,
      [name]: {
        isActive: checked,
      },
    };
    setWidgets(updatedWidgets);
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          ...updatedWidgets,
        },
      },
    });
  };
  return (
    <div className="d-flex align-items-center flex-column w-100 gap-3">
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.AccountStructure}
        handleChange={handleActiveChange}
      >
        <AccountsWidget />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.BalanceTrends}
        handleChange={handleActiveChange}
      >
        <BalanceTrendsWidget />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.WCDPO}
        handleChange={handleActiveChange}
      >
        <FactorsThatImpactDSODPOWidget widgetConfig={DPOFTIData} />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.AccountsPayable}
        handleChange={handleActiveChange}
      >
        <ElectronicPaymentsForGrowthWidget
          widgetConfig={ElectronicPaymentsToGrowthData}
        />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.WCDSO}
        handleChange={handleActiveChange}
      >
        <FactorsThatImpactDSODPOWidget widgetConfig={DSOFTIData} />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.AccountsReceivable}
        handleChange={handleActiveChange}
      >
        <HoursSpentWeeklyReconcilingPaymentsWidget widgetConfig={HSWRData} />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.BalanceTrendsFraud}
        handleChange={handleActiveChange}
      >
        <QRCodeWidget widgetConfig={FraudMitigationData} />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.AccountsReceivableAutomating}
        handleChange={handleActiveChange}
      >
        <HoursSpentWeeklyWidget widgetConfig={HoursSpentWeeklyData} />
      </WithActiveToggle>
      <WithActiveToggle
        widgets={widgets}
        name={AccountSchematicReportSections.Disclaimer}
        handleChange={handleActiveChange}
      >
        <DisclaimerWidget
          widgetConfig={{ data: isFFB ? DisclaimerDataFFB : DisclaimerData }}
          headingStyles=""
        />
      </WithActiveToggle>
    </div>
  );
};
export default Widgets;
