import React, { useState } from 'react';
import MaterialIcon from './MaterialIcon';
import { Accordion } from 'react-bootstrap';

const MenuAccordion = ({
  options,
  className = 'list-unstyled mb-0',
  wrapperClass = '',
  showCount = false,
  conditionalCount = {},
  defaultCollapsedIds = [],
}) => {
  const [activeKey, setActiveKey] = useState(
    options
      ?.filter((item) => !defaultCollapsedIds.includes(item.id))
      .map((item) => item.id)
  );

  const toggleAccordion = (id) => {
    setActiveKey((prevActiveKey) =>
      prevActiveKey.includes(id)
        ? prevActiveKey.filter((key) => key !== id)
        : [...prevActiveKey, id]
    );
  };

  return (
    <>
      <ul className={className}>
        {options?.map((item, index) => {
          const isCollapsed = !activeKey.includes(item.id);
          const isShowCount =
            showCount &&
            item.count &&
            (conditionalCount[item.id] ? isCollapsed : true);

          return (
            <li key={index} className={`accordion-wrapper ${wrapperClass}`}>
              <Accordion
                activeKey={activeKey?.includes(item.id) ? item.id : null}
              >
                <Accordion.Toggle
                  onClick={() => toggleAccordion(item.id)}
                  eventKey={item.id}
                  className={`${
                    activeKey?.includes(item.id)
                      ? 'bg-accordion-active rounded-top rounded-left-0 rounded-right-0 rounded-bottom-0'
                      : 'bg-white'
                  } accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
                >
                  <div className="d-flex align-items-center">
                    <span className="material-symbols-rounded fs-20">
                      {item.icon}
                    </span>
                    <span className="ml-2 text-capitalize py-0">
                      {item.label}
                    </span>
                    {isShowCount ? (
                      <div
                        className={`ml-2 d-flex justify-content-center bg-primary align-items-center rounded-circle size-18`}
                      >
                        <span className={`fs-8 font-weight-normal text-white`}>
                          {item.count}
                        </span>
                      </div>
                    ) : null}
                    <MaterialIcon
                      clazz="ml-auto"
                      icon={
                        activeKey.includes(item.id)
                          ? 'keyboard_arrow_up'
                          : 'keyboard_arrow_down'
                      }
                    />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey={item.id}
                  className="accordion-content-wrapper"
                >
                  {activeKey?.includes(item.id) ? (
                    <div className="py-0 overflow-x-hidden">
                      {item.component}
                    </div>
                  ) : (
                    <div />
                  )}
                </Accordion.Collapse>
              </Accordion>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default MenuAccordion;
