import React, { useEffect, useState } from 'react';

import ManagementCourses from '../../Resources/courses/ManageCourses';
import ListCourses from '../../Resources/courses/ListCourses';

const CoursesView = ({ setModified }) => {
  const [create, setCreate] = useState(false);
  const [id, setId] = useState();

  useEffect(() => {
    if (!create) {
      setId(null);
    }
  }, [create]);
  return (
    <>
      {create ? (
        <ManagementCourses
          currentCourseId={id}
          setCreate={setCreate}
          setModified={setModified}
        />
      ) : (
        <ListCourses
          setCreate={setCreate}
          setId={setId}
          setModified={setModified}
        />
      )}
    </>
  );
};

export default CoursesView;
