import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import Table from '../../../components/GenericTable';
import { initialFilters, organizationColumns } from './Contacts.constants';
import organizationService from '../../../services/organization.service';
import {
  OWNER,
  paginationDefault,
  COMPANY_CREATED,
  ADD_INSIGHT,
  INSIGHT_CREATED,
} from '../../../utils/constants';
import OrganizationForm from '../../../components/organizations/OrganizationForm';
import {
  changePaginationLimit,
  changePaginationPage,
  reducer,
  removeCustomFieldsFromActivityForm,
} from './utils';
import userService from '../../../services/user.service';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import routes from '../../../utils/routes.json';
import { orgDynamicFields } from '../../../components/organizations/organizationFormsFields';
import DeleteModal from '../../../components/modal/DeleteModal';

import stringConstants from '../../../utils/stringConstants.json';
import LayoutHead from '../../../components/commons/LayoutHead';
import { sortingTable } from '../../../utils/sortingTable';
import {
  DATE_FORMAT_MONTH,
  RIGHT_PANEL_WIDTH,
  endOfLastWeekString,
  endOfWeekString,
  setDateFormat,
  splitAddress,
  startOfLastWeekString,
  startOfWeekString,
} from '../../../utils/Utils';
import FilterTabsButtonDropdown from '../../../components/commons/FilterTabsButtonDropdown';
import fieldService from '../../../services/field.service';
import { useForm } from 'react-hook-form';
import RightPanelModal from '../../../components/modal/RightPanelModal';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import { groupBy } from 'lodash';
import { usePagesContext } from '../../../contexts/pagesContext';
import { useProfileContext } from '../../../contexts/profileContext';
import IdfOwnersHeader from '../../../components/idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import useIsTenant from '../../../hooks/useIsTenant';
import { useModuleContext } from '../../../contexts/moduleContext';
import TableRowHover from '../../../components/commons/TableRowHover';
import Avatar from '../../../components/Avatar';
import TextOverflowTooltip from '../../../components/commons/TextOverflowTooltip';
import IdfTooltip from '../../../components/idfComponents/idfTooltip';
import TableFooterStats from '../../../components/TableFooterStats';

const organizationConstants = stringConstants.deals.organizations;

const defaultFilter = {
  id: 6,
  type: 'filter',
  key: 'RecentlyModified',
  name: 'Recently Modified',
};

const ORGANIZATION_FILTER_OPTIONS_LIST = [
  { ...defaultFilter },
  { id: 5, type: 'filter', key: 'RecentlyCreated', name: 'Recently Created' },
  { id: 4, type: 'filter', key: 'AddedThisWeek', name: 'Added This Week' },
  { id: 3, type: 'filter', key: 'AddedLastWeek', name: 'Added Last Week' },
];

const Organizations = ({
  selectedOwnership,
  setSelectedOwnership,
  stats,
  isViewMore,
  setIsViewMore,
}) => {
  const organizationObj = {
    name: '',
  };
  const {
    register,
    handleSubmit,
    reset,
    getFieldState,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: organizationObj,
  });
  const { pageContext, setPageContext } = usePagesContext();
  const { moduleMap } = useModuleContext();
  const { profileInfo } = useProfileContext();

  const isSynovus = useIsTenant().isSynovusBank;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [modal, setModal] = useState(false);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [filtersItems, setFiltersItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [order, setOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters] = useReducer(reducer, initialFilters);
  const history = useHistory();
  const [showDeleteOrgModal, setShowDeleteOrgModal] = useState(false);
  const [deleteResults, setDeleteResults] = useState([]);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [preOwners, setPreOwners] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterTabs, setFilterTabs] = useState('filters');
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [isFieldsObj, setIsFieldsObj] = useState(organizationObj);
  const [customFields, setCustomFields] = useState([]);
  const [addButtonLabel, setButtonLabel] = useState(ADD_INSIGHT);
  const [selectedOwner, setSelectedOwner] = useState('');

  const [modified, setModified] = useState(
    pageContext?.OrganizationsPage?.isModified || 0
  );

  const getIsModified = () => {
    return pageContext?.OrganizationsPage?.isModified || 0;
  };
  const [filterOptionSelected, setFilterOptionSelected] = useState(
    pageContext?.OrganizationsPage?.filterOptionSelected
  );
  const defaultFilterSelected = {
    filter: {},
  };
  const [filterSelected, setFilterSelected] = useState(
    pageContext?.OrganizationsPage?.selectedFilter ?? defaultFilterSelected
  );
  useEffect(() => {
    // whenever filter applied from left side, remove existing filter
    if (selectedOwnership === 'My' && filterOptionSelected?.type === 'owner') {
      setFilterOptionSelected({});
      setFilterSelected(defaultFilterSelected);
      setSelectedOwner('');
      setPageContext({
        ...pageContext,
        OrganizationsPage: {
          ...pageContext.OrganizationsPage,
          filterOptionSelected: {},
          selectedFilter: defaultFilterSelected,
          isModified: getIsModified() + 1,
          user: '',
        },
      });
    } else if (
      selectedOwnership === 'All' &&
      pageContext?.OrganizationsPage?.user &&
      pageContext?.OrganizationsPage?.user !== '' &&
      filterOptionSelected?.type === 'owner'
    ) {
      onHandleFilterOrg(pageContext?.OrganizationsPage?.user);
    }

    setPaginationPage(paginationDefault);
    setModified((prevState) => prevState + 1);
  }, [selectedOwnership]);

  useEffect(() => {
    if (!isSynovus && moduleMap.organization)
      setButtonLabel(`Add ${moduleMap.organization.singular}`);
  }, [moduleMap.organization]);

  useEffect(() => {
    if (isViewMore) {
      const syntheticEvent = {
        preventDefault: () => {}, // Prevent default is required for the function
      };

      handleFilterSelect(syntheticEvent, defaultFilter);

      // Reset `isViewMore` to prevent repeated triggers
      setIsViewMore(false);
    }
  }, [isViewMore]);

  const handleFilterSelect = (e, status) => {
    setPaginationPage(paginationDefault);
    e.preventDefault();
    setOpenFilter(false);

    let newFilterSelected = {
      ...filterSelected,
    };
    if (status.key === 'AddedLastWeek') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {
          startDate: startOfLastWeekString,
          endDate: endOfLastWeekString,
        },
      };
    } else if (status.key === 'AddedThisWeek') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {
          startDate: startOfWeekString,
          endDate: endOfWeekString,
        },
      };
    } else if (status.key === 'RecentlyCreated') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: {},
      };
      setOrder(['date_entered', 'desc']);
    } else if (status.key === 'RecentlyModified') {
      newFilterSelected = {
        ...newFilterSelected,
        filter: { recent_activity: true },
      };
    }

    const hasFilters = Object.keys(newFilterSelected.filter);

    if (!hasFilters.length) delete newFilterSelected.filter;

    setFilterSelected(newFilterSelected);
    setFilterOptionSelected(status);
    setModified((prevState) => prevState + 1);
    setPageContext({
      ...pageContext,
      OrganizationsPage: {
        ...pageContext.OrganizationsPage,
        filterOptionSelected: status,
        selectedFilter: newFilterSelected,
        isModified: getIsModified() + 1,
      },
    });
  };

  async function onGetUsers() {
    const response = await userService
      .getUsers(
        {
          search: '',
          users: [],
          filters: '',
        },
        {}
      )
      .catch((err) => err);
    const { data } = response || {};
    const newFilterOptions = filtersItems.slice();
    newFilterOptions.push({
      id: newFilterOptions.length,
      label: OWNER,
      name: 'assigned_user_id',
      options: data?.users,
      type: 'search',
    });
    setFiltersItems(newFilterOptions);
  }

  const getOrganizations = async (count) => {
    if (Object.keys(filterSelected).length || modified > 0 || order?.length) {
      setTableLoading(true);

      let newFilterSelected = {
        ...filterSelected,
        filter: {
          ...filterSelected.filter,
        },
      };

      if (selectedOwnership === 'My') {
        newFilterSelected = {
          ...newFilterSelected,
          filter: {
            ...newFilterSelected.filter,
            assigned_user_id: profileInfo?.id,
            self: true,
          },
        };
      } else if (selectedOwnership === 'All') {
        newFilterSelected = {
          ...newFilterSelected,
          filter: {
            ...newFilterSelected.filter,
            assigned_user_id: filterSelected?.filter?.assigned_user_id || null,
          },
        };
      }

      let newOrder = order;
      if (filterOptionSelected?.key === 'RecentlyCreated') {
        newOrder = ['date_entered', 'desc'];
      }

      const organizations = await organizationService
        .getOrganizations(
          { ...newFilterSelected, order: newOrder },
          {
            page: paginationPage.page,
            limit: paginationPage.limit,
          }
        )
        .catch((err) => console.log(err));

      const { data } = organizations || {};

      setAllOrganizations(data?.data);
      setPagination(data?.pagination);

      setDataInDB(count ? Boolean(data?.pagination?.count) : false);
      setTableLoading(false);
      setModified(0);
    }
  };

  useEffect(() => {
    if (modified > 0 || order?.length) {
      getOrganizations(true);
    }
  }, [modified, order]);

  useEffect(() => {
    (async () => {
      onGetUsers();
      if (pageContext?.RefreshCompanyList) getOrganizations(true);
      const getOwnerUserId = {
        ...isFieldsObj,
        assigned_user_id: profileInfo?.id,
        user: profileInfo,
      };
      setIsFieldsObj(getOwnerUserId);
    })();
  }, [pageContext]);

  const deleteOrganizations = async (selectedData) => {
    try {
      const response = await organizationService.deleteOrganizations(
        selectedData
      );

      setSuccessMessage('Company Deleted.');
      setDeleteResults(response);
    } catch (err) {
      setErrorMessage(err.message);
      setDeleteResults([]);
    }
  };

  const handleDelete = async () => {
    await deleteOrganizations(selectedData);
    setSelectedData([]);
    setShowDeleteReport(true);
  };

  const openDeleteModal = () => {
    setShowDeleteOrgModal(true);
  };

  const data = allOrganizations?.map((organization) => {
    return {
      ...organization,
      dataRow: [
        {
          key: 'name',
          classes: 'px-0',
          component: (
            <Link
              to={
                useIsTenant().isSynovusBank
                  ? `${routes.insightsCompanies}/${organization.id}/organization/profile`
                  : `${routes.companies}/${organization.id}/organization/profile`
              }
              className="text-black text-truncate fw-bold d-flex align-items-center gap-2"
            >
              <Avatar
                classModifiers="bg-primary-soft text-primary"
                sizeIcon="font-size-lg"
                defaultSize="xs"
                user={organization}
                type="organization"
              />
              <TextOverflowTooltip
                maxLength={25}
                textStyle="fw-bold text-truncate font-size-sm2 mb-0"
                text={organization.name}
              />
            </Link>
          ),
        },
        {
          key: 'address',
          label: 'address',
          component: (
            <span className="pl-3">
              {organization.address_city || organization.address_state
                ? `            
              ${
                organization.address_city
                  ? organization.address_city + ', '
                  : ''
              } 
              ${organization.address_state ? organization.address_state : ''}
            `
                : '--'}
            </span>
          ),
        },
        {
          key: 'industry',
          label: '',
          component: (
            <TextOverflowTooltip
              maxLength={42}
              textStyle="text-truncate"
              text={
                organization.industry
                  ? `${
                      organization.naics_code
                        ? `(${organization.naics_code})`
                        : ''
                    } ${organization.industry}`
                  : '--'
              }
            />
          ),
        },
        {
          key: 'assigned_user',
          label: '',
          component: (
            <span>
              {organization?.assigned_user ? (
                <p className="d-flex align-items-center mb-0 gap-1">
                  <IdfTooltip
                    text={`${organization?.assigned_user?.first_name} ${organization?.assigned_user?.last_name}`}
                  >
                    <Avatar
                      user={organization?.assigned_user}
                      defaultSize="xs"
                    />
                  </IdfTooltip>
                  <span>
                    {`${organization?.assigned_user?.first_name} ${organization?.assigned_user?.last_name}`}
                  </span>{' '}
                </p>
              ) : (
                '--'
              )}
            </span>
          ),
        },
        {
          key: 'owners',
          label: 'owners',
          component: (
            <>
              <TableRowHover
                onClick={(e) => {
                  const rect = e.target.getBoundingClientRect();
                  const x = e.clientX - rect.left;
                  if (x <= 50) {
                    setSelectedData((prevSelected) => {
                      if (!prevSelected.includes(organization.id)) {
                        return [...prevSelected, organization.id];
                      } else {
                        return prevSelected.filter(
                          (item) => item !== organization.id
                        );
                      }
                    });
                  } else {
                    history.push(
                      useIsTenant().isSynovusBank
                        ? `${routes.insightsCompanies}/${organization.id}/organization/profile`
                        : `${routes.companies}/${organization.id}/organization/profile`
                    );
                  }
                }}
              />
              <div className="position-relative z-index-99">
                <IdfOwnersHeader
                  mainOwner={organization.assigned_user}
                  service={organizationService}
                  serviceId={null}
                  headerType="organization"
                  isClickable={false}
                  onClick={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                  }}
                  listOwners={organization.owners}
                  defaultSize="xs"
                  isprincipalowner={false}
                  small
                />
              </div>
            </>
          ),
        },
        {
          key: 'date_modified',
          label: '',
          component: (
            <span>
              {organization?.date_modified ? (
                <p className="d-flex align-items-center mb-0 gap-1">
                  <span>
                    {setDateFormat(
                      organization?.date_modified,
                      DATE_FORMAT_MONTH
                    )}
                  </span>
                </p>
              ) : (
                '--'
              )}
            </span>
          ),
        },
      ],
    };
  });

  const onHandleFilterOrg = (item) => {
    const newFilterSelected = {
      ...filterSelected,
      filter: item && item.id ? { assigned_user_id: item.id } : filters,
    };

    const hasFilters = Object.keys(newFilterSelected.filter);
    if (!hasFilters.length) delete newFilterSelected.filter;

    // Perform setSelectedOwnership action when owner filter applied
    if (item?.id !== profileInfo?.id) {
      setSelectedOwnership('All');
    }

    setFilterSelected(newFilterSelected);
    const newFilterOptionSelected = {
      key: item.id,
      id: item.id,
      name: item?.id ? `${item?.first_name} ${item?.last_name}` : 'Filters',
      type: 'owner',
    };
    setFilterOptionSelected(newFilterOptionSelected);
    setOpenFilter(false);
    setModified((prevState) => prevState + 1);
    setSelectedOwner(item);
    setPageContext({
      ...pageContext,
      OrganizationsPage: {
        ...pageContext.OrganizationsPage,
        filterOptionSelected: newFilterOptionSelected,
        selectedFilter: newFilterSelected,
        isModified: getIsModified() + 1,
        user: item,
      },
    });
  };

  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };

  const currentView = 'organization';
  const getFields = async () => {
    setIsLoading(true);
    const { data } = await fieldService.getFields(currentView, {
      preferred: true,
    });
    groupBySection(data);
    setIsLoading(false);
  };

  const toggle = () => {
    setModal(!modal);
    reset(organizationObj);
    setIsFieldsObj({
      assigned_user_id: isFieldsObj.assigned_user_id,
      user: isFieldsObj.user,
      ...organizationObj,
    });
    getFields();
    setCustomFields([]);
  };

  const onHandleSubmit = async () => {
    setLoading(true);
    // set US as country for now
    isFieldsObj.address_country = 'USA';

    // here splitting address back to what API needs
    isFieldsObj.address_street = isFieldsObj?.address_full
      ? splitAddress(isFieldsObj.address_full)?.address
      : '';
    const updateFields = removeCustomFieldsFromActivityForm(
      isFieldsObj,
      customFields
    );
    const newContact = await organizationService
      .createOrganization(updateFields)
      .catch((err) => console.log(err));

    if (newContact) {
      await Promise.all(
        customFields?.map(async (item) => {
          if (item?.value !== '')
            await new Promise((resolve) => {
              organizationService
                .updateCustomField(newContact?.data?.id, item)
                .then(resolve);
            });
        }),
        preOwners?.map(async (item) => {
          await new Promise((resolve) => {
            organizationService
              .addOwner(newContact?.data?.id, item.user_id)
              .then(resolve);
          });
        })
      );
      getOrganizations(true);
      const dataUpdate = {
        ...isFieldsObj,
        assigned_user_id: profileInfo?.id,
      };
      setIsFieldsObj(dataUpdate);
      setPreOwners([]);
      setSuccessMessage(
        isSynovus
          ? INSIGHT_CREATED
          : COMPANY_CREATED.replace(/Company/g, moduleMap.organization.singular)
      );

      toggle();
    }

    setLoading(false);
  };

  const loader = () => {
    if (tableLoading) return <TableSkeleton cols={6} rows={10} />;
  };

  const onClose = () => {
    setModal(false);
    reset(organizationObj);
    setPreOwners([]);
    setIsFieldsObj({
      assigned_user_id: isFieldsObj.assigned_user_id,
      user: isFieldsObj.user,
      ...organizationObj,
    });
    setCustomFields([]);
  };

  const sortTable = ({ name }) => sortingTable({ name, order, setOrder });

  const handleRowClick = (row, col) => {
    if (row.dataRow && col.key === 'name') {
      history.push(row.dataRow[0].component.props.to);
    }
  };

  const handleClearSelection = () => {
    setSelectAll(false);
    setSelectedData([]);
  };

  return (
    <div className="bg-white">
      <div className="d-flex align-items-center justify-content-between positon-relative mb-2 pt-2 px-3">
        <FilterTabsButtonDropdown
          options={ORGANIZATION_FILTER_OPTIONS_LIST}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          filterOptionSelected={filterOptionSelected}
          filterSelected={filterSelected}
          filterTabs={filterTabs}
          handleFilterSelect={handleFilterSelect}
          onHandleFilterOwner={onHandleFilterOrg}
          setFilterOptionSelected={setFilterOptionSelected}
          setFilterSelected={setFilterSelected}
          setFilterTabs={setFilterTabs}
          setModified={setModified}
          extraClasses={'dropdown-left'}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          module="organization"
        />
        {moduleMap.organization && (
          <LayoutHead
            id="add-company-button"
            onHandleCreate={toggle}
            buttonLabel={addButtonLabel}
            selectedData={selectedData}
            onDelete={openDeleteModal}
            alignTop="my-0"
            dataInDB={dataInDB}
            permission={{
              collection: 'organizations',
              action: 'create',
            }}
            onClear={handleClearSelection}
          ></LayoutHead>
        )}
      </div>

      {showDeleteOrgModal && (
        <DeleteModal
          type="organizations"
          showModal={showDeleteOrgModal}
          setShowModal={setShowDeleteOrgModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          event={handleDelete}
          data={allOrganizations}
          results={deleteResults}
          setResults={setDeleteResults}
          showReport={showDeleteReport}
          setShowReport={setShowDeleteReport}
          modified={modified}
          setModified={setModified}
          setSelectAll={setSelectAll}
          constants={organizationConstants.delete}
        />
      )}

      <Card className="card-0">
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {tableLoading ? (
                loader()
              ) : (
                <>
                  {moduleMap.organization && (
                    <Table
                      checkbox
                      stickyColumn="stickyColumn"
                      columns={organizationColumns}
                      data={data}
                      showLoading={tableLoading}
                      selectAll={selectAll}
                      setSelectAll={setSelectAll}
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                      paginationInfo={pagination}
                      usePagination
                      showPerPage={true}
                      onPageChange={(newPage) => {
                        changePaginationPage(newPage, setPaginationPage);
                        setModified((prevState) => prevState + 1);
                      }}
                      onLimitChange={(perPage) => {
                        changePaginationLimit(perPage, setPaginationPage);
                        setModified((prevState) => prevState + 1);
                      }}
                      title={`${moduleMap.organization.singular}`}
                      dataInDB={dataInDB}
                      emptyDataText={`No records in this view.`}
                      toggle={toggle}
                      sortingTable={sortTable}
                      sortingOrder={order}
                      onClickCol={handleRowClick}
                      permission={{
                        collection: 'organizations',
                        action: 'create',
                      }}
                      stickyFooter={true}
                      stats={() => (
                        <TableFooterStats stats={stats} loading={loading} />
                      )}
                      stickyClass="accounts-table-container"
                      tableLoading={tableLoading}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <RightPanelModal
        showModal={modal}
        setShowModal={() => onClose()}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed'}
        headerBgColor="bg-gray-5"
        Title={
          <div className="d-flex py-2 align-items-center">
            {moduleMap.organization && (
              <h3 className="mb-0">
                {isSynovus
                  ? ADD_INSIGHT
                  : `Add ${moduleMap.organization.singular}`}
              </h3>
            )}
          </div>
        }
      >
        <OrganizationForm
          setIsFieldsObj={setIsFieldsObj}
          isFieldsObj={isFieldsObj}
          checkFieldsType={orgDynamicFields}
          fields={isFieldsData}
          refresh={() => getOrganizations(true)}
          me={profileInfo}
          onClose={onClose}
          isLoading={isLoading}
          loading={loading}
          onHandleSubmit={onHandleSubmit}
          handleSubmit={handleSubmit}
          register={register}
          customDataFields={customFields}
          setCustomDataFields={setCustomFields}
          setValue={setValue}
          getFieldState={getFieldState}
          control={control}
          errors={errors}
          labelType="organization"
          isprincipalowner="true"
          service={organizationService}
          prevalue="true"
          preowners={preOwners}
          setPreOwners={setPreOwners}
          organizationObj={organizationObj}
          fromNavBar
        />
      </RightPanelModal>

      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
    </div>
  );
};

export default Organizations;
