import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import tenantService from '../../services/tenant.service';
import { paginationDefault } from '../../utils/constants';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import stringConstants from '../../utils/stringConstants.json';
import { sortingTable } from '../../utils/sortingTable';
import Table from '../GenericTable';
import LayoutHead from '../commons/LayoutHead';
import CreateTenantModal from './CreateTenantModal';
import { useForm } from 'react-hook-form';
import { setDateFormat } from '../../utils/Utils';
import {
  changePaginationPage,
  changePaginationLimit,
} from '../../views/Deals/contacts/utils';
import MoreActions from '../MoreActions';
import TableSkeleton from '../commons/TableSkeleton';
import authService from '../../services/auth.service';
import { DataFilters } from '../DataFilters';
import MaterialIcon from '../commons/MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';
import CategoryPartnerLogo from '../lesson/CategoryPartnerLogo';
import AnimatedTabs from '../commons/AnimatedTabs';

const constants = stringConstants.tenants;
const includeOwners = true;

const TenantTableTabs = {
  Active: {
    id: 1,
    tabId: 1,
    title: 'Active',
    label: 'Active',
  },
  Disabled: {
    id: 2,
    tabId: 2,
    title: 'Disabled',
    label: 'Disabled',
  },
};
const TenantTable = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [tabsData, setTabsData] = useState([
    {
      ...TenantTableTabs.Active,
      count: 0,
    },
    {
      ...TenantTableTabs.Disabled,
      count: 0,
    },
  ]);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [pagination, setPagination] = useState(paginationDefault);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState(['updated_at', 'DESC']);
  const [selectedEditData, setSelectedEditData] = useState('');
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [allTenants, setAllTenants] = useState([]);
  const [showTooltip, setShowTooltip] = useState(true);
  const [createTenantResponse, setCreateTenantResponse] = useState();
  const [filter, setFilter] = useState({});
  const [tabCounts, setTabCounts] = useState();
  const [activeTab, setActiveTab] = useState(TenantTableTabs.Active.id);
  const [, setStats] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultComponentForm = {
    name: '',
    module: '',
    measures: [],
    dimensions: [],
    timeDimensions: [],
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultComponentForm,
  });

  const getActionItemsByUserStatus = (tenant) => {
    let actions = [
      {
        id: 'edit',
        name: 'Edit Tenant',
        className: 'bg-hover-primary',
        icon: 'edit',
        onClick: handleEditModalShow,
      },
      {
        id: 'add',
        name: 'Owner Ghost Login',
        user: 'owner',
        className: 'bg-hover-primary',
        icon: 'login',
        onClick: ghoostLogin,
      },
      {
        id: 'remove',
        name: 'User Ghost Login',
        user: 'app',
        className: 'bg-hover-primary',
        icon: 'input',
        onClick: ghoostLogin,
      },
    ];
    if (tenant.users) {
      const appUser = tenant?.users.find(
        (item) => item.role?.app_access && !item.role?.owner_access
      );
      if (!appUser) actions.pop();
    }
    if (tenant.status === 'enabled') {
      actions = [
        ...actions,
        { type: 'divider' },
        {
          id: 'disable',
          name: 'Disable Tenant',
          icon: 'do_not_disturb_on',
          className: 'bg-hover-danger',
          onClick: updateTenantStatus,
        },
      ];
    } else {
      actions = [
        ...actions,
        { type: 'divider' },
        {
          id: 'enable',
          name: 'Enable Tenant',
          icon: 'check_circle',
          className: 'bg-hover-success',
          onClick: updateTenantStatus,
        },
      ];
    }
    return actions;
  };
  const filterEnabled = (d) => d.status === 'enabled';
  const filterDisabled = (d) => d.status === 'disabled';

  const getTenants = async () => {
    setShowLoading(true);
    try {
      const { data, pagination } = await tenantService.getTenants(
        order,
        { page: paginationPage.page, limit: paginationPage.limit },
        includeOwners,
        { ...filter }
      );
      setPagination(pagination);
      setDataInDB(Boolean(pagination?.totalPages));
      setAllTenants(data);
      const activeCount = data.filter(filterEnabled).length;
      const disabledCount = data.filter(filterDisabled).length;
      setTabCounts({
        [TenantTableTabs.Active.id]: activeCount,
        [TenantTableTabs.Disabled.id]: disabledCount,
      });
      setTabsData([
        {
          ...TenantTableTabs.Active,
          count: activeCount,
        },
        {
          ...TenantTableTabs.Disabled,
          count: disabledCount,
        },
      ]);
      setStats([
        {
          ...TenantTableTabs.Active,
          count: activeCount,
        },
        {
          ...TenantTableTabs.Disabled,
          count: disabledCount,
        },
      ]);
      setShowLoading(false);
    } catch (err) {
      setErrorMessage(constants.create?.errorGetTenants);
    }
  };

  useEffect(() => {
    getTenants();
  }, [paginationPage, order, filter]);

  const validationConfig = {
    name: {
      required: 'Component Name is required.',
      inline: false,
    },
    module: {
      required: true,
    },
    measures: {
      required: true,
    },
    dimensions: {
      required: true,
    },
    timeDimensions: {
      required: true,
    },
  };
  const updateTenantStatus = async (tenant, status) => {
    await tenantService.updateTenant({ status }, tenant.id);
    getTenants();
  };
  const ghoostLogin = async (item, user) => {
    let idToImpersonate;
    if (item.users.length) {
      idToImpersonate = item.users.find((userObj) => {
        if (user === 'owner') {
          return userObj.role?.owner_access;
        }
        // for normal user
        return (
          userObj.role && !userObj.role.owner_access && userObj.role.app_access
        );
      });
      try {
        const impersonateUser = await authService.impersonate(
          idToImpersonate.id
        );

        // localhost special handling because it redirects to https://localhost we want to redirect it to http://localhost:3000
        const domainUrl = item.domain.includes('localhost')
          ? 'http://localhost:3000'
          : `https://${item.domain}`;
        window.open(
          `${domainUrl}/login?access_token=${impersonateUser.access_token}&refresh_token=${impersonateUser.refresh_token}`,
          '_blank'
        );
      } catch (e) {
        setErrorMessage(constants.edit.ghost);
      }
    } else {
      setErrorMessage(constants.edit.userError);
    }
  };
  const handleEditModalShow = async (item) => {
    const singleTenant = await tenantService.getSingleTenant(item.id);
    singleTenant &&
      setSelectedEditData({
        name: singleTenant?.name || '',
        description: singleTenant?.description || '',
        id: singleTenant?.id || '',
        domain: singleTenant?.domain || '',
        ownerEmail: singleTenant?.ownerEmail || '',
        modules: singleTenant?.modules || '',
        tenantInfo: singleTenant?.domain || '',
        colors: singleTenant?.colors || '',
        icon: singleTenant?.icon || '',
        logo: singleTenant?.logo || '',
        use_logo: singleTenant?.use_logo || '',
        settings: singleTenant?.settings || null,
      });
    setShowCreateRoleModal(true);
  };
  const handleUpdate = async (data) => {
    setLoading(true);
    try {
      const createResponce = await tenantService.updateTenant(
        data,
        selectedEditData.id
      );

      if (createResponce) {
        getTenants();
        setSuccessMessage(constants.edit.TenantUpdateSuccess);
        setShowCreateRoleModal(false);
      } else {
        setErrorMessage(constants.edit.TenantUpdateFailed);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getUsersCountByStatus = (users, status) => {
    return users.filter((user) => user.status === status).length;
  };

  const columns = [
    {
      key: 'name',
      orderBy: 'name',
      component: 'Tenant Name',
    },
    {
      key: 'sso-enabled',
      component: 'SSO',
    },
    {
      key: 'total-users',
      component: 'Total Users',
    },
    {
      key: 'active-users',
      component: 'Active Users',
    },
    {
      key: 'invited-users',
      component: 'Invited Users',
    },
    {
      key: 'created_at',
      orderBy: 'created_at',
      component: 'Created At',
    },
    {
      key: 'updated_at',
      orderBy: 'updated_at',
      component: 'Last Modified',
    },
    {
      key: 'action',
      component: 'Actions',
    },
  ];

  const data = allTenants?.map((tenant, id) => ({
    ...tenant,
    dataRow: [
      {
        key: 'name',
        component: (
          <div className="d-flex align-items-center gap-1">
            <div className="avatar avatar-circle bg-white avatar-xs">
              <CategoryPartnerLogo
                categoryInfo={tenant}
                width="25px"
                minWidth="25px"
                height="25px"
                imageStyle="avatar-img border"
                avatarKey="icon"
                icon="corporate_fare"
              />
            </div>

            <span className="p-0 pl-2 nav-link fw-bold text-truncate">
              {tenant?.name}
            </span>
            <TooltipComponent
              title={tenant?.domain?.toLowerCase()}
              placement="bottom"
            >
              <MaterialIcon icon="info" clazz="font-size-lg" />
            </TooltipComponent>
          </div>
        ),
      },
      {
        key: 'sso-enabled',
        component: (
          <span className="text-center">
            {tenant?.settings?.idp?.enabled ? (
              <>
                <span className="badge status-chk pr-2 label d-inline-flex align-items-center justify-content-center rounded-pill text-capitalize undefined status-green">
                  <MaterialIcon filled size="fs-7" icon="fiber_manual_record" />
                  <span>Enabled</span>
                </span>
              </>
            ) : (
              <span className="px-0">--</span>
            )}
          </span>
        ),
      },
      {
        key: 'total-users',
        component: <span>{tenant?.users.length}</span>,
      },
      {
        key: 'active-users',
        component: getUsersCountByStatus(tenant?.users, 'active'),
      },
      {
        key: 'invited-users',
        component: getUsersCountByStatus(tenant?.users, 'invited'),
      },
      {
        key: 'created_at',
        component: <span>{setDateFormat(tenant?.created_at)}</span>,
      },
      {
        key: 'last_modified',
        component: <span>{setDateFormat(tenant?.updated_at)}</span>,
      },
      {
        key: 'action',
        component: (
          <a className={`icon-hover-bg cursor-pointer`}>
            <MoreActions
              items={getActionItemsByUserStatus(tenant)}
              onHandleEdit={() => {
                handleEditModalShow({ ...tenant, title: name });
              }}
              onHandleAdd={() => {
                ghoostLogin({ ...tenant }, 'owner');
              }}
              onHandleRemove={() => {
                ghoostLogin({ ...tenant }, 'app');
              }}
              onHandleEnable={() => {
                updateTenantStatus(tenant, 'enabled');
              }}
              onHandleDisable={() => {
                updateTenantStatus(tenant, 'disabled');
              }}
              menuWidth={210}
            />
          </a>
        ),
      },
    ],
  }));

  const handleCreateTenant = async (data) => {
    try {
      if (!data?.colors) {
        setErrorMessage('Please select color!');
        setCreateTenantResponse(false);
        return false;
      } else {
        setLoading(true);
        const createResponce = await tenantService.createTenant(data);
        if (!createResponce.response) {
          getTenants();
          setSuccessMessage(constants.create.TenantCreatedSuccess);
          setShowCreateRoleModal(false);
          setCreateTenantResponse(true);
        } else {
          createResponce.response.request.status === 500
            ? setErrorMessage(constants.create.TenantCreatedFailed)
            : setErrorMessage(createResponce.response?.data?.error);
          setCreateTenantResponse(false);
          return false;
        }

        return createTenantResponse;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const sortTable = ({ name }) => {
    if (name === 'last_modified') name = 'updated_at';
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder });
  };
  const loader = () => {
    if (showLoading) return <TableSkeleton cols={4} rows={12} />;
  };

  const toggle = (tab) => {
    if (activeTab !== tab.id) {
      setActiveTab(tab.id);
    }
  };

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>

      <AnimatedTabs
        showCount
        maxCount={99}
        tabsData={tabsData}
        activeTab={activeTab}
        tabCounts={tabCounts}
        tabClasses="px-3"
        toggle={toggle}
        permissionCheck={true}
        borderClasses="bg-white border-bottom"
      />

      <Card className="mb-0 card-0 rounded-0">
        <Card.Header className="flex-column align-items-unset px-0">
          <div className="d-flex align-items-center px-3">
            <DataFilters
              filterSelected={filter}
              setFilterSelected={setFilter}
              searchPlaceholder="Search Tenants"
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />
            <LayoutHead
              onHandleCreate={() => setShowCreateRoleModal(true)}
              buttonLabel={constants.edit.add}
              selectedData={selectedData}
              onDelete={true}
              headingTitle=""
              dataInDB={dataInDB}
              alignTop="mb-0"
            />
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive-md">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {showLoading ? (
                loader()
              ) : (
                <Table
                  stickyFooter
                  actionPadding="p-0"
                  columns={columns}
                  data={data?.filter(
                    activeTab === TenantTableTabs.Active.id
                      ? filterEnabled
                      : filterDisabled
                  )}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  showPerPage={true}
                  paginationInfo={pagination}
                  onPageChange={(newPage) => {
                    changePaginationPage(newPage, setPaginationPage);
                  }}
                  onLimitChange={(perPage) => {
                    changePaginationLimit(perPage, setPaginationPage);
                  }}
                  emptyDataText="No Tenant available yet."
                  onClick={handleEditModalShow}
                  title="Tenant"
                  dataInDB={dataInDB}
                  showTooltip={showTooltip}
                  setShowTooltip={setShowTooltip}
                  toggle={() => setShowCreateRoleModal(true)}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  stickyClass="tenants-table-container"
                />
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      {showCreateRoleModal && (
        <CreateTenantModal
          setErrorMessage={setErrorMessage}
          showModal={showCreateRoleModal}
          setShowModal={setShowCreateRoleModal}
          handleCreateTenant={handleCreateTenant}
          showLoading={loading}
          errors={errors}
          config={validationConfig}
          register={register}
          handleSubmit={handleSubmit}
          reset={reset}
          setValue={setValue}
          selectedEditData={selectedEditData}
          setSelectedEditData={setSelectedEditData}
          handleUpdateTenant={handleUpdate}
        />
      )}
    </>
  );
};

export default TenantTable;
