import React, { useCallback, useMemo, useState } from 'react';
import {
  checkIfNaicsOrReportDateEmpty,
  formatCurrencyField,
  NAICS_STORAGE_KEY,
  overflowing,
} from '../../../utils/Utils';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import {
  PdfFileNames,
  updateWidgetBySection,
} from '../../../components/reports/reports.helper.functions';
import {
  ActionTypes,
  CommercialCardReportSections,
  ReportFormErrorFields,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import _ from 'lodash';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  ListGroup,
  Row,
} from 'reactstrap';
import SicNaicsAutoComplete from '../../../components/prospecting/v2/common/SicNaicsAutoComplete';
import ReactDatepicker from '../../../components/inputs/ReactDatpicker';
import useSicNaicsChangeDetect from '../../useSicNaicsChangeDetect';
import { Accordion, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import ReportService from '../../../services/report.service';
import OrganizationService from '../../../services/organization.service';
import useDeleteFraudReportModal from './useDeleteFraudReportModal';
import ReportAccordionToggle from '../../../components/reports/ReportAccordionToggle';
import NoDataFound from '../../../components/commons/NoDataFound';
import IconHeadingBlock from '../../../components/reportbuilder/blocks/IconHeadingBlock';
import WidgetWithActionButtons from '../../../components/reportbuilder/widgets/WidgetWithActionButtons';
import {
  DisclaimerData,
  DisclaimerDataFFB,
  PaymentStatsData,
  PaymentStatsTypes,
  VendorAnalysisPhasesData,
  VendorsCountData,
  WidgetTypes,
} from '../../../components/reportbuilder/constants/widgetsConstants';
import ReportSectionIncludedLabel from '../../../components/reports/ReportSectionIncludedLabel';
import useWidgetsLibrary from '../../../components/reportbuilder/widgets/useWidgetsLibrary';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import ValidationErrorText from '../../../components/commons/ValidationErrorText';
import DisclaimerAccordion from '../../../components/reports/DisclaimerAccordion';
import useIsTenant from '../../useIsTenant';

const WidgetsBySection = ({
  icon,
  widgets,
  section,
  onAddWidget,
  isMultiple,
  selectedTenant,
  onDeleteWidget,
  setSelectedWidget,
  setShowWidgetsLibrary,
}) => {
  const OpportunityHeading = {
    [CommercialCardReportSections.Insights]: 'Insights: Trends and Benefits',
    [CommercialCardReportSections.Highlights]: 'Highlights',
    [CommercialCardReportSections.VendorAnalysisDetails]:
      'Vendor Analysis Details',
  };
  const newWidgets = isMultiple || widgets[section];

  const heading =
    section === 'Disclaimer' && widgets[section]
      ? widgets[section][0]?.widgetConfig?.data?.disclaimerHeading
      : OpportunityHeading[section] || _.startCase(section);

  return (
    <>
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={heading}
          icon={icon}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-3 mb-0"
          reportType={ReportTypes.CommercialCard}
        />
      </div>
      <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
        {newWidgets?.map((widget, index) => (
          <WidgetWithActionButtons
            key={index}
            widget={widget}
            section={section}
            onAddWidget={onAddWidget}
            onDeleteWidget={onDeleteWidget}
            setSelectedWidget={setSelectedWidget}
            setShowWidgetsLibrary={setShowWidgetsLibrary}
            selectedTenant={selectedTenant}
            actionButtons={{}}
          />
        ))}
      </ListGroup>
    </>
  );
};

const GenerateCommercialCardReportModal = ({
  show,
  setShow,
  organization,
  modalWidgets,
  selectedReport,
  modalReportPages,
  handleGenerateReport,
  getOrganization,
}) => {
  const [errorFields, setErrorFields] = useState(ReportFormErrorFields);
  const [generating, setGenerating] = useState(false);
  const clonedReport = _.cloneDeep(selectedReport);
  const [reportForm, setReportForm] = useState(clonedReport);
  const { isFFB } = useIsTenant();
  const { setShowModal, setCompany, NaicsModal } = useSicNaicsChangeDetect(
    selectedReport,
    organization,
    getOrganization
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [currentAccordionKey, setCurrentAccordionKey] = useState(
    CommercialCardReportSections.Highlights
  );
  const [widgets, setWidgets] = useState(modalWidgets);
  const [reportPages, setReportPages] = useState(modalReportPages);
  const disclaimerData = isFFB ? DisclaimerDataFFB : DisclaimerData;

  const {
    DeleteFraudReportModal,
    setReport,
    setShowModal: setShowDeleteModal,
  } = useDeleteFraudReportModal(handleGenerateReport, setShow);
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const handleAccordionClick = (eventKey) => {
    setCurrentAccordionKey(eventKey);
  };

  const updateWidgets = (
    newReportData,
    widgetType,
    widgetConfigData,
    widgetSection
  ) => {
    const newWidgets = updateWidgetBySection(
      widgets,
      widgetSection,
      widgetType,
      newReportData,
      widgetConfigData
    );
    setWidgets(newWidgets);
  };

  const createOrUpdateReport = async () => {
    try {
      let reportObject = null;
      if (
        checkIfNaicsOrReportDateEmpty(reportForm, errorFields, setErrorFields)
      ) {
        return;
      }
      setGenerating(true);
      // update case
      if (selectedReport?.reportId) {
        reportObject = await ReportService.updateReport(
          selectedReport?.reportId,
          {
            name: selectedReport.companyName,
            date: selectedReport.reportDate,
            type: ReportTypes.CommercialCard,
            manualInput: {
              ...reportForm,
              widgets,
              reportPages,
            },
          }
        );
      } else {
        reportObject = await OrganizationService.createManualReport(
          organization.id,
          {
            name: selectedReport.companyName,
            date: selectedReport.reportDate,
            type: ReportTypes.CommercialCard,
            manualInput: {
              ...reportForm,
              widgets,
              reportPages,
            },
          }
        );
      }
      setShow(false);
      handleGenerateReport(
        selectedReport,
        selectedReport?.reportId ? ActionTypes.UPDATE : ActionTypes.ADD,
        reportObject,
        widgets,
        reportPages
      );
    } catch (e) {
      console.log(e);
    } finally {
      setGenerating(false);
    }
  };

  const handleChangeVCW = (e, key) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      [key]: value,
    };
    updateWidgets(
      newReport,
      WidgetTypes.VCW,
      VendorsCountData,
      CommercialCardReportSections.Highlights
    );
    setReportForm(newReport);
  };

  const sumTodayAmount = (data) => {
    return Object.keys(PaymentStatsTypes).reduce((total, key) => {
      const type = PaymentStatsTypes[key];
      return total + (data[type]?.todayAmount || 0);
    }, 0);
  };

  const handleChangePaymentStats = (e, key, type) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      [type]: {
        ...reportForm[type],
        [key]: value,
      },
    };
    const totalTodayAmount = sumTodayAmount(newReport);
    const updatedReportWithTotal = {
      ...newReport,
      paymentAmount: totalTodayAmount,
    };
    updateWidgets(
      updatedReportWithTotal,
      WidgetTypes.VCW,
      VendorsCountData,
      CommercialCardReportSections.Highlights
    );
    setReportForm(updatedReportWithTotal);
  };

  const handleChangeVAP = (e, key) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      [key]: value,
    };
    updateWidgets(
      newReport,
      WidgetTypes.VAP,
      VendorAnalysisPhasesData,
      CommercialCardReportSections.VendorAnalysisDetails
    );
    setReportForm(newReport);
  };

  const handleChangeInputDisclaimer = (e) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      disclaimer: value,
    };
    setReportForm(newReport);
    updateWidgets(
      newReport,
      WidgetTypes.DISC,
      disclaimerData,
      CommercialCardReportSections.Disclaimer
    );
  };

  const handleChangeInputDisclaimerHeading = (e) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      disclaimerHeading: value,
    };
    setReportForm(newReport);
    updateWidgets(
      newReport,
      WidgetTypes.DISC,
      disclaimerData,
      CommercialCardReportSections.Disclaimer
    );
  };

  const detectNaicsChangeAndRefreshOrganizationProfile = () => {
    if (reportForm?.valueNaicsSic !== organization?.naics_code) {
      getOrganization && getOrganization();
    }
  };

  const handleReportPageToggle = (section) => {
    const updatedReportPages = {
      ...reportPages,
      [section]: {
        ...reportPages[section],
        enabled: !Object.hasOwn(reportPages, section)
          ? true
          : !reportPages[section].enabled,
      },
    };

    // we need to make sure at-least 1 page is active, so if user toggles all off and trying to toggle last off make sure to avoid it.
    const remainingEnabledSections = Object.entries(updatedReportPages).filter(
      ([key, value]) => value.enabled && key !== section
    ).length;

    if (!remainingEnabledSections) {
      setErrorMessage('At least one section must be enabled.');
      return;
    }
    setReportPages(updatedReportPages);
  };

  const onAddWidget = (e, newWidget, oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
    setShowWidgetsLibrary(false);
  };

  const onDeleteWidget = (oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
  };

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <NaicsModal />
      <DeleteFraudReportModal />
      <WidgetsLibraryModal />
      <SimpleModalCreation
        modalTitle={`${PdfFileNames[ReportTypes.CommercialCard]} Report`}
        open={show}
        saveButton="Save Report"
        isLoading={generating}
        deleteButton={
          reportForm?.reportId || reportForm?.key
            ? {
                label: 'Delete Report',
                show: true,
                loading: false,
                onClick: () => {
                  setReport(reportForm);
                  setShowDeleteModal(true);
                },
              }
            : null
        }
        handleSubmit={() => {
          overflowing();
          createOrUpdateReport();
          detectNaicsChangeAndRefreshOrganizationProfile();
        }}
        bodyClassName="p-0 overflow-x-hidden"
        size="xxl"
        onHandleCloseModal={() => {
          overflowing();
          setShow(false);
        }}
        onClick={() => document.dispatchEvent(new MouseEvent('click'))}
      >
        <Row>
          <Col md={5}>
            <div className="pl-3 py-3">
              <FormGroup>
                <Label for="title">Company Name</Label>
                <input
                  name="name"
                  type="text"
                  value={reportForm.companyName}
                  placeholder="Enter Company Name"
                  onChange={(e) => {
                    setReportForm({
                      ...reportForm,
                      companyName: e.target.value,
                    });
                    setErrorFields({
                      ...errorFields,
                      name: {
                        ...errorFields.name,
                        isShow: !e.target.value,
                      },
                    });
                  }}
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.name.isShow ? 'border-danger' : ''
                  }`}
                />
                {errorFields.name.isShow && (
                  <ValidationErrorText text={errorFields.name.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Label for="title">NAICS</Label>
                <SicNaicsAutoComplete
                  data={reportForm}
                  setData={setReportForm}
                  placeholder="Enter a NAICS code"
                  customKey="valueN"
                  showFieldError={errorFields.naics?.isShow}
                  setErrorMessage={setErrorMessage}
                  callKey={NAICS_STORAGE_KEY}
                  isDisabled={!!organization.naics_code}
                  callType="getNaicsCodes"
                  onSelect={(item, naicsSicOnly, naicsTitle) => {
                    setReportForm({
                      ...reportForm,
                      valueN: item ? [item] : [],
                      valueNaicsSic: naicsSicOnly,
                      industry: naicsTitle,
                    });
                    setErrorFields({
                      ...errorFields,
                      naics: {
                        ...errorFields.naics,
                        isShow: !naicsSicOnly,
                      },
                    });
                    if (naicsSicOnly) {
                      if (naicsSicOnly !== organization.naics_code) {
                        setShowModal(true);
                        setCompany({
                          ...organization,
                          naics_code: naicsSicOnly,
                          industry: naicsTitle,
                        });
                      }
                    }
                  }}
                />
                {errorFields.naics?.isShow && (
                  <ValidationErrorText text={errorFields.naics.message} />
                )}
              </FormGroup>
              <FormGroup className="date-wrapper">
                <Label for="title">Report Date</Label>
                <ReactDatepicker
                  id={'rptDate'}
                  name={'reportDate'}
                  todayButton="Today"
                  value={reportForm.reportDate}
                  autoComplete="off"
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.reportDate?.isShow ? 'border-danger' : ''
                  }`}
                  placeholder="Select Report Date"
                  format="MMMM yyyy"
                  onChange={(date) => {
                    setReportForm({
                      ...reportForm,
                      reportDate: date,
                    });
                    setErrorFields({
                      ...errorFields,
                      reportDate: {
                        ...errorFields.reportDate,
                        isShow: !date,
                      },
                    });
                  }}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
                {errorFields.reportDate?.isShow && (
                  <ValidationErrorText text={errorFields.reportDate.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={CommercialCardReportSections.Highlights}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {CommercialCardReportSections.Highlights}
                      <ReportSectionIncludedLabel
                        section={CommercialCardReportSections.Highlights}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={CommercialCardReportSections.Highlights}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="title">Total Active Vendors</Label>
                          <InputGroup className="align-items-center">
                            <CurrencyInput
                              name="activeVendors"
                              placeholder="0"
                              value={
                                formatCurrencyField(reportForm.activeVendors) ||
                                ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleChangeVCW(
                                  { target: { value: values.float, name } },
                                  name
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="title">Total $ of Payments</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                style={{ width: 40 }}
                                className="bg-gray-100"
                              >
                                $
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="paymentAmount"
                              placeholder="0"
                              readOnly={true}
                              value={
                                formatCurrencyField(reportForm.paymentAmount) ||
                                ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleChangeVCW(
                                  { target: { value: values.float, name } },
                                  name
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label for="title">Active Months</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                style={{ width: 40 }}
                                className="bg-gray-100"
                              >
                                #
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="activeMonths"
                              placeholder="0"
                              value={
                                formatCurrencyField(reportForm.activeMonths) ||
                                ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleChangeVCW(
                                  { target: { value: values.float, name } },
                                  name
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        {PaymentStatsData?.items?.map((item, index) => {
                          return (
                            <div key={index}>
                              <Label for="">{item.type} (Today)</Label>
                              <div className="d-flex align-items-center">
                                <Label
                                  for="todayPayments"
                                  className="pr-2 text-right"
                                  style={{ width: 120 }}
                                >
                                  Payments
                                </Label>
                                <FormGroup className="w-100">
                                  <InputGroup className="align-items-center">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text
                                        style={{ width: 40 }}
                                        className="bg-gray-100"
                                      >
                                        #
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CurrencyInput
                                      name="todayPayments"
                                      placeholder="0"
                                      value={
                                        formatCurrencyField(
                                          reportForm[item.type]?.todayPayments
                                        ) || ''
                                      }
                                      className="form-control"
                                      onValueChange={(value, name, values) => {
                                        handleChangePaymentStats(
                                          {
                                            target: {
                                              value: values.float,
                                              name,
                                            },
                                          },
                                          name,
                                          item.type
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </div>
                              <div className="d-flex">
                                <Label
                                  for="todayAmount"
                                  className="pr-2 text-right"
                                  style={{ width: 120 }}
                                >
                                  Amount
                                </Label>
                                <FormGroup className="w-100">
                                  <InputGroup className="align-items-center">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text
                                        style={{ width: 40 }}
                                        className="bg-gray-100"
                                      >
                                        $
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CurrencyInput
                                      name="todayAmount"
                                      placeholder="0"
                                      value={
                                        formatCurrencyField(
                                          reportForm[item.type]?.todayAmount
                                        ) || ''
                                      }
                                      className="form-control"
                                      onValueChange={(value, name, values) => {
                                        handleChangePaymentStats(
                                          {
                                            target: {
                                              value: values.float,
                                              name,
                                            },
                                          },
                                          name,
                                          item.type
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </div>
                              <Label for="">{item.type} (Future)</Label>
                              <div className="d-flex">
                                <Label
                                  for="futurePayments"
                                  className="pr-2 text-right"
                                  style={{ width: 120 }}
                                >
                                  Payments
                                </Label>
                                <FormGroup className="w-100">
                                  <InputGroup className="align-items-center">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text
                                        style={{ width: 40 }}
                                        className="bg-gray-100"
                                      >
                                        #
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CurrencyInput
                                      name="futurePayments"
                                      placeholder="0"
                                      value={
                                        formatCurrencyField(
                                          reportForm[item.type]?.futurePayments
                                        ) || ''
                                      }
                                      className="form-control"
                                      onValueChange={(value, name, values) => {
                                        handleChangePaymentStats(
                                          {
                                            target: {
                                              value: values.float,
                                              name,
                                            },
                                          },
                                          name,
                                          item.type
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </div>
                              <div className="d-flex">
                                <Label
                                  for="futureAmount"
                                  className="pr-2 text-right"
                                  style={{ width: 120 }}
                                >
                                  Amount
                                </Label>
                                <FormGroup className="w-100">
                                  <InputGroup className="align-items-center">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text
                                        style={{ width: 40 }}
                                        className="bg-gray-100"
                                      >
                                        $
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CurrencyInput
                                      name="futureAmount"
                                      placeholder="0"
                                      value={
                                        formatCurrencyField(
                                          reportForm[item.type]?.futureAmount
                                        ) || ''
                                      }
                                      className="form-control"
                                      onValueChange={(value, name, values) => {
                                        handleChangePaymentStats(
                                          {
                                            target: {
                                              value: values.float,
                                              name,
                                            },
                                          },
                                          name,
                                          item.type
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </div>
                            </div>
                          );
                        })}
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={
                        CommercialCardReportSections.VendorAnalysisDetails
                      }
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {CommercialCardReportSections.VendorAnalysisDetails}
                      <ReportSectionIncludedLabel
                        section={
                          CommercialCardReportSections.VendorAnalysisDetails
                        }
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={
                        CommercialCardReportSections.VendorAnalysisDetails
                      }
                    >
                      <CardBody>
                        {VendorAnalysisPhasesData?.items?.map(
                          (phase, index) => {
                            return (
                              <div key={index} className="">
                                <Label for={``}>
                                  {reportForm[`titleKey${index}`] ||
                                    phase.title}
                                </Label>
                                <FormGroup>
                                  <InputGroup className="align-items-center">
                                    <input
                                      name={`titleKey${index}`}
                                      placeholder=""
                                      value={
                                        reportForm[`titleKey${index}`] ||
                                        phase.title
                                      }
                                      className="form-control"
                                      onChange={(e) => {
                                        handleChangeVAP(
                                          {
                                            target: {
                                              value: e.target.value,
                                              name: `titleKey${index}`,
                                            },
                                          },
                                          `titleKey${index}`
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label for={`descriptionKey${index}`}>
                                    Description
                                  </Label>
                                  <InputGroup className="align-items-center">
                                    <textarea
                                      maxLength="300"
                                      name={`descriptionKey${index}`}
                                      placeholder=""
                                      value={
                                        reportForm[`descriptionKey${index}`] ||
                                        phase.description
                                      }
                                      className="form-control"
                                      onChange={(e) => {
                                        handleChangeVAP(
                                          {
                                            target: {
                                              value: e.target.value,
                                              name: `descriptionKey${index}`,
                                            },
                                          },
                                          `descriptionKey${index}`
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label for={`venderCountKey${index}`}>
                                    Total vendors
                                  </Label>
                                  <InputGroup className="align-items-center">
                                    <CurrencyInput
                                      name={`venderCountKey${index}`}
                                      placeholder="0"
                                      value={
                                        index === 0
                                          ? formatCurrencyField(
                                              reportForm.venderCountKey0
                                            )
                                          : index === 1
                                          ? formatCurrencyField(
                                              reportForm.venderCountKey1
                                            )
                                          : index === 2
                                          ? formatCurrencyField(
                                              reportForm.venderCountKey2
                                            )
                                          : ''
                                      }
                                      className="form-control"
                                      onValueChange={(value, name, values) => {
                                        handleChangeVAP(
                                          {
                                            target: {
                                              value: values.float,
                                              name,
                                            },
                                          },
                                          name
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label for={`totalSpentKey${index}`}>
                                    Total spend
                                  </Label>
                                  <InputGroup className="align-items-center">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text
                                        style={{ width: 40 }}
                                        className="bg-gray-100"
                                      >
                                        $
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <CurrencyInput
                                      name={`totalSpentKey${index}`}
                                      placeholder="0"
                                      value={
                                        index === 0
                                          ? formatCurrencyField(
                                              reportForm.totalSpentKey0
                                            )
                                          : index === 1
                                          ? formatCurrencyField(
                                              reportForm.totalSpentKey1
                                            )
                                          : index === 2
                                          ? formatCurrencyField(
                                              reportForm.totalSpentKey2
                                            )
                                          : ''
                                      }
                                      className="form-control"
                                      onValueChange={(value, name, values) => {
                                        handleChangeVAP(
                                          {
                                            target: {
                                              value: values.float,
                                              name,
                                            },
                                          },
                                          name
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label for={`conversationRateKey${index}`}>
                                    Conversion rate
                                  </Label>
                                  <InputGroup className="align-items-center">
                                    <InputGroup.Prepend>
                                      <InputGroup.Text
                                        style={{ width: 40 }}
                                        className="bg-gray-100"
                                      >
                                        %
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <CurrencyInput
                                      name={`conversationRateKey${index}`}
                                      placeholder="0"
                                      value={
                                        index === 0
                                          ? formatCurrencyField(
                                              reportForm.conversationRateKey0
                                            )
                                          : index === 1
                                          ? formatCurrencyField(
                                              reportForm.conversationRateKey1
                                            )
                                          : index === 2
                                          ? formatCurrencyField(
                                              reportForm.conversationRateKey2
                                            )
                                          : ''
                                      }
                                      className="form-control"
                                      onValueChange={(value, name, values) => {
                                        handleChangeVAP(
                                          {
                                            target: {
                                              value: values.float,
                                              name,
                                            },
                                          },
                                          name
                                        );
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </div>
                            );
                          }
                        )}
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={CommercialCardReportSections.Insights}
                      callback={handleAccordionClick}
                      dontExpand={true}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {CommercialCardReportSections.Insights}
                      <ReportSectionIncludedLabel
                        section={CommercialCardReportSections.Insights}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={
                        CommercialCardReportSections.MovingForwardTogether
                      }
                      callback={handleAccordionClick}
                      dontExpand={true}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {CommercialCardReportSections.MovingForwardTogether}
                      <ReportSectionIncludedLabel
                        section={
                          CommercialCardReportSections.MovingForwardTogether
                        }
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={CommercialCardReportSections.Disclaimer}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {reportForm.disclaimerHeading}
                      <ReportSectionIncludedLabel
                        section={CommercialCardReportSections.Disclaimer}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <DisclaimerAccordion
                      eventKey={CommercialCardReportSections.Disclaimer}
                      reportModal={reportForm}
                      handleChangeInputDisclaimer={handleChangeInputDisclaimer}
                      handleChangeInputDisclaimerHeading={
                        handleChangeInputDisclaimerHeading
                      }
                    />
                  </Card>
                </Accordion>
              </FormGroup>
            </div>
          </Col>
          <Col
            md={7}
            className={`bg-white treasury-modal position-relative overflow-x-hidden pb-3 pl-0 ${
              currentAccordionKey === null
                ? 'd-flex align-items-center justify-content-center'
                : ''
            }`}
          >
            {currentAccordionKey === null && (
              <NoDataFound
                title="No page selected"
                description="Please expand a page menu from left to edit values"
                icon="analytics"
                containerStyle="text-gray-900"
              />
            )}
            <div className="py-2 px-3">
              <div>
                {currentAccordionKey ===
                  CommercialCardReportSections.Highlights && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={CommercialCardReportSections.Highlights}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  CommercialCardReportSections.VendorAnalysisDetails && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={CommercialCardReportSections.VendorAnalysisDetails}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  CommercialCardReportSections.Insights && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={CommercialCardReportSections.Insights}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  CommercialCardReportSections.MovingForwardTogether && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={CommercialCardReportSections.MovingForwardTogether}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  CommercialCardReportSections.Disclaimer && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={CommercialCardReportSections.Disclaimer}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </SimpleModalCreation>
    </>
  );
};

const useGenerateCommercialCardModel = (
  report,
  organization,
  modalReportPages,
  modalWidgets,
  handleGenerateReport,
  getOrganization
) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [widgets, setWidgets] = useState({});
  const [reportPages, setReportPages] = useState({});

  const GenerateCommercialCardReportModalCallback = useCallback(() => {
    return (
      <GenerateCommercialCardReportModal
        show={showModal}
        setShow={setShowModal}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
        organization={organization}
        modalReportPages={reportPages}
        modalWidgets={widgets}
        handleGenerateReport={handleGenerateReport}
        getOrganization={getOrganization}
      />
    );
  }, [showModal, setShowModal, selectedReport, setSelectedReport]);

  return useMemo(
    () => ({
      setShowModal,
      setSelectedReport,
      setModalWidgets: setWidgets,
      setModalReportPages: setReportPages,
      GenerateCommercialCardReportModal:
        GenerateCommercialCardReportModalCallback,
    }),
    [setShowModal, GenerateCommercialCardReportModalCallback]
  );
};

export default useGenerateCommercialCardModel;
