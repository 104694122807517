import React, { useState } from 'react';
import { Card, CardBody, FormGroup, Label } from 'reactstrap';
import SicNaicsAutoComplete from '../../../prospecting/v2/common/SicNaicsAutoComplete';
import { NAICS_STORAGE_KEY } from '../../../../utils/Utils';
import ReactDatepicker from '../../../inputs/ReactDatpicker';
import useSicNaicsChangeDetect from '../../../../hooks/useSicNaicsChangeDetect';
import { usePagesContext } from '../../../../contexts/pagesContext';
import { AccountStructureTabMap } from '../account.structure.constants';
import { getCycleDate } from '../../reports.helper.functions';
import ValidationErrorText from '../../../commons/ValidationErrorText';

const ReportInfo = ({ errorFields = {}, setErrorFields, organization }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportInfo.key;
  const [reportInfo, setReportInfo] = useState(
    pageContext?.AccountStructureReportModal[jsonKey] || {}
  );
  const { setShowModal, setCompany, NaicsModal } = useSicNaicsChangeDetect(
    reportInfo,
    organization,
    () => {}
  );
  return (
    <Card>
      <CardBody className="p-3">
        <NaicsModal />
        <FormGroup>
          <Label for="title">Company Name</Label>
          <input
            name="name"
            type="text"
            value={reportInfo.companyName}
            placeholder="Enter Company Name"
            onChange={(e) => {
              setReportInfo({
                ...reportInfo,
                companyName: e.target.value,
              });
              setPageContext({
                ...pageContext,
                AccountStructureReportModal: {
                  ...pageContext.AccountStructureReportModal,
                  [jsonKey]: {
                    ...reportInfo,
                    companyName: e.target.value,
                  },
                },
              });
              setErrorFields({
                ...errorFields,
                name: {
                  ...errorFields.name,
                  isShow: !e.target.value,
                },
              });
            }}
            className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
              errorFields.name.isShow ? 'border-danger' : ''
            }`}
          />
          {errorFields.name.isShow && (
            <ValidationErrorText text={errorFields.name.message} />
          )}
        </FormGroup>
        <FormGroup>
          <Label for="title">NAICS</Label>
          <SicNaicsAutoComplete
            data={reportInfo}
            setData={setReportInfo}
            placeholder="Enter a NAICS code"
            customKey="valueN"
            callKey={NAICS_STORAGE_KEY}
            callType="getNaicsCodes"
            showFieldError={errorFields.naics?.isShow}
            isDisabled={!!organization.naics_code}
            onSelect={(item, naicsSicOnly, naicsTitle) => {
              setReportInfo({
                ...reportInfo,
                valueN: [item],
                valueNaicsSic: naicsSicOnly,
                industry: naicsTitle,
              });
              setPageContext({
                ...pageContext,
                AccountStructureReportModal: {
                  ...pageContext.AccountStructureReportModal,
                  [jsonKey]: {
                    ...reportInfo,
                    valueN: [item],
                    valueNaicsSic: naicsSicOnly,
                    industry: naicsTitle,
                  },
                },
              });
              setErrorFields({
                ...errorFields,
                naics: {
                  ...errorFields.naics,
                  isShow: !naicsSicOnly,
                },
              });
              if (naicsSicOnly) {
                if (naicsSicOnly !== organization.naics_code) {
                  setShowModal(true);
                  setCompany(organization);
                }
              }
            }}
          />
          {errorFields.naics?.isShow && (
            <ValidationErrorText text={errorFields.naics.message} />
          )}
        </FormGroup>
        <FormGroup className="date-wrapper">
          <Label for="title">Report Date</Label>
          <ReactDatepicker
            id={'rptDate'}
            name={'reportDate'}
            todayButton="Today"
            value={
              reportInfo.reportDate ? getCycleDate(reportInfo.reportDate) : ''
            }
            autoComplete="off"
            className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
              errorFields.reportDate?.isShow ? 'border-danger' : ''
            }`}
            placeholder="Select Report Date"
            format="MMMM yyyy"
            onChange={(date) => {
              setReportInfo({
                ...reportInfo,
                reportDate: date,
              });
              setPageContext({
                ...pageContext,
                AccountStructureReportModal: {
                  ...pageContext.AccountStructureReportModal,
                  [jsonKey]: {
                    ...reportInfo,
                    reportDate: date,
                  },
                },
              });
              setErrorFields({
                ...errorFields,
                reportDate: {
                  ...errorFields.reportDate,
                  isShow: !date,
                },
              });
            }}
            showMonthYearPicker
            showFullMonthYearPicker
          />
          {errorFields.reportDate?.isShow && (
            <ValidationErrorText text={errorFields.reportDate.message} />
          )}
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default ReportInfo;
