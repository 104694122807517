import PageTitle from '../../components/commons/PageTitle';
import React, { useContext, useEffect, useState } from 'react';
import SelfAssessment from '../Resources/selfAssessment/SelfAssessment';
import MyFavorites from '../Resources/MyLessons';
import categoryService from '../../services/category.service';
import Category from '../Resources/category/Category';
import { useHistory } from 'react-router-dom';
import {
  generatePath,
  isModuleAllowed,
  LearnViewTypes,
  sortByPinnedTopics,
} from '../../utils/Utils';
import { TenantContext } from '../../contexts/TenantContext';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import OrganizationTopics from './OrganizationTopics';
import { useProfileContext } from '../../contexts/profileContext';
import KnowledgeAssessment from '../Resources/knowledgeAssessment/KnowledgeAssessment';
import { PermissionsConstants } from '../../utils/permissions.constants';
import _ from 'lodash';
import MyOrganizationCover from '../../assets/png/learn/categories/MyOrganization-Category.png';
import AgileMindsetCover from '../../assets/png/learn/categories/AgileMindset-Category.png';
import CommercialCardCover from '../../assets/png/learn/categories/CommercialCard-Category.png';
import CoreProductAndServicesCover from '../../assets/png/learn/categories/CoreProductsAndServices-Category.png';
import FasterPaymentsCover from '../../assets/png/learn/categories/FasterPayments-Category.png';
import FraudAndRiskCover from '../../assets/png/learn/categories/FraudAndRisk-Category.png';
import HowToCover from '../../assets/png/learn/categories/HowTo-Category.png';
import IndustryInsightsCover from '../../assets/png/learn/categories/IndustryInsights-Category.png';
import MerchantServicesCover from '../../assets/png/learn/categories/MerchantServices-Category.png';
import SalesStrategyCover from '../../assets/png/learn/categories/SalesStrategyAndSkills-Category.png';
import WorkingCapitalandWorkflowsCover from '../../assets/png/learn/categories/WorkingCapitalAndWorkflows-Category.png';
import AIAdvisor from '../Resources/aiAdvisor/AIAdvisor';
import MaterialIcon from '../../components/commons/MaterialIcon';
import { Accordion, Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import TextOverflowTooltip from '../../components/commons/TextOverflowTooltip';
import MyAssignments from '../../components/Assignments/MyAssignments';
import CardSkeleton from '../../components/lesson/CardSkeleton';
import SubHeading from '../../components/subheading';
import Overview from './Overview';
import lessonService from '../../services/lesson.service';
import courseService from '../../services/course.service';
import CourseDetail from '../Resources/CourseDetail';
import Lesson from '../Resources/Lesson';
import assignmentService from '../../services/assignment.service';

const CategoryCovers = {
  AgileMindset: AgileMindsetCover,
  CommercialCard: CommercialCardCover,
  CoreProductsandServices: CoreProductAndServicesCover,
  FasterPayments: FasterPaymentsCover,
  FraudandRisk: FraudAndRiskCover,
  HowTo: HowToCover,
  IndustryInsights: IndustryInsightsCover,
  MerchantServices: MerchantServicesCover,
  MyOrganization: MyOrganizationCover,
  SalesStrategyandSkills: SalesStrategyCover,
  WorkingCapitalandWorkflows: WorkingCapitalandWorkflowsCover,
};
const convertToPascalCase = (str) => {
  return _.upperFirst(_.camelCase(str.replace(/\s+/g, '')));
};

const mapCategoryPath = (item) => {
  const path = item.title.toLocaleLowerCase().trim().replace(/ /g, '-');
  const cover = CategoryCovers[convertToPascalCase(item.title)];
  return {
    ...item,
    path: generatePath(item, path),
    cover,
  };
};

const Learn = () => {
  const history = useHistory();
  const [categories, setCategories] = useState({});
  const [, setOwnerAccess] = useState(false);
  const searchParams = useUrlSearchParams();
  const title = searchParams.get('title');
  const id = searchParams.get('id');
  const path = searchParams.get('path');
  const viewType = searchParams.get('viewType') || '';
  const ref = searchParams.get('ref');
  const customViewType = searchParams.get('customViewType');
  const { tenant } = useContext(TenantContext);
  const { profileInfo } = useProfileContext();
  const [refresh, setRefresh] = useState(0);

  const filterList = [
    {
      id: 1,
      name: 'Categories',
      moduleName: PermissionsConstants.Learn.LessonCatalog,
      icon: 'category',
      key: LearnViewTypes.Topics,
      submenu: null, // will list all public categories
      show: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.Learn.LessonCatalog
      ),
      component: null,
    },
    {
      id: 2,
      name: 'My Library',
      icon: 'book',
      count: 0,
      key: LearnViewTypes.Library,
      show:
        isModuleAllowed(
          tenant?.modules,
          PermissionsConstants.Learn.Assignments
        ) ||
        isModuleAllowed(
          tenant?.modules,
          PermissionsConstants.Learn.LessonCatalog
        ),
      submenu: [
        {
          name: 'Assignments',
          key: LearnViewTypes.MyAssignments,
          moduleName: PermissionsConstants.Learn.Assignments,
          component: <MyAssignments setRefresh={setRefresh} />,
          count: 0,
          show: isModuleAllowed(
            tenant?.modules,
            PermissionsConstants.Learn.Assignments
          ),
        },
        {
          name: 'Favorites',
          key: LearnViewTypes.MyFavorites,
          moduleName: PermissionsConstants.Learn.LessonCatalog,
          component: <MyFavorites setRefresh={setRefresh} />,
          count: 0,
          show: isModuleAllowed(
            tenant?.modules,
            PermissionsConstants.Learn.LessonCatalog
          ),
        },
      ],
      component: null,
    },
    {
      id: 3,
      name: 'Assessment',
      icon: 'assignment',
      key: LearnViewTypes.Assessment,
      show:
        isModuleAllowed(
          tenant?.modules,
          PermissionsConstants.Learn.SelfAssessment
        ) ||
        isModuleAllowed(
          tenant?.modules,
          PermissionsConstants.Learn.KnowledgeAssessment
        ),
      submenu: [
        {
          name: 'Self-Assessment',
          key: LearnViewTypes.SelfAssessment,
          moduleName: PermissionsConstants.Learn.SelfAssessment,
          show: isModuleAllowed(
            tenant?.modules,
            PermissionsConstants.Learn.SelfAssessment
          ),
          component: <SelfAssessment />,
        },
        {
          name: 'Knowledge Assessment',
          key: LearnViewTypes.KnowledgeAssessment,
          moduleName: PermissionsConstants.Learn.KnowledgeAssessment,
          show: isModuleAllowed(
            tenant?.modules,
            PermissionsConstants.Learn.KnowledgeAssessment
          ),
          component: <KnowledgeAssessment />,
        },
      ],
      component: null,
    },
    {
      id: 4,
      name: 'Ask AI Advisor',
      icon: 'voice_chat',
      key: LearnViewTypes.AIAdvisor,
      show: isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.Learn.AIAdvisor
      ),
      component: <AIAdvisor />,
    },
  ];

  const organizationFilter = {
    id: '1',
    name: 'My Organization',
    key: LearnViewTypes.Custom,
    component: <OrganizationTopics setRefresh={setRefresh} />,
    submenu: null,
  };

  const overviewFilter = {
    id: 1,
    name: 'Categories',
    key: LearnViewTypes.Overview,
    component: null,
    submenu: null,
  };

  const [stats, setStats] = useState({});
  const [updateFilterList, setUpdateFilterList] = useState(filterList);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState();

  const handleTopicCustomMenuClick = (e, item, type) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedFilter(item);

    if (item?.type === 'custom') {
      history.push(item?.url);
    } else {
      const titlePath = item.title
        .toLocaleLowerCase()
        .trim()
        .replace(/ /g, '-');
      history.push(generatePath(item, titlePath));
    }
  };

  const handleSelectFilter = (item) => {
    if (item.key !== LearnViewTypes.Topics) {
      history.replace({
        search: `?viewType=${item.key}`,
      });
    }
    setSelectedFilter(item);
  };

  const MenuList = ({ list }) => {
    return (
      <>
        {list?.map((submenu, index) => {
          return submenu.show ? (
            <Row
              key={submenu?.key || index}
              onClick={() => handleSelectFilter(submenu)}
              className={`cursor-pointer accordion-sub-menu align-items-center px-4_1 py-2 nav-link item-filter ${
                selectedFilter?.key === submenu?.key
                  ? 'text-primary active bg-primary-soft fw-600'
                  : ''
              }`}
            >
              <Col className="px-2_1 d-flex align-items-center w-100" md={12}>
                <p className="py-0 px-0 my-0">
                  <span className="font-weight-medium font-size-sm2 mb-0">
                    <TextOverflowTooltip text={submenu?.name} maxLength={30} />
                  </span>
                </p>
                {submenu.count && submenu.count > 0 ? (
                  <div
                    className={`ml-2 d-flex justify-content-center bg-primary align-items-center rounded-circle size-18`}
                  >
                    <span className={`fs-8 font-weight-normal text-white`}>
                      {submenu.count}
                    </span>
                  </div>
                ) : null}
              </Col>
            </Row>
          ) : null;
        })}
      </>
    );
  };

  const CategoryList = ({ list, type }) => {
    return (
      <>
        {sortByPinnedTopics(list)?.map((item, index) => (
          <Row
            key={item?.id || index}
            onClick={(e) => handleTopicCustomMenuClick(e, item, type)}
            className={`cursor-pointer accordion-sub-menu align-items-center px-4_1 py-2 nav-link item-filter ${
              selectedFilter?.id === item?.id
                ? 'text-primary active bg-primary-soft fw-600'
                : ''
            }`}
          >
            <Col className="px-2_1 d-flex align-items-center w-100">
              <p className="py-0 px-0 my-0">
                <span className="font-weight-medium font-size-sm2 mb-0">
                  <TextOverflowTooltip
                    text={item?.title?.split('-')[0]?.trim()}
                    maxLength={30}
                  />
                </span>
              </p>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const getCategories = async () => {
    try {
      const favoirtes_filter = {
        order: [
          ['progress.completed_at', 'asc nulls first'],
          ['progress.last_attempted_at', 'desc nulls last'],
          ['updated_at', 'desc'],
        ],
      };

      const myOrgData = [
        lessonService.getLessons({
          page: 1,
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
        courseService.getCourses({
          page: 1,
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
        assignmentService.getAssignments({
          page: 1,
          limit: 1,
          self: true,
          status: 'active',
          retrieveAssigned: true,
        }),
        lessonService.getLessons({
          page: 1,
          limit: 1,
          self: true,
          favorites: 'required',
          progress: 'include',
          ...favoirtes_filter,
        }),
        courseService.getCourses({
          page: 1,
          limit: 1,
          self: true,
          favorites: 'required',
          progress: 'include',
          ...favoirtes_filter,
        }),
      ];

      const [
        lessonsResponse,
        coursesResponse,
        assignmentsResponse,
        favoriteLessons,
        favoriteCourses,
      ] = await Promise.all(myOrgData);

      const lessons = lessonsResponse.data;
      const courses = coursesResponse.data;
      const assignmentsCount = assignmentsResponse?.pagination?.count || 0;
      const favoritesCount =
        (favoriteLessons?.pagination?.count || 0) +
          favoriteCourses?.pagination?.count || 0;

      const data = {
        totalLessons: lessons?.length,
        totalCourses: courses?.length,
      };

      setStats(data);

      const requests = [
        categoryService.GetCategories(null, {
          limit: 100,
          restrictBy: 'externalPublic',
          requireContent: false,
          status: 'published',
        }),
        categoryService.GetCategories(null, {
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
      ];

      const responses = await Promise.all(requests);
      const exploreList = responses[0].data?.map((m) => ({
        ...m,
        isPublic: true,
      }));
      const customList = responses[1].data?.map((m) => ({
        ...m,
        isPublic: false,
      }));
      const updatedExploreList = exploreList.filter(
        (item) => item.title !== 'My Organization'
      );
      setCategories({
        exploreList: updatedExploreList?.map(mapCategoryPath),
        customList: customList.map(mapCategoryPath),
      });
      const updatedSubMenu = [...updatedExploreList];
      updatedSubMenu.unshift({
        title: 'My Organization',
        type: 'custom',
        id: '1',
        cover: CategoryCovers.MyOrganization,
        key: LearnViewTypes.Custom,
        component: <OrganizationTopics />,
        url: '?viewType=custom&ref=my-organization',
      });

      let newFilters = [...updateFilterList].map((s) => ({
        ...s,
        submenu:
          s.key === LearnViewTypes.Topics
            ? updatedSubMenu?.map(mapCategoryPath)
            : s.submenu,
      }));

      if (courses.length !== 0 || lessons.length !== 0) {
        newFilters = newFilters?.map((filter) => {
          if (filter.key === LearnViewTypes.Library) {
            return {
              ...filter,
              count: assignmentsCount + favoritesCount,
              submenu: filter.submenu?.map((submenuItem) => {
                if (submenuItem.key === LearnViewTypes.MyAssignments) {
                  return { ...submenuItem, count: assignmentsCount };
                } else if (submenuItem.key === LearnViewTypes.MyFavorites) {
                  return { ...submenuItem, count: favoritesCount };
                }
                return submenuItem;
              }),
            };
          }
          return filter;
        });
      }
      setUpdateFilterList(newFilters);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const isLessonCatalogAllowed = isModuleAllowed(
    tenant?.modules,
    PermissionsConstants.Learn.LessonCatalog
  );

  useEffect(() => {
    isLessonCatalogAllowed && getCategories();
  }, [viewType, path, id, refresh]);

  useEffect(() => {
    if (!viewType) {
      setSelectedFilter(overviewFilter);
    } else if (viewType === 'custom' && ref === 'my-organization') {
      // Case 1: Set organization filter and active index
      setSelectedFilter(organizationFilter);
    } else if (viewType === 'explore') {
      // Case 2: View type is "explore" - match by ID in submenu
      const exploreFilter = updateFilterList?.find(
        (filter) => filter.key === 'explore'
      );
      const submenuFilter = exploreFilter?.submenu?.find(
        (item) => item.id === parseInt(id, 10)
      );
      if (submenuFilter) {
        setSelectedFilter(submenuFilter);
      }
    } else {
      // Case 3: Other view types - match viewType by checking all filters and their submenus
      let found = false;

      for (const filter of updateFilterList) {
        if (filter.key === 'explore') continue; // Skip "explore"

        // Check submenus for a match
        const submenuMatch = filter.submenu?.find(
          (submenu) => submenu.key === viewType
        );
        if (submenuMatch) {
          setSelectedFilter(submenuMatch);
          found = true;
          break;
        }

        // Check filters without submenu
        if (filter.key === viewType && !filter.submenu) {
          setSelectedFilter(filter);
          found = true;
          break;
        }
      }

      if (!found) {
        setSelectedFilter(overviewFilter); // Fallback to default
      }
    }
  }, [viewType, updateFilterList, customViewType]);

  useEffect(() => {
    if (profileInfo?.role) {
      setOwnerAccess(profileInfo.role.owner_access);
    }
  }, [profileInfo]);

  const handleToggleAccordion = (key) => {
    setActive(active === key ? null : key);
  };

  const getDropdownFilteredItems = () => {
    return updateFilterList?.filter((item) => {
      if (item?.moduleName === PermissionsConstants.Learn.Assignments) {
        return (
          isModuleAllowed(
            tenant?.modules,
            PermissionsConstants.Learn.Assignments
          ) ||
          isModuleAllowed(
            tenant?.modules,
            PermissionsConstants.Learn.LessonCatalog
          )
        );
      } else {
        return item?.moduleName
          ? isModuleAllowed(tenant?.modules, item?.moduleName)
          : item;
      }
    });
  };

  const filterEnabledMenu = (menu) => !!menu.show;
  useEffect(() => {
    // auto select first enabled item when click on learn menu
    if (tenant?.modules && !viewType) {
      const filteredMenuItems = getDropdownFilteredItems();
      setUpdateFilterList(filteredMenuItems);
      if (filteredMenuItems.length) {
        const firstItem = filteredMenuItems.find(filterEnabledMenu);
        if (firstItem?.id !== overviewFilter.id) {
          if (firstItem?.submenu?.length) {
            handleSelectFilter(firstItem.submenu.find(filterEnabledMenu));
          } else {
            handleSelectFilter(firstItem);
          }
        }
      }
    }
  }, [tenant]);

  return (
    <>
      <PageTitle page={selectedFilter?.name || 'Learn'} pageModule="" />
      <div className="custom-layout">
        <div className="custom-layout-sidebar overflow-x-hidden">
          <div className="d-flex overflow-y-auto overflow-x-hidden flex-column p-3">
            {loading ? (
              <Skeleton count={5} height={30} className="w-100" />
            ) : (
              <ul className="list-unstyled mb-0 transparent-scroll-track">
                {updateFilterList?.map((i, index) => {
                  return i.show ? (
                    <li key={index} className="accordion-wrapper">
                      <Accordion activeKey={active}>
                        <Accordion.Toggle
                          onClick={() =>
                            i.component
                              ? handleSelectFilter(i)
                              : handleToggleAccordion(i.id)
                          }
                          eventKey={i.id}
                          className={`${
                            active === i.id || selectedFilter?.key === i.key
                              ? 'bg-accordion-active rounded-top rounded-left-0 rounded-right-0 rounded-bottom-0'
                              : 'bg-white'
                          } accordion-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
                        >
                          <div className="d-flex align-items-center">
                            <span className="material-symbols-rounded fs-20">
                              {i.icon}
                            </span>
                            <span className="ml-2 text-capitalize py-0">
                              {i.name}
                            </span>

                            {i.count && i.count > 0 ? (
                              <div
                                className={`ml-2 d-flex justify-content-center bg-primary align-items-center rounded-circle size-18`}
                              >
                                <span
                                  className={`fs-8 font-weight-normal text-white`}
                                >
                                  {i.count}
                                </span>
                              </div>
                            ) : null}

                            {!i.component && (
                              <MaterialIcon
                                clazz="ml-auto"
                                icon={
                                  active === i.id
                                    ? 'keyboard_arrow_up'
                                    : 'keyboard_arrow_down'
                                }
                              />
                            )}
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey={i?.id}
                          className="accordion-content-wrapper"
                        >
                          <div className="py-0 overflow-x-hidden">
                            {i.key === LearnViewTypes.Topics ? (
                              <CategoryList list={i.submenu} type={i.key} />
                            ) : (
                              <MenuList list={i.submenu} />
                            )}
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    </li>
                  ) : null;
                })}
              </ul>
            )}
          </div>
        </div>

        <div className="custom-layout-content bg-white">
          <>
            {loading ? (
              <>
                <div className="px-3 position-relative page-header-divider">
                  <SubHeading title="Categories" headingStyle="m-0 p-0" />
                </div>
                <div className="px-5 pt-3">
                  <CardSkeleton
                    count={3}
                    cols="row-cols-md-3"
                    className="p-3"
                  />
                </div>
              </>
            ) : customViewType === 'course' ? (
              <CourseDetail setRefreshFav={setRefresh} />
            ) : customViewType === 'lesson' ? (
              <Lesson setRefreshFav={setRefresh} />
            ) : id && path ? (
              <Category
                category={{ id, title, path }}
                topics={categories?.exploreList?.concat(categories?.customList)}
                setRefresh={setRefresh}
              />
            ) : (
              <>
                <div className="pt-0">
                  {selectedFilter?.key === LearnViewTypes.Overview ? (
                    <>
                      <Overview
                        loading={loading}
                        setSelectedFilter={setSelectedFilter}
                        topics={categories}
                        stats={stats}
                        updateFilterList={updateFilterList}
                        selectedFilter={selectedFilter}
                        organizationFilter={organizationFilter}
                      />
                    </>
                  ) : (
                    selectedFilter?.component
                  )}
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default Learn;
