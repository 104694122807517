import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { CardBody, Card, CardHeader, Spinner } from 'reactstrap';
import NoDataFound from './commons/NoDataFound';
import LayoutCard from './lesson/layoutCard';
import SubHeading from './subheading';
import { removeAndReplaceParams } from '../views/Resources/category/constants/Category.constants';

export const LessonLayout = (props) => {
  const {
    children,
    lesson,
    setGetLessonId,
    type,
    viewType,
    relatedLessons,
    course,
    categoryTitle,
    setRefresh,
    loading,
  } = props;

  const location = useLocation();
  const history = useHistory();

  const completedLessons = relatedLessons?.filter(
    (lesson) => lesson.progress === 100
  );
  const progress = (100 / relatedLessons?.length) * completedLessons?.length;

  const filteredLessons =
    viewType === 'lesson'
      ? relatedLessons?.filter((item) => lesson?.id !== item?.id)
      : relatedLessons;

  const returnUrl = removeAndReplaceParams(
    location,
    ['customViewType', 'customViewId'],
    {
      categoryId: 'id',
      catTitle: 'title',
    }
  );

  return (
    <>
      <div className="px-3 position-relative page-header-divider">
        <SubHeading
          title={
            categoryTitle ||
            lesson?.category?.title ||
            lesson?.contents?.title ||
            ''
          }
          headingStyle="m-0 p-0"
          showArrow
          onClick={() => history.push(returnUrl)}
        />
      </div>
      <div className="mt-4 d-flex justify-content-center mw-70 mx-auto">
        <div className="d-flex flex-column gap-4 w-100 mb-5">
          <div>{children}</div>
          <Card className="lesson-layout-card rounded-0 w-100">
            <CardHeader className="py-2_1 font-size-sm2 px-3">
              <div className="d-flex justify-content-between align-items-center w-100 p-1_2 py-0">
                {course ? (
                  <>
                    <p>{`Progress: ${progress?.toFixed(0) || 0}% Complete`}</p>
                    <p>{`Lessons: ${completedLessons?.length} of ${relatedLessons?.length}`}</p>
                  </>
                ) : (
                  <p>Related Lessons</p>
                )}
              </div>
            </CardHeader>
            <CardBody className="p-0">
              {loading ? (
                <div className="my-5 text-center">
                  <Spinner
                    color="primary"
                    size="sm"
                    className="spinner-grow-sm2"
                  />
                </div>
              ) : (
                <>
                  {filteredLessons?.length === 0 ? (
                    <NoDataFound title="No lessons found" />
                  ) : (
                    <>
                      <div className="d-flex flex-column lesson-items">
                        {filteredLessons?.map((item, index) => {
                          const matchingLesson = filteredLessons?.find(
                            (lessonItem) => lessonItem.id === lesson?.id
                          );

                          return (
                            <LayoutCard
                              learnViewTypes={type}
                              key={item}
                              disabled={
                                course &&
                                item?.progress !== 100 &&
                                relatedLessons[index] &&
                                lesson?.id !== item?.id &&
                                matchingLesson?.progress !== 100
                              }
                              setGetLessonId={setGetLessonId}
                              lesson={lesson}
                              course={course}
                              item={item}
                              viewType={viewType}
                              setRefresh={setRefresh}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};
