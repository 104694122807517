import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import dashboardService from '../../../../services/dashboard.service';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import UserTranscriptDashboard from '../../components/InsightsQueryUserTranscript';
import ChecklistActivityReport from '../ChecklistActivityReport';
import ChecklistOverviewStats from '../ChecklistOverviewStats';
import ChecklistAverageStats from '../ChecklistAverageStats';
import ChecklistFastestStats from '../ChecklistFastestStats';
import ChecklistOverviewReportStats from '../ChecklistOverviewReportStats';
import { cubeService } from '../../../../services';
import ChecklistLeaderStats from '../ChecklistLeaderStats';

const ChecklistsOverview = ({
  dashboard,
  selectedComponent = {},
  componentHeight = 'h-100',
  handleCallUserComponent = () => {},
}) => {
  const renderComponents = [
    {
      name: 'Total Checklist Completed',
      render: ChecklistOverviewReportStats,
    },
    {
      name: 'Total Checklist In Progress',
      render: ChecklistOverviewReportStats,
    },
    {
      name: 'Total Checklist Not Started',
      render: ChecklistOverviewReportStats,
    },
    {
      name: 'Total Checklist OverDue',
      render: ChecklistOverviewReportStats,
    },

    {
      name: 'Checklists Split',
      render: ChecklistOverviewStats,
    },
    {
      name: 'Average Completion Time',
      render: ChecklistAverageStats,
    },
    {
      name: 'Fastest Completion Time',
      render: ChecklistFastestStats,
    },
    {
      name: 'Checklists Leader',
      render: ChecklistLeaderStats,
    },
    {
      name: 'Active Checklists',
      render: ChecklistActivityReport,
    },
  ];
  const [loading, setLoading] = useState(false);

  const [dashboardComponents, setDashboardComponents] = useState([]);
  const [query, setQuery] = useState({});
  const [checklistCount, setChecklistCount] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const getDashboards = async () => {
    setLoading(true);

    const data = await dashboardService.getAnalytics();

    setLoading(false);

    const updatedData = data
      ?.filter((item) => {
        const matchedComponent = renderComponents?.find(
          (rc) => rc.name === item?.name
        );
        return !!matchedComponent;
      })
      .map((item) => {
        const matchedComponent = renderComponents?.find(
          (rc) => rc.name === item?.name
        );
        return { ...item, renderComponent: matchedComponent };
      });

    setDashboardComponents(updatedData);
  };

  useEffect(() => {
    (async () => {
      const query = {
        dimensions: [],
        measures: ['Checklist.count'],
        filters: [
          {
            member: 'Checklist.status',
            values: ['active'],
            operator: 'equals',
          },
        ],
        order: [['OrganizationChecklist.count', 'desc']],
        segments: [],
        timeDimensions: [],
      };
      const results = await cubeService.loadAnalytics({ query });
      setChecklistCount(results?.data[0]);
    })();
  }, []);

  const desiredOrder = [
    'Total Checklist Completed',
    'Total Checklist In Progress',
    'Total Checklist Not Started',
    'Total Checklist OverDue',

    'Checklists',
    'Checklists Split',
    'Average Completion Time',
    'Fastest Completion Time',
    'Checklists Leader',
    'Active Checklists',
  ];

  let sortedDashboardComponents = dashboardComponents.sort((a, b) => {
    // Get the component names
    const nameA = a?.component?.name || '';
    const nameB = b?.component?.name || '';
    const indexA = desiredOrder.indexOf(nameA);
    const indexB = desiredOrder.indexOf(nameB);

    return indexA - indexB;
  });
  useEffect(() => {
    getDashboards();
  }, [selectedComponent]);
  sortedDashboardComponents = sortedDashboardComponents?.map((item, index) => {
    return {
      ...item,
      width: index < 4 ? 'col-md-3 px-2' : 'w-100',
    };
  });

  const handleSortByData = (key) => {
    setOrderBy({
      order: [[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]],
    });
  };

  return (
    <Card className={`overflow-x-hidden overflow-y-auto ${componentHeight}`}>
      <CardHeader>
        <div className="d-flex justify-content-between align items center w-100">
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}
          </h4>
          <p className="mb-0 fw-bold pt-2 pb-2">
            Total Active Checklists:{' '}
            <span>{checklistCount?.['Checklist.count']}</span>
          </p>
        </div>
      </CardHeader>
      <div className="py-3 px-2">
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={20} height={15} />
          </div>
        ) : (
          <div className="row p-0 align-items-center overview-dashboard justify-content-center mx-0">
            <UserTranscriptDashboard
              dashboardComponents={sortedDashboardComponents}
              query={query}
              orderBy={orderBy}
              handleCallUserComponent={handleCallUserComponent}
              setQuery={setQuery}
              handleSortByData={handleSortByData}
              checklistReportSorting={true}
              width={'w-100'}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ChecklistsOverview;
