import { Row, Col } from 'reactstrap';
import MaterialIcon from './commons/MaterialIcon';

export default function SubHeading({
  title,
  containerClass = 'mb-2 d-flex align-items-center gap-1',
  headingStyle = 'mt-3 mb-2',
  showArrow = false,
  path,
  onClick,
  clazz,
}) {
  return (
    <Row>
      <Col className={containerClass}>
        {showArrow ? (
          <div
            onClick={onClick}
            className="cursor-pointer bg-hover-gray position-absolute icon-hover-bg rounded-circle d-flex align-items-center"
          >
            <MaterialIcon icon="keyboard_backspace" clazz={clazz} />
          </div>
        ) : null}
        <h4
          style={{ paddingLeft: showArrow ? 35 : 0 }}
          className={!showArrow ? headingStyle : 'mb-0 pt-0 mt-0'}
        >
          {title || 'Loading...'}
        </h4>
      </Col>
    </Row>
  );
}
