import React, { useEffect, useReducer, useState } from 'react';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import KnowledgeQuestions from './knowledgeAssessmentQuestions.json';
import KnowledgeAssessmentBanner from './KnowledgeAssessmentBanner';
import { useProfileContext } from '../../../contexts/profileContext';
import moment from 'moment/moment';
import { ReportTypes } from '../../../components/reports/reports.constants';
import { DATE_FORMAT_TIME_WO_SEC } from '../../../utils/Utils';
import ReportService from '../../../services/report.service';
import SubHeading from '../../../components/subheading';
import { ProgressBar } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import routes from '../../../utils/routes.json';
import MaterialIcon from '../../../components/commons/MaterialIcon';

const PageHeading = 'SS&D Product Knowledge Assessment';

export default function KnowledgeAssessment() {
  const { profileInfo } = useProfileContext();
  const history = useHistory();
  const isFullPage = history.location.pathname.includes(
    routes.knowledgeAssessment
  );
  const assessmentQuestions = Object.values(KnowledgeQuestions);
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [, setAssessments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [assessment, updateAssessment] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      start: false,
      completed: false,
      showResults: false,
      finalResult: {},
      progress: 0,
      scored: 0,
    }
  );

  const calculateCorrectAnswers = (answers) => {
    return answers.filter((ans) => {
      return (
        ans.answer.userSelected === ans.answer.correctAnswer ||
        ans.answer.correctAnswer.includes(ans.answer.userSelected)
      );
    })?.length;
  };

  const resetForm = () => {
    updateAssessment({
      start: false,
      completed: false,
      showResults: false,
      finalResult: {},
      progress: 0,
      scored: 0,
    });
  };

  const hasPassed30Days = (inputDate) => {
    const inputMoment = moment(inputDate, 'YYYY-MM-DD');
    const currentMoment = moment();
    const daysDifference = currentMoment.diff(inputMoment, 'days');
    return daysDifference >= 30;
  };

  const getAssessments = async () => {
    try {
      setLoader(true);
      const { data } = await ReportService.getReports({
        type: ReportTypes.KnowledgeAssessment,
        limit: 1,
        page: 1,
      });

      const pastAssessments = data;
      setAssessments(pastAssessments);
      const userCreated = pastAssessments.find(
        (assessment) => assessment.createdById === profileInfo?.id
      );
      const are30DaysPassed = hasPassed30Days(userCreated?.date);
      const hasCompleted =
        pastAssessments.length === 0 ||
        userCreated === undefined ||
        are30DaysPassed;

      if (are30DaysPassed) {
        // Reset to default assessment configuration
        setIsAllowed(hasCompleted);
        resetForm();
      } else {
        setIsAllowed(hasCompleted);
        updateAssessment({
          completed: userCreated !== undefined,
          showResults: false,
          progress: userCreated !== undefined ? 100 : 0,
          start: userCreated !== undefined,
          scored:
            userCreated !== undefined
              ? userCreated?.manualInput?.scored ||
                calculateCorrectAnswers(
                  userCreated?.manualInput?.answers ||
                    userCreated?.manualInput?.finalResult
                )
              : 0,
        });
      }
    } catch (e) {
      console.error(e);
      setErrorMessage('Error fetching assessments.');
    } finally {
      setLoader(false);
      setLoading(false);
    }
  };
  const submitQuestionnaire = async (submissionRequest) => {
    try {
      setLoader(true);
      const now = moment();
      const name =
        profileInfo?.name ||
        `${profileInfo?.first_name} ${profileInfo?.last_name || ''}`;
      // correct answers count to show percentage at the end
      const correctAnswers = calculateCorrectAnswers(
        submissionRequest.finalResult
      );
      const reportObject = {
        name: `${name?.trim()} Knowledge Assessment - ${now.format(
          DATE_FORMAT_TIME_WO_SEC
        )}`,
        date: now.toISOString(),
        createdById: profileInfo.id,
        manualInput: {
          answers: submissionRequest.finalResult,
          scored: correctAnswers,
        },
        type: ReportTypes.KnowledgeAssessment,
      };
      await ReportService.createReport(reportObject);
      // once save user cannot take another
      setIsAllowed(false);
      // reset once submitted
      updateAssessment({
        start: true,
        completed: true,
        showResults: false,
        finalResult: {},
        progress: 100,
        scored: correctAnswers,
      });
    } catch (e) {
      console.log(e);
      setErrorMessage('Error in submitting knowledge assessment.');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    profileInfo?.id && getAssessments();
  }, [profileInfo]);

  if (loading) {
    return (
      <>
        <div className={`px-3 position-relative page-header-divider`}>
          <SubHeading title={PageHeading} headingStyle="m-0 p-0" />
        </div>
        <div className="px-3 mt-3">
          <div className="d-flex flex-column align-items-center gap-4">
            <Skeleton style={{ height: 300, width: 600 }} />
            <Skeleton
              count={4}
              style={{ height: 10, width: 800 }}
              className="mb-2 mx-auto d-flex justify-content-center"
            />
            <Skeleton className="rounded" style={{ height: 42, width: 227 }} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert color="info" message={infoMessage} setMessage={setInfoMessage} />
      </AlertWrapper>

      <div className="position-relative">
        <div className={`px-3 position-relative page-header-divider`}>
          <div className="d-flex align-items-center gap-1">
            {!isFullPage && assessment.start && !assessment.completed && (
              <a
                className="cursor-pointer icon-hover-bg assess-back-arrow"
                onClick={resetForm}
              >
                <MaterialIcon icon="keyboard_backspace" />{' '}
              </a>
            )}
            <div
              style={{
                paddingLeft:
                  !isFullPage && assessment.start && !assessment.completed
                    ? 25
                    : 0,
              }}
            >
              <SubHeading
                title={`${
                  assessmentQuestions?.length &&
                  assessment.start &&
                  !assessment.completed
                    ? `${PageHeading}: Question ${currentQuestion + 1}/${
                        assessmentQuestions?.length
                      }`
                    : PageHeading
                }`}
                headingStyle="m-0 p-0"
              />
            </div>
          </div>
          {!assessment.showResults &&
            !assessment.completed &&
            assessment.start &&
            !isFullPage && (
              <ProgressBar
                now={assessment.progress}
                className={`w-100 position-absolute flat-progress bottom-0 left-0`}
              />
            )}
        </div>
        <div className="px-3">
          <div
            className="mx-xs-2 m-md-auto m-lg-auto mb-2"
            style={{ maxWidth: 900 }}
          >
            <KnowledgeAssessmentBanner
              loader={loader}
              loading={loading}
              isFullPage={isFullPage}
              isAllowed={isAllowed}
              assessment={assessment}
              setInfoMessage={setInfoMessage}
              updateAssessment={updateAssessment}
              assessmentQuestions={assessmentQuestions}
              submitQuestionnaire={submitQuestionnaire}
              setCurrentQuestion={setCurrentQuestion}
            />
          </div>
        </div>
      </div>
    </>
  );
}
