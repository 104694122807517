import React, { useEffect, useState } from 'react';
import assignmentService from '../../services/assignment.service';
import { useProfileContext } from '../../contexts/profileContext';
import CategorySection from '../../views/Resources/category/CategorySection';
import courseService from '../../services/course.service';
import lessonService from '../../services/lesson.service';
import {
  COURSES_COMMING_SOON,
  LESSONS_COMMING_SOON,
} from '../../utils/constants';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import NoDataFound from '../commons/NoDataFound';
import { useTenantContext } from '../../contexts/TenantContext';
import { isModuleAllowed } from '../../utils/Utils';
import SubHeading from '../subheading';
import CardSkeleton from '../lesson/CardSkeleton';

const MyAssignments = ({ setRefresh }) => {
  const defaultPagination = { page: 1, limit: 1000 };
  const [pagination, setPagination] = useState(defaultPagination);
  const [userAssignments, setUserAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { profileInfo } = useProfileContext();
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const { tenant } = useTenantContext();
  const searchParams = useUrlSearchParams();
  const viewType = searchParams.get('viewType');

  const fetchUserAssignments = async () => {
    setLoading(true);
    const self = true;
    const filter = {
      order: [['dueAt', 'desc']],
    };
    const response = await assignmentService.getAssignments({
      page: pagination.page,
      limit: pagination.limit,
      self,
      status: 'active',
      retrieveAssigned: true,
      ...filter,
    });

    setUserAssignments(response?.data);
    setPagination(response?.pagination);

    // Get Courses and Lessos for aLL Assignments
    await getCoursesLessons(response);
    setLoading(false);
  };

  const getCoursesLessons = async (response) => {
    setLoading(true);

    try {
      const resultData = response?.data.map(async (assignment) => {
        return Promise.all(
          assignment.contents?.map(async (item) => {
            if (item.courseId) {
              try {
                const progress = await courseService.getCourseProgress(
                  item.courseId,
                  { self: true }
                );

                if (progress?.progress !== 100) {
                  try {
                    const course = await courseService.getCourseById(
                      item.courseId,
                      { favorites: 'include' }
                    );

                    if (course !== undefined) {
                      course.dueAt = assignment.dueAt;
                      course.assignmentId = assignment.id;
                      setCourses((courses) => [...courses, course]);
                    }
                  } catch (error) {
                    console.error('Error fetching course by ID:', error);
                  }
                }
              } catch (error) {
                console.error('Error fetching course progress:', error);
              }
            } else if (item.lessonId) {
              try {
                const progress = await lessonService.getLessonProgress(
                  item.lessonId,
                  { self: true }
                );

                if (progress?.progress !== 100) {
                  try {
                    const lesson = await lessonService.getLessonById(
                      item.lessonId,
                      { favorites: 'include' }
                    );

                    if (lesson !== undefined) {
                      lesson.dueAt = assignment.dueAt;
                      lesson.assignmentId = assignment.id;
                      setLessons((lessons) => [...lessons, lesson]);
                    }
                  } catch (error) {
                    console.error('Error fetching lesson by ID:', error);
                  }
                }
              } catch (error) {
                console.error('Error fetching lesson progress:', error);
              }
            }
          })
        );
      });

      await Promise.all(resultData);
      // Sort courses and lessons by dueAt in descending order
      setCourses((courses) =>
        courses.sort((a, b) => new Date(b.dueAt) - new Date(a.dueAt))
      );
      setLessons((lessons) =>
        lessons.sort((a, b) => new Date(b.dueAt) - new Date(a.dueAt))
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      fetchUserAssignments();
    }
  }, [profileInfo]);

  if (loading) {
    return (
      <>
        <div className="px-3 position-relative page-header-divider">
          <SubHeading title="Assignments" headingStyle="m-0 p-0" />
        </div>
        <div className="px-5 mt-5">
          <CardSkeleton count={3} cols="row-cols-md-3" className="px-2" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="px-3 position-relative page-header-divider">
        <SubHeading title="Assignments" headingStyle="m-0 p-0" />
      </div>
      <div className="mt-3 px-3">
        <>
          <CategorySection
            slug={''}
            viewType={viewType}
            title={`Courses`}
            data={courses}
            loading={loading}
            commingSoonText={COURSES_COMMING_SOON}
            sectionType="course"
            fromAssignment={true}
            self={true}
            hasCataLogAccess={isModuleAllowed(
              tenant.modules,
              'learns_lesson_catalog'
            )}
            setRefresh={setRefresh}
          />
          <CategorySection
            slug={''}
            title={`Lessons`}
            viewType={viewType}
            data={lessons}
            loading={loading}
            commingSoonText={LESSONS_COMMING_SOON}
            sectionType="lesson"
            fromAssignment={true}
            self={true}
            hasCataLogAccess={isModuleAllowed(
              tenant.modules,
              'learns_lesson_catalog'
            )}
            setRefresh={setRefresh}
          />
        </>

        {!loading &&
        !userAssignments.length &&
        courses.length === 0 &&
        lessons.length === 0 ? (
          <NoDataFound
            title={'Your currently have no assignments'}
            description=""
            icon="manage_search"
            titleStyle="text-gray-search"
            containerStyle="text-gray-search my-6 py-6"
          />
        ) : !loading && courses.length === 0 && lessons.length === 0 ? (
          <NoDataFound
            title={'All assignments are completed'}
            description=""
            icon="done_all"
            titleStyle="text-gray-search"
            containerStyle="text-gray-search my-6 py-6"
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default MyAssignments;
