import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';

import { ADD_TO_LESSON, REMOVE_FROM_FAVORITES } from '../../utils/constants';
import LessonService from '../../services/lesson.service';
import TooltipComponent from './Tooltip';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import MaterialIcon from '../commons/MaterialIcon';
import { isModuleAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
import LessonLabel from '../commons/LessonLabel';

export default function LayoutCard(props) {
  const { item, lesson, setGetLessonId, disabled = false, setRefresh } = props;
  const { id } = item;

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [favorite, setFavorite] = useState(false);
  const [favoriteInProgress, setFavoriteInProgress] = useState(false);
  const { tenant } = useTenantContext();

  const inProgress = item?.progress > 0 || item?.progress[0]?.progress > 0;
  const isCompleted =
    item?.progress === 100 || item?.progress[0]?.progress === 100;

  const parsedTags = item?.tags?.length && JSON.parse(item.tags);

  const setFavoriteLessonAndCourse = (item) => {
    try {
      const { progress } = item;
      const { preferences } = item;
      setFavorite(preferences?.[0]?.isFavorite || progress?.[0]?.is_favorite);
    } catch (e) {
      console.log('parsing-error-preferences', e);
    }
  };

  useEffect(() => {
    const setTrainingInfo = async () => {
      setFavoriteLessonAndCourse(item);
    };

    setTrainingInfo();
  }, []);

  async function onHandleFavorite(e) {
    e.preventDefault();
    setFavoriteInProgress(true);

    try {
      const favorite = await LessonService.PutFavoriteByLessonId({ id });

      if (favorite) {
        setFavorite(favorite.isFavorite);

        const message = favorite.isFavorite
          ? `The lesson has been added to favorites`
          : `The lesson has been removed from favorites`;

        setSuccessMessage(message);
      }
    } catch (error) {
      console.error('Error handling favorite action:', error);
      setErrorMessage(`An error occurred while updating the lesson favorites`);
    } finally {
      setFavoriteInProgress(false);
      setRefresh((prevState) => prevState + 1);
    }
  }

  async function onDownload(e) {
    e.preventDefault();

    try {
      const file = await LessonService.PdfLinkByLesson(item.documents);

      if (!file) {
        setErrorMessage('File not found');
        return;
      }

      const data = new Blob([file], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(data);
      window.open(fileUrl);
    } catch (error) {
      setErrorMessage('File not found');
    }
  }

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <div
        className={`cursor-pointer lesson-item px-3 py-2 w-100 ${
          lesson?.id === item?.id ? 'bg-primary-soft' : ''
        } ${disabled && 'disabled-lesson'}`}
        onClick={() => !disabled && setGetLessonId(item?.id)}
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center gap-1">
            <div
              className={`${
                disabled ? 'cursor-pointer-important' : 'cursor-pointer'
              }`}
            >
              {isCompleted && (
                <TooltipComponent title="Completed">
                  <button
                    className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default"
                    onClick={(e) => onDownload(e)}
                  >
                    <MaterialIcon
                      filled
                      icon="circle"
                      clazz="text-success font-size-xl"
                    />
                  </button>
                </TooltipComponent>
              )}
              {!isCompleted && inProgress && (
                <TooltipComponent title="In Progress">
                  <button className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default">
                    <MaterialIcon
                      filled
                      icon="circle"
                      clazz="text-warning font-size-xl"
                    />
                  </button>
                </TooltipComponent>
              )}
              {!isCompleted && !inProgress && (
                <TooltipComponent title="Not Started">
                  <button className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default">
                    <MaterialIcon
                      filled
                      icon="circle"
                      clazz="text-muted-light font-size-xl"
                    />
                  </button>
                </TooltipComponent>
              )}
            </div>
            <div style={{ minWidth: '92px' }}>
              <LessonLabel labels={parsedTags} unknownLabel="Unknown" />
            </div>
            <p className="lesson-name">
              <TextOverflowTooltip
                textStyle="nav-link p-2"
                text={item.title || item.name}
                maxLength={50}
              />
            </p>
          </div>
          <div className="d-flex align-items-center gap-2 lesson-actions">
            {!disabled && item.documents && (
              <TooltipComponent title="Download lessons">
                <button
                  className="cursor-pointer btn btn-icon icon-ignore btn-icon-sm btn-sm rounded-circle"
                  onClick={(e) => onDownload(e)}
                >
                  <MaterialIcon icon="download_for_offline" />
                </button>
              </TooltipComponent>
            )}

            <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
              <MaterialIcon icon="schedule" />
              {`${item.duration || 0} min`}
            </div>

            <>
              {isModuleAllowed(tenant.modules, 'learns_lesson_catalog') ? (
                favoriteInProgress ? (
                  <Spinner style={{ width: 16, height: 16 }} />
                ) : (
                  <TooltipComponent
                    title={favorite ? REMOVE_FROM_FAVORITES : ADD_TO_LESSON}
                  >
                    <button
                      className={`btn btn-icon btn-icon-sm icon-ignore btn-sm rounded-circle ${
                        disabled ? 'cursor-pointer-important' : 'cursor-pointer'
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleFavorite(e);
                      }}
                    >
                      <MaterialIcon
                        filled={favorite}
                        icon="favorite"
                        clazz="text-black"
                      />
                    </button>
                  </TooltipComponent>
                )
              ) : null}
            </>
          </div>
        </div>
      </div>
    </>
  );
}
