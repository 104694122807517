import { CardBody, FormGroup, Label } from 'reactstrap';
import { Accordion } from 'react-bootstrap';
import React from 'react';

const DisclaimerAccordion = ({
  eventKey,
  reportModal,
  handleChangeInputDisclaimerHeading,
  handleChangeInputDisclaimer,
}) => {
  return (
    <Accordion.Collapse eventKey={eventKey}>
      <CardBody>
        <FormGroup>
          <Label for="title">Enter disclaimer heading</Label>
          <input
            name="heading"
            value={reportModal.disclaimerHeading}
            onChange={handleChangeInputDisclaimerHeading}
            className="form-control"
          />
        </FormGroup>
        <FormGroup>
          <Label for="title">Enter disclaimer text</Label>
          <textarea
            name="disclaimer"
            rows={6}
            value={reportModal.disclaimer}
            onChange={handleChangeInputDisclaimer}
            className="form-control"
          />
        </FormGroup>
      </CardBody>
    </Accordion.Collapse>
  );
};

export default DisclaimerAccordion;
