import React from 'react';
import ReportBlockWrapper from '../reports/ReportBlockWrapper';
import IconHeadingBlock from './blocks/IconHeadingBlock';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { ReportTypes } from '../reports/reports.constants';
import Accounts from '../reports/account-structure/pages/Accounts';
import { AccountSchematicReportSections } from '../reports/account-structure/account.structure.constants';
import BalanceTrends from '../reports/account-structure/pages/BalanceTrends';
import AccountsPayables from '../reports/account-structure/pages/AccountsPayables';
import AccountsReceivables from '../reports/account-structure/pages/AccountsReceivables';
// import SignersAndBankContacts from '../reports/account-structure/pages/SignersAndBankContacts';
import WorkingCapitalDPO from '../reports/account-structure/pages/WorkingCapitalDPO';
import WorkingCapitalDSO from '../reports/account-structure/pages/WorkingCapitalDSO';
import FundsFlow from '../reports/account-structure/pages/FundsFlow';
import AccountsWidget from '../reports/account-structure/widgets/AccountsWidget';
import SectionWrapper from '../reports/account-structure/SectionWrapper';
import WhiteLabelName from '../reports/account-structure/WhiteLabelName';
import BankContact from '../reports/account-structure/BankContact';
import DisclaimerWidget from './widgets/horizontal/DisclaimerWidget';

const Page = ({ report, pageNumber, heading, selectedTenant, children }) => {
  return (
    <ReportBlockWrapper
      showLogo={true}
      current={pageNumber}
      report={report.ReportInfo}
      reportType={ReportTypes.AccountStructure}
      selectedTenant={selectedTenant}
    >
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={heading}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-5 mb-0"
          pageNumber={pageNumber}
          report={report}
          reportType={ReportTypes.AccountStructure}
        />
      </div>
      <ListGroup
        className={`list-group-no-gutters my-0 list-group-flush ${
          ReportTypes.AccountStructure === ReportTypes.WorkingCapital
            ? 'working-capital-report'
            : ''
        }`}
      >
        <ListGroupItem className="border-0 px-5 position-relative pb-0">
          {children}
        </ListGroupItem>
      </ListGroup>
    </ReportBlockWrapper>
  );
};

const getDynamicAccountStructurePages = (reportWithAccountStructure) => {
  const accounts =
    reportWithAccountStructure.AccountStructureReport.ReportDetails?.accounts ||
    [];

  const calculateDynamicChunks = () => {
    const maxPageHeight = 795;
    const accountsWidgetHeight = 265;

    const chunks = [];
    let currentHeight = 0;
    let currentChunk = [];
    let isLastPageNeeded = false; // Track if accountsWidget needs a separate page

    // Dynamically calculate card height
    const calculateCardHeight = (account, index) => {
      let height = 16 + 50; // Card Padding + title div
      if (account.fraudServices) {
        height += 15; // fraudServices height
      }
      if (account.productServices) {
        const servicesCount = account.productServices.split(',').length;
        const lines = Math.ceil(servicesCount / 5); // Assume 5 services per line
        height += lines * 15; // Height per line
      }
      return height;
    };

    accounts.forEach((account, index) => {
      const cardHeight = calculateCardHeight(account, index);

      const adjustedCardHeight =
        currentChunk.length > 0 ? cardHeight + 15 : cardHeight;

      if (currentHeight + adjustedCardHeight > maxPageHeight) {
        // Start a new page if the current page is full
        chunks.push(currentChunk);
        currentChunk = [account];
        currentHeight = cardHeight;
      } else {
        currentChunk.push(account);
        currentHeight += adjustedCardHeight;
      }
    });

    // Push the remaining chunk, if any
    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }

    // Determine if the accountsWidget needs a separate page
    if (currentHeight + accountsWidgetHeight > maxPageHeight) {
      isLastPageNeeded = true;
    }

    return { chunks, isLastPageNeeded };
  };

  const { chunks, isLastPageNeeded } = calculateDynamicChunks();

  const pages = chunks.map((chunk, index) => ({
    id: `${AccountSchematicReportSections.AccountStructure}-${index + 1}`,
    heading: AccountSchematicReportSections.AccountStructure,
    isAvailable: true,
    component: (
      <Accounts
        report={reportWithAccountStructure}
        chunked={chunk}
        isShow={!isLastPageNeeded && index === chunks.length - 1}
      />
    ),
  }));

  if (isLastPageNeeded) {
    // Add the widget page directly if all page full with accounts card
    pages.push({
      id: `${AccountSchematicReportSections.AccountStructure}-widget`,
      heading: AccountSchematicReportSections.AccountStructure,
      isAvailable: true,
      component: <AccountsWidget whenPrinting={!!reportWithAccountStructure} />,
    });
  }
  return pages;
};

const getElementsWithHeights = (dataAttribute, dataArray) => {
  const rows = document.querySelectorAll(`[${dataAttribute}]`);
  return Array.from(rows)
    .map((row) => {
      const id = row.getAttribute(dataAttribute);
      const element = dataArray.find((item) => item.id === id);

      if (element) {
        const height = row.offsetHeight; // Get the height of the current row
        return { element, height };
      }
      return null;
    })
    .filter(Boolean); // Remove null entries
};

const calculatePages = (data, pageHeight = 780, spacings = 100) => {
  const pages = [];
  let currentPage = [];
  let currentHeight = 0;

  data?.forEach(({ element, height }) => {
    const totalPageHeight = spacings + currentHeight + height;

    // If the total height exceeds the page limit, push the current page and start a new one
    if (totalPageHeight > pageHeight) {
      pages.push(currentPage);
      currentPage = [];
      currentHeight = 0;
    }

    currentPage.push(element);
    currentHeight += height; // Update current height with row height
  });

  // Push any remaining rows to the last page
  if (currentPage.length > 0) {
    pages.push(currentPage);
  }

  return pages;
};

const calculateBankPages = (
  data,
  pageHeight = 780,
  spacings = 100,
  rowHeight = 84
) => {
  const pages = [];
  let currentPage = [];
  let currentHeight = 0;

  data.forEach((contact) => {
    const totalPageHeight = spacings + currentHeight + rowHeight;

    // If the total height exceeds the page height, push the current page and start a new one
    if (totalPageHeight > pageHeight) {
      pages.push(currentPage);
      currentPage = [];
      currentHeight = 0;
    }

    currentPage.push(contact);
    currentHeight += rowHeight; // Add row height to the current page height
  });

  // Push any remaining rows to the last page
  if (currentPage.length > 0) {
    pages.push(currentPage);
  }

  return pages;
};

const getDynamicSignersAndBankContacts = (reportWithAccountStructure) => {
  const accountStructure = reportWithAccountStructure?.AccountStructureReport;

  const { authorizedSigners, authorizedUsers, bankContacts } =
    accountStructure?.ReportDetails || {};

  let signersWithHeights = [];
  let usersWithHeights = [];

  if (document) {
    signersWithHeights = getElementsWithHeights(
      'data-signer-id',
      authorizedSigners
    );
    usersWithHeights = getElementsWithHeights('data-user-id', authorizedUsers);
  }

  // Calculate pages for signers and users
  const signersPages = calculatePages(signersWithHeights);
  const usersPages = calculatePages(usersWithHeights);
  const bankPages = calculateBankPages(bankContacts);

  const pages = [];

  // Add pages for signers
  if (signersWithHeights?.length) {
    signersPages.forEach((page, pageIndex) => {
      pages.push({
        id: `${AccountSchematicReportSections.SignersUsersContacts}_${pageIndex}`,
        heading: AccountSchematicReportSections.SignersUsersContacts,
        isAvailable: true,
        component: (
          <AuthorizedSignersWidgetWrapper
            page={page}
            columns={['Names', 'Account Numbers']}
            title="Authorized Signers"
            type="signers"
          />
        ),
      });
    });
  }

  // Add pages for users
  if (usersWithHeights?.length) {
    usersPages.forEach((page, pageIndex) => {
      pages.push({
        id: `${AccountSchematicReportSections.AuthorizedUsersContacts}_${pageIndex}`,
        heading: AccountSchematicReportSections.AuthorizedUsersContacts,
        isAvailable: true,
        component: (
          <AuthorizedSignersWidgetWrapper
            page={page}
            columns={['Name', 'Role']}
            title="Authorized Users"
            type="users"
          />
        ),
      });
    });
  }

  // Add pages for users
  if (bankContacts?.length) {
    bankPages.forEach((page, pageIndex) => {
      pages.push({
        id: `${AccountSchematicReportSections.BankContacts}_${pageIndex}`,
        heading: AccountSchematicReportSections.BankContacts,
        isAvailable: true,
        component: (
          <>
            <div className="px-2">
              <SectionWrapper
                columns={['Contact Name', 'Contact Details']}
                containerClasses="card card-body rounded-lg report-widget"
              >
                <div className="px-3">
                  {page?.map((acnt) => (
                    <BankContact key={acnt.id} acnt={acnt} />
                  ))}
                </div>
              </SectionWrapper>
            </div>
          </>
        ),
      });
    });
  }

  return pages;
};

const AuthorizedSignersWidgetWrapper = ({
  page,
  columns,
  type = 'signers',
}) => {
  return (
    <div className="px-2">
      <SectionWrapper
        columns={columns}
        containerClasses="card card-body rounded-lg report-widget"
      >
        {page.map((element) => (
          <Row
            key={element.id}
            className="fs-9 bg-hover-gray mx-0 py-2 px-0 border-bottom border-white"
          >
            {type === 'signers' ? (
              <>
                <Col md={6} className="pl-2 align-self-center">
                  <div className="d-flex flex-wrap gap-1">
                    {element.names?.map((nme) => (
                      <WhiteLabelName key={nme.id}>{nme.value}</WhiteLabelName>
                    ))}
                  </div>
                </Col>
                <Col md={6} className="align-self-center">
                  <div className="d-flex flex-wrap gap-1">
                    <AccountNumbers accounts={element.accountNumber} />
                  </div>
                </Col>
              </>
            ) : type === 'users' ? (
              <>
                <Col md={6} className="pl-2 align-self-center">
                  <WhiteLabelName wrap={true}>{element.name}</WhiteLabelName>
                </Col>
                <Col md={6} className="align-self-center">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{element.role?.name}</span>
                  </div>
                </Col>
              </>
            ) : (
              ''
            )}
          </Row>
        ))}
      </SectionWrapper>
    </div>
  );
};

const AccountNumbers = ({ accounts }) => {
  const splitted = accounts.length > 0 ? accounts.split(', ') : [];
  return (
    <>
      {splitted.length > 0 ? (
        <>
          {splitted.map((nme) => (
            <WhiteLabelName key={nme}>{nme}</WhiteLabelName>
          ))}
        </>
      ) : null}
    </>
  );
};

// this wrap each section into pdf page
const ReportPagesAccountStructure = ({ report, selectedTenant }) => {
  const reportWithAccountStructure = { AccountStructureReport: report };
  const isWorkingCapitalActive = report?.WorkingCapital?.isActive;
  const isAccountReceivablesActive =
    report?.Widgets[AccountSchematicReportSections.AccountsReceivable]
      ?.isActive;
  const isAccountReceivablesActiveAutomating =
    report?.Widgets[AccountSchematicReportSections.AccountsReceivableAutomating]
      ?.isActive;
  const isDisclaimerActive =
    report?.Widgets[AccountSchematicReportSections.Disclaimer]?.isActive;

  // pages without Working Capital pages
  const accountStructureReportPages = [
    {
      id: AccountSchematicReportSections.FundsFlow,
      heading: AccountSchematicReportSections.FundsFlow,
      isAvailable: true,
      component: <FundsFlow report={reportWithAccountStructure} />,
    },
    ...getDynamicAccountStructurePages(reportWithAccountStructure),
    {
      id: AccountSchematicReportSections.BalanceTrends,
      heading: AccountSchematicReportSections.BalanceTrends,
      isAvailable: true,
      component: (
        <BalanceTrends report={reportWithAccountStructure} hideHeading={true} />
      ),
    },
    {
      id: AccountSchematicReportSections.AccountsPayable,
      heading: AccountSchematicReportSections.AccountsPayable,
      isAvailable: true,
      component: <AccountsPayables report={reportWithAccountStructure} />,
    },
    {
      id: AccountSchematicReportSections.AccountsReceivable,
      heading: AccountSchematicReportSections.AccountsReceivable,
      isAvailable:
        isAccountReceivablesActive || isAccountReceivablesActiveAutomating,
      component: <AccountsReceivables report={reportWithAccountStructure} />,
    },
    ...getDynamicSignersAndBankContacts(reportWithAccountStructure),
    {
      id: AccountSchematicReportSections.Disclaimer,
      heading: AccountSchematicReportSections.Disclaimer,
      isAvailable: isDisclaimerActive,
      component: <DisclaimerWidget whenPrinting={true} />,
    },
  ];

  // adding Working Capital specific pages if active
  if (isWorkingCapitalActive) {
    accountStructureReportPages.splice(
      3,
      0, // add after BalanceTrends widget
      {
        id: AccountSchematicReportSections.WCDPO,
        heading: AccountSchematicReportSections.WCDPO,
        isAvailable: true,
        component: <WorkingCapitalDPO report={reportWithAccountStructure} />,
      },
      {
        id: AccountSchematicReportSections.WCDSO,
        heading: AccountSchematicReportSections.WCDSO,
        isAvailable: true,
        component: <WorkingCapitalDSO report={reportWithAccountStructure} />,
      }
    );
  }

  return (
    <>
      {accountStructureReportPages
        .filter((page) => page.isAvailable)
        .map((page, index) => (
          <Page
            key={page.id}
            report={report}
            heading={page.heading}
            selectedTenant={selectedTenant}
            pageNumber={index + 2}
          >
            {page.component}
          </Page>
        ))}
    </>
  );
};

export default ReportPagesAccountStructure;
