import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { isPermissionAllowed } from '../utils/Utils';
import IdfTooltip from './idfComponents/idfTooltip';
import MaterialIcon from './commons/MaterialIcon';
import { Spinner } from 'reactstrap';

const MoreActions = ({
  icon = 'more_horiz',
  items,
  onHandleOpen,
  onHandleNameEdit,
  onHandleRemove,
  onHandleDownload,
  onHandleEdit,
  onHandleReinvite,
  onHandleAdd,
  onHandleEnable,
  onHandleDisable,
  toggleClassName = 'add-more-btn icon-hover-bg',
  menuClassName,
  iconStyle,
  permission = {},
  loader,
  customClass,
  menuWidth = 160,
  menuPlacement = 'down',
  show,
  onToggle,
  ...restProps
}) => {
  const onClickFire = {
    remove: onHandleRemove,
    edit: onHandleEdit,
    editName: onHandleNameEdit,
    reInvite: onHandleReinvite,
    add: onHandleAdd,
    download: onHandleDownload,
    open: onHandleOpen,
    enable: onHandleEnable,
    disable: onHandleDisable,
  };

  return (
    <>
      <Dropdown
        drop={menuPlacement}
        show={show}
        onToggle={onToggle}
        style={{ opacity: 1 }}
        className={`${customClass} idf-dropdown-item-list`}
      >
        <Dropdown.Toggle
          className={`${
            toggleClassName === '' || toggleClassName?.includes('w-auto')
              ? 'add-more-btn icon-hover-bg'
              : toggleClassName
          } rounded-circle dropdown-hide-arrow`}
          variant="outline-link"
          id="dropdown"
          {...restProps}
        >
          <span className="d-flex align-items-center justify-content-center">
            {loader ? (
              <Spinner className="spinner-grow-xs" />
            ) : (
              <MaterialIcon icon={icon} clazz={`${iconStyle} fs-5`} />
            )}
          </span>
        </Dropdown.Toggle>

        {items.length > 0 && (
          <Dropdown.Menu
            align="right"
            className={`border border-1 py-1 ${menuClassName}`}
            style={{ width: menuWidth }}
          >
            {items?.map((data) => (
              <>
                {data?.type === 'divider' ? (
                  <div
                    style={{ height: 1, width: '100' }}
                    className="border-top mt-1 pb-1"
                  />
                ) : (
                  <>
                    {data?.permission?.collection ? (
                      isPermissionAllowed(
                        data?.permission?.collection,
                        data?.permission?.action
                      ) && (
                        <Dropdown.Item
                          key={data.id}
                          className={`pl-2 text-black d-flex align-items-center ${
                            data.className
                          } ${
                            data.id === 'delete' || data.id === 'remove'
                              ? data.className || 'bg-hover-danger'
                              : ''
                          }`}
                          onClick={onClickFire[data.id]}
                        >
                          {data.icon && (
                            <MaterialIcon
                              icon={data.icon}
                              rounded
                              clazz={`dropdown-item-icon ${data.className}`}
                            />
                          )}
                          <span
                            className={data.textStyles}
                            style={{
                              whiteSpace: 'nowrap',
                              background: 'transparent',
                            }}
                          >
                            {data.name}
                          </span>
                        </Dropdown.Item>
                      )
                    ) : (
                      <>
                        {data?.disabled ? (
                          <IdfTooltip text={data.disabled} placement="bottom">
                            <Dropdown.Item
                              key={data.id}
                              className={`pl-2 d-flex align-items-center text-black ${
                                data.className
                              } ${
                                data.id === 'delete' || data.id === 'remove'
                                  ? data.className || 'bg-hover-danger'
                                  : ''
                              }`}
                            >
                              <i
                                className={`material-symbols-rounded fs-5 dropdown-item-icon ${data.className}`}
                              >
                                {data.icon}
                              </i>
                              {data.name}
                            </Dropdown.Item>
                          </IdfTooltip>
                        ) : (
                          <Dropdown.Item
                            key={data.id}
                            className={`pl-2 text-black d-flex align-items-center ${
                              data.className
                            } ${
                              data.id === 'delete' || data.id === 'remove'
                                ? data.className || 'bg-hover-danger'
                                : ''
                            }`}
                            onClick={onClickFire[data.id]}
                          >
                            {data.icon && (
                              <MaterialIcon
                                icon={data.icon}
                                rounded
                                clazz={`dropdown-item-icon ${data.className}`}
                              />
                            )}
                            <span
                              className={data.textStyles}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              {data.name}
                            </span>
                          </Dropdown.Item>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

MoreActions.defaultProps = {
  toggleClassName: '',
};

export default MoreActions;
