import { Card, CardBody, ListGroupItem } from 'reactstrap';
import React from 'react';
import { DisclaimerData } from '../../constants/widgetsConstants';

const DisclaimerWidget = ({
  widgetConfig,
  whenPrinting,
  headingStyles = 'hide-heading',
}) => {
  const { disclaimer, disclaimerHeading } = widgetConfig?.data || {};
  const content = disclaimer;
  const heading = disclaimerHeading || DisclaimerData.disclaimerHeading;

  return (
    <>
      {!whenPrinting ? (
        <ListGroupItem className={`m-0 p-0 border-0`}>
          <Card className="report-widget border mb-0">
            <CardBody>
              <h5 className={`text-left ${headingStyles} d-flex mb-1`}>
                {heading}
              </h5>
              <div className="d-flex gap-1 px-2">
                <p className="mb-0 text-left text-black font-size-sm2">
                  {content}
                </p>
              </div>
            </CardBody>
          </Card>
        </ListGroupItem>
      ) : (
        <ListGroupItem className="border-0 m-0 p-0">
          <Card className="report-widget mb-0">
            <CardBody className="p-3">
              <p className="mb-0 text-left text-black font-size-sm2">
                {content}
              </p>
            </CardBody>
          </Card>
        </ListGroupItem>
      )}
    </>
  );
};

export default DisclaimerWidget;
