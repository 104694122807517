import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import React, { useEffect, useState } from 'react';
import {
  ActionTypes,
  CommercialCardReportSections,
  EngagementReportConfig,
  ReportTypes,
} from '../../reports/reports.constants';
import useWidgetsLibrary from '../../reportbuilder/widgets/useWidgetsLibrary';
import {
  AcceptanceConditionsData,
  DisclaimerData,
  DisclaimerDataFFB,
  FPData,
  IVREPHData,
  PaymentStatsData,
  PaymentTrendsData,
  VendorAnalysisPhasesData,
  VendorsCountData,
  WidgetTypes,
  YourBenefitsData,
} from '../../reportbuilder/constants/widgetsConstants';
import { ListGroup } from 'reactstrap';
import WidgetWithActionButtons from '../../reportbuilder/widgets/WidgetWithActionButtons';
import NoDataFound from '../../commons/NoDataFound';
import {
  NO_REPORTS_AVAILABLE,
  NO_REPORTS_AVAILABLE_ICON,
} from '../../../utils/constants';
import ReportAction from '../../reports/ReportAction';
import useFetchEngagementReports from '../../../hooks/reports/useFetchEngagementReports';
import { useProfileContext } from '../../../contexts/profileContext';
import {
  getCycleDate,
  getReportName,
  updateJsonObject,
} from '../../reports/reports.helper.functions';
import _ from 'lodash';
import ReportDropdownItem from '../../reports/ReportDropdownItem';
import moment from 'moment/moment';
import {
  getNAICSWithIndustry,
  getNaicsWithTitle,
  overflowing,
} from '../../../utils/Utils';
import ReportService from '../../../services/report.service';
import ReportDragDrop from '../../reports/ReportDragDrop';
import ReportBlocksSkeleton from '../../loaders/ReportBlocksSkeleton';
import ReportPDFWrapper from '../../reportbuilder/ReportPDFWrapper';
import ReportCover from '../../reports/ReportCover';
import ReportPages from '../../reportbuilder/ReportPages';
import useIsTenant from '../../../hooks/useIsTenant';
import useGenerateCommercialCardModel from '../../../hooks/reports/modals/useGenerateCommercialCardModel';
import useHash from '../../../hooks/useHash';

const DOWNLOAD_OPTIONS = [
  {
    id: 1,
    icon: 'picture_as_pdf',
    key: 'downloadAsPdf',
    name: 'PDF Download',
  },
];

const PageConfig =
  EngagementReportConfig[ReportTypes.CommercialCard].PageConfig;

const CommercialCardReportSectionKeys =
  EngagementReportConfig[ReportTypes.CommercialCard].SectionKeys;

const CommercialCardReport = ({
  organization,
  readOnly,
  selectedTenant,
  currentTab,
  getProfileInfo,
}) => {
  const { hash, updateHash } = useHash();
  const { profileInfo } = useProfileContext();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const defaultPreventionProducts = updateJsonObject({
    'Positive Pay': 0,
    'ACH Positive Pay': 0,
  });
  const [report, setReport] = useState({
    fraudPreventionProducts: defaultPreventionProducts,
  });
  const {
    reports,
    setReports,
    loading: loadingPastReports,
    fetchReports,
  } = useFetchEngagementReports(organization, ReportTypes.CommercialCard);
  const [selectedRpt, setSelectedRpt] = useState({});
  const [rptGenerated, setRptGenerated] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const { isExcelBank, isFFB } = useIsTenant();
  const excelBankMode = isExcelBank;
  const disclaimerData = isFFB ? DisclaimerDataFFB : DisclaimerData;

  const defaultDisclaimerWidget = {
    [CommercialCardReportSections.Disclaimer]: [
      {
        id: 1,
        widgetConfig: { data: disclaimerData },
        type: WidgetTypes.DISC,
      },
    ],
  };

  const getDefaultWidgets = () => {
    return {
      [CommercialCardReportSections.Highlights]: [
        {
          id: 1,
          widgetConfig: VendorsCountData,
          type: WidgetTypes.VCW,
        },
        {
          id: 2,
          widgetConfig: PaymentStatsData,
          type: WidgetTypes.PSW,
        },
        {
          id: 3,
          widgetConfig: YourBenefitsData,
          type: WidgetTypes.YBW,
        },
      ],
      [CommercialCardReportSections.VendorAnalysisDetails]: [
        {
          id: 1,
          widgetConfig: VendorAnalysisPhasesData,
          type: WidgetTypes.VAP,
        },
        {
          id: 2,
          widgetConfig: AcceptanceConditionsData,
          type: WidgetTypes.AC,
        },
      ],
      [CommercialCardReportSections.Insights]: [
        {
          id: 1,
          widgetConfig: PaymentTrendsData,
          type: WidgetTypes.PT,
        },
        {
          id: 2,
          widgetConfig: IVREPHData,
          type: WidgetTypes.IVREPH,
        },
      ],
      [CommercialCardReportSections.MovingForwardTogether]: [
        {
          id: 1,
          widgetConfig: FPData,
          type: WidgetTypes.FP,
        },
      ],
      [CommercialCardReportSections.Disclaimer]: [
        {
          id: 1,
          widgetConfig: { data: disclaimerData },
          type: WidgetTypes.DISC,
        },
      ],
    };
  };

  const [widgets, setWidgets] = useState(getDefaultWidgets());
  const [reportPages, setReportPages] = useState(getDefaultReportPages());
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const widgetsSections = {
    [CommercialCardReportSections.Highlights]: [],
    [CommercialCardReportSections.VendorAnalysisDetails]: [],
    [CommercialCardReportSections.Insights]: [],
    [CommercialCardReportSections.MovingForwardTogether]: [],
    [CommercialCardReportSections.Disclaimer]: [],
  };
  const [, setEditWidgets] = useState(widgetsSections);
  const [, setIsEdited] = useState(false);
  const [, setOpenGenerateReport] = useState(false);

  const getUpdatedWidgetConfig = (wg) => {
    const updatedWidgetConfig = {
      ...wg.widgetConfig,
      items: wg.widgetConfig?.items,
    };
    return updatedWidgetConfig;
  };

  const updateDefaultWidgetsWithMapping = (
    defaultOrSavedWidgets,
    reportObjectWithMapping
  ) => {
    let updatedWidgets = {};
    delete reportObjectWithMapping.widgets;
    delete reportObjectWithMapping.reportPages;
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => {
        const updatedConfig = getUpdatedWidgetConfig(wg);
        return {
          ...wg,
          widgetConfig: Array.isArray(wg.widgetConfig)
            ? wg.widgetConfig
            : {
                ...wg.widgetConfig,
                data: reportObjectWithMapping,
                items: updatedConfig.items,
              },
        };
      });
    }
    // add default disclaimer widget
    if (
      !Object.hasOwn(
        defaultOrSavedWidgets,
        CommercialCardReportSections.Disclaimer
      )
    ) {
      updatedWidgets = {
        ...updatedWidgets,
        ...defaultDisclaimerWidget,
      };
    }
    return {
      ...updatedWidgets,
    };
  };

  const handleGenerateReport = (
    newReport,
    action,
    newOrUpdatedReport,
    newWidgets,
    newReportPages
  ) => {
    if (action === ActionTypes.REMOVE) {
      const newReports = [
        ...reports.filter((rpt) => rpt.reportId !== newReport.reportId),
      ];
      const reportsAvailable = newReports.length > 0;
      setReports(reportsAvailable ? newReports : []);
      setSelectedRpt(reportsAvailable ? newReports.at(-1) : {});
      const defaultWidgets = getDefaultWidgets({});
      const defaultReportPages = getDefaultReportPages();
      setWidgets(defaultWidgets);
      setReportPages(defaultReportPages);
      setModalWidgets(defaultWidgets);
      setModalReportPages(defaultReportPages);
      setRptGenerated(false);
      setOpenGenerateReport(false);
      setSuccessMessage('Report Deleted');
    } else {
      setReportPages(newReportPages);
      setModalReportPages(newReportPages);
      setRptGenerated(true);
      if (newReport) {
        try {
          if ('key' in selectedRpt) {
            const newReports = [
              ...reports.map((rpt) =>
                rpt.key === selectedRpt.key
                  ? {
                      ...rpt,
                      customElement: (
                        <ReportDropdownItem
                          item={{
                            ...newOrUpdatedReport.manualInput,
                            key: rpt.key,
                            reportId: rpt.key,
                          }}
                        />
                      ),
                      reportPages: newReportPages,
                      name: getReportName(newOrUpdatedReport.manualInput),
                      createdById: profileInfo.id,
                      updatedAt: new Date().toISOString(),
                      prettyDate: moment(
                        newOrUpdatedReport.manualInput?.reportDate
                      ).format('MMMM YYYY'),
                      widgets: updateDefaultWidgetsWithMapping(
                        newWidgets,
                        newOrUpdatedReport.manualInput
                      ),
                    }
                  : rpt
              ),
            ];
            setReports(newReports);
            const reportMapping = {
              ...report,
              ...newOrUpdatedReport.manualInput,
            };
            setReport(reportMapping);
            const selected = newReports.find((r) => r.key === selectedRpt.key);
            setSelectedRpt(selected);
            setSelectedReport({
              ...newOrUpdatedReport.manualInput,
              reportDate: getCycleDate(
                newOrUpdatedReport.manualInput.reportDate
              ),
            });
            const updatedWidgets = updateDefaultWidgetsWithMapping(
              selected.widgets,
              reportMapping
            );
            setWidgets(updatedWidgets);
            setModalWidgets(updatedWidgets);
            setSuccessMessage('Report Saved');
          } else {
            const rptObject = newOrUpdatedReport;
            const pastReportObject = {
              key: newOrUpdatedReport.reportId,
              reportId: newOrUpdatedReport.reportId,
              reportPages: newReportPages,
              customElement: (
                <ReportDropdownItem
                  item={{
                    ...rptObject.manualInput,
                    key: newOrUpdatedReport.reportId,
                    reportId: newOrUpdatedReport.reportId,
                  }}
                />
              ),
              name: getReportName(rptObject.manualInput),
              isManual: true,
              createdById: profileInfo.id,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              prettyDate: moment(rptObject.manualInput.reportDate).format(
                'MMMM YYYY'
              ),
            };
            const newReports = [pastReportObject].concat([...reports]);
            setReports(newReports);
            setSelectedRpt(pastReportObject);
            setSelectedReport({
              ...newOrUpdatedReport.manualInput,
              reportDate: getCycleDate(
                newOrUpdatedReport.manualInput.reportDate
              ),
            });
            const updatedWigets = updateDefaultWidgetsWithMapping(
              pastReportObject.widgets,
              newReport
            );
            setWidgets(updatedWigets);
            setModalWidgets(updatedWigets);
            setSuccessMessage('Report Created');
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoadingReport(false);
          setOpenGenerateReport(false);
        }
      }
    }
  };

  const {
    setShowModal,
    setSelectedReport,
    setModalReportPages,
    setModalWidgets,
    GenerateCommercialCardReportModal,
  } = useGenerateCommercialCardModel(
    report,
    organization,
    reportPages,
    widgets,
    handleGenerateReport,
    getProfileInfo
  );

  const handleCreateButtonReport = () => {
    setSelectedRpt({});
    setIsEdited(false);
    setRptGenerated(false);
    const defaultWidgets = getDefaultWidgets({});
    const defaultReportPages = getDefaultReportPages();
    setWidgets(defaultWidgets);
    setReportPages(defaultReportPages);
    setModalWidgets(defaultWidgets);
    setModalReportPages(defaultReportPages);
  };

  const handleEditReport = () => {
    setIsEdited(true);
    setOpenGenerateReport(true);
    setShowModal(true);
    updateHash('');
  };

  const onDeleteWidget = async (oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    setEditWidgets(newWidgets); // this needs to be added in order to open updated in modal
    await ReportService.updateReport(report.reportId, {
      name: report.value1,
      date: report.value2,
      manualInput: {
        ...report,
        widgets: newWidgets,
      },
    });
    setSuccessMessage('Report Saved');
  };

  const onAddWidget = async (e, newWidget, oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    setEditWidgets(newWidgets); // this needs to be added in order to open updated in modal
    await ReportService.updateReport(report.reportId, {
      name: report.value1,
      date: report.value2,
      manualInput: {
        ...report,
        widgets: newWidgets,
      },
    });
    setShowWidgetsLibrary(false);
    overflowing();
    setSuccessMessage('Report Saved');
  };

  const getReportById = async (selectedReport) => {
    setLoadingReport(true);
    setRptGenerated(true);
    try {
      const defaultReportPages = getDefaultReportPages();
      const data = await ReportService.getReport(selectedReport.key);
      const { manualInput } = data;
      const rptObject = manualInput;
      const fraudPreventionProducts =
        rptObject?.fraudPreventionProducts ||
        updateJsonObject({
          'Positive Pay': rptObject.positivePay ? 1 : 0,
          'ACH Positive Pay': rptObject.achPositivePay ? 1 : 0,
        });
      if (Object.hasOwn(rptObject, 'widgets')) {
        const savedWidgets = updateDefaultWidgetsWithMapping(
          rptObject.widgets,
          { ...rptObject, fraudPreventionProducts }
        );
        setWidgets(savedWidgets);
        setModalWidgets(savedWidgets);
      } else {
        const defWidgets = getDefaultWidgets(selectedReport);
        setWidgets(defWidgets);
        setModalWidgets(defWidgets);
      }
      // if we have .reportPages saved in report then load those otherwise load default ones to cater old reports too
      if (Object.hasOwn(rptObject, 'reportPages')) {
        setReportPages(rptObject.reportPages);
        setModalReportPages(rptObject.reportPages);
      } else {
        setReportPages(defaultReportPages);
        setModalReportPages(defaultReportPages);
      }

      const newReport = {
        ...rptObject,
        fraudPreventionProducts,
        id: selectedReport.key,
        reportId: selectedReport.key,
        valueN: getNaicsWithTitle(rptObject, organization),
        reportDate: getCycleDate(rptObject.reportDate),
        disclaimer: rptObject.disclaimer || disclaimerData.disclaimer,
        disclaimerHeading:
          rptObject.disclaimerHeading || disclaimerData.disclaimerHeading,
      };
      setSelectedReport(newReport);
      setReport(newReport);
      if (hash?.includes('/edit')) {
        handleEditReport();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
    }
  };

  const handleManualReport = () => {
    setSelectedRpt({});
    setIsEdited(false);
    setRptGenerated(false);
    const defaultWidgets = getDefaultWidgets({});
    const defaultReportPages = getDefaultReportPages();
    setWidgets(defaultWidgets);
    setReportPages(defaultReportPages);
    setModalWidgets(defaultWidgets);
    setModalReportPages(defaultReportPages);
    const newReport = {
      companyName: organization.name,
      reportDate: getCycleDate(new Date().toISOString()),
      fraudPreventionProducts: updateJsonObject({
        'Positive Pay': 0,
        'ACH Positive Pay': 0,
      }),
      disclaimer: disclaimerData.disclaimer,
      disclaimerHeading: disclaimerData.disclaimerHeading,
      ...getNAICSWithIndustry(organization),
    };
    setReport(newReport);
    setSelectedReport(newReport);
    setShowModal(true);
  };

  useEffect(() => {
    if (organization?.id && organization?.name) {
      fetchReports();
    }
  }, [organization]);

  useEffect(() => {
    if (selectedRpt?.key || hash?.includes('/id')) {
      getReportById(selectedRpt);
    }
  }, [selectedRpt?.key]);

  useEffect(() => {
    if (hash?.includes('/new')) {
      handleCreateButtonReport();
    }
  }, [hash]);

  useEffect(() => {
    if (reports.length) {
      const firstReport = hash?.includes('/new') ? {} : reports[0] || {};
      // if we have the reportId then get it
      if (firstReport?.key || hash?.includes('/id')) {
        const urlHashId = hash?.split('/')?.at(-1); // get the last index which has report id
        const reportFound = reports.find((rp) => rp.reportId === urlHashId);
        if (reportFound) {
          setSelectedRpt(reportFound);
          setWidgets(reportFound?.widgets);
        } else {
          setSelectedRpt(firstReport);
          setWidgets(firstReport?.widgets);
        }
      } else {
        setSelectedRpt({});
        setRptGenerated(false);
      }
    } else {
      setSelectedRpt({});
      setRptGenerated(false);
    }
  }, [reports]);

  return (
    <>
      <WidgetsLibraryModal />
      <GenerateCommercialCardReportModal />
      <AlertWrapper className="alert-position">
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
          time={8000}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
          time={8000}
        />
      </AlertWrapper>
      {reports.length === 0 &&
        readOnly &&
        !loadingReport &&
        !loadingPastReports && (
          <NoDataFound
            icon={NO_REPORTS_AVAILABLE_ICON}
            iconRounded={true}
            containerStyle="my-6 py-6"
            title={NO_REPORTS_AVAILABLE}
          />
        )}
      <ReportAction
        report={report}
        readOnly={readOnly}
        reportType={ReportTypes.CommercialCard}
        pastReports={reports}
        organization={organization}
        selectedRpt={selectedRpt}
        profileInfo={profileInfo}
        rptGenerated={rptGenerated}
        loadingReport={loadingReport}
        startDownload={startDownload}
        setSelectedRpt={(newReport) => {
          updateHash('');
          setSelectedRpt(newReport);
        }}
        downloadOptions={DOWNLOAD_OPTIONS}
        setStartDownload={setStartDownload}
        handleEditReport={handleEditReport}
        handleManualReport={handleCreateButtonReport}
        loadingPastReports={loadingPastReports}
      />

      <div className="text-center">
        {!rptGenerated &&
          !loadingReport &&
          !readOnly &&
          !loadingPastReports && (
            <ReportDragDrop
              file={null}
              setFile={() => {}}
              loader={false}
              onRemoveFile={() => {}}
              onLoadFile={() => {}}
              handleGenerate={handleManualReport}
              uploadIcon="edit_document"
              fileUpload="Enter data to generate Commercial Card report."
            />
          )}

        {loadingReport || loadingPastReports ? (
          <ReportBlocksSkeleton />
        ) : (
          <>
            {rptGenerated && (
              <>
                {startDownload && (
                  <ReportPDFWrapper>
                    <ReportCover
                      name={report.companyName}
                      date={report.reportDate}
                      excelBankMode={excelBankMode}
                      selectedTenant={selectedTenant}
                      report={report}
                      type={ReportTypes.CommercialCard}
                      organization={organization}
                    />
                    <ReportPages
                      pages={widgets}
                      report={report}
                      reportPages={reportPages}
                      sectionKeys={CommercialCardReportSectionKeys}
                      pageConfig={PageConfig}
                      showIcon={false}
                      selectedTenant={selectedTenant}
                      reportType={ReportTypes.CommercialCard}
                    />
                  </ReportPDFWrapper>
                )}
                <div
                  className={`pb-3 ${readOnly ? 'client-dashboard-rpt' : ''}`}
                >
                  <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
                    {CommercialCardReportSectionKeys.flatMap((key) => {
                      const widgetsForCurrentKey = widgets[key] || [];
                      const pageEnabled = reportPages[key]?.enabled;
                      const widgetsWithoutBlank = widgetsForCurrentKey
                        ?.filter((widget) => widget.type !== WidgetTypes.BLANK)
                        .map((wg) => ({
                          ...wg,
                          id: crypto.randomUUID(),
                          section: key,
                        }));
                      if (widgetsWithoutBlank.length && pageEnabled) {
                        return widgetsWithoutBlank?.map((widget) => (
                          <WidgetWithActionButtons
                            key={widget.id}
                            widget={widget}
                            section={key}
                            onAddWidget={onAddWidget}
                            onDeleteWidget={onDeleteWidget}
                            selectedTenant={selectedTenant}
                            setSelectedWidget={setSelectedWidget}
                            setShowWidgetsLibrary={setShowWidgetsLibrary}
                            actionButtons={{}}
                          />
                        ));
                      }
                      return null;
                    })}
                  </ListGroup>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CommercialCardReport;
