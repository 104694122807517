import React from 'react';
import { AvatarLogo } from '../commons/AvatarLogo';

const CategoryPartnerLogo = ({
  categoryInfo,
  imageStyle = 'ml-2',
  width = '90px',
  minWidth,
  height = 'auto',
  avatarKey = 'logo',
  icon,
}) => {
  const avatarId = categoryInfo?.[avatarKey] || categoryInfo?.logo;

  return (
    <AvatarLogo
      name={categoryInfo?.name}
      avatarId={avatarId}
      imageStyle={imageStyle}
      width={width}
      minWidth={minWidth}
      height={height}
      icon={icon}
    />
  );
};

export default CategoryPartnerLogo;
