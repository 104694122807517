/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Progress,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import routes from '../../utils/routes.json';

import {
  COMPLETED,
  ADD_TO_LESSON,
  REMOVE_FROM_FAVORITES,
  IN_PROGRESS,
} from '../../utils/constants';
import LessonService from '../../services/lesson.service';
import TooltipComponent from './Tooltip';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import courseService from '../../services/course.service';
import MaterialIcon from '../commons/MaterialIcon';
import TopicIcon from '../commons/TopicIcon';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import Skeleton from 'react-loading-skeleton';
import LessonLabel from '../commons/LessonLabel';
import ButtonIcon from '../commons/ButtonIcon';
import moment from 'moment/moment';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';

export default function LessonCard(props) {
  const {
    icon,
    item,
    sectionType,
    setItem,
    self,
    topics,
    myOrganization,
    progressDone,
    layoutCourse,
    category,
    handleRetakeCourse,
    learnViewTypes,
    selected,
    classnames,
    setRefresh,
  } = props;
  const params = useUrlSearchParams();
  const catTitle = params?.get('title');
  const type = params?.get('viewType');
  const urlPath = params?.get('path');
  const { id } = item;
  const track =
    item.progress && item.progress.length > 0
      ? item.progress[0] || false
      : false;
  const parsedTags = item?.tags?.length && JSON.parse(item.tags);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [favorite, setFavorite] = useState(false);
  const [favoriteInProgress, setFavoriteInProgress] = useState(false);
  const [totalLessons, setTotalLessons] = useState(item?.totalLessons || 0);
  const [totalLessonsLoading, setTotalLessonsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(
    track && track.status === COMPLETED
  );
  const [progress, setProgress] = useState(
    track.status === IN_PROGRESS ? track.progress : 0
  );

  const isCourse = sectionType === 'course';
  const category_id = isCourse
    ? item?.categoryCourses?.length
      ? item?.categoryCourses[0]?.categoryId
      : null
    : item.category_id;
  const categoryInfo =
    topics?.find((cat) => cat.id === category_id) || item.category;

  const categoryTitle = myOrganization
    ? 'My Organization'
    : item?.category?.title || catTitle;

  const path = !isCourse
    ? `${routes.learnMain}${buildQueryString({
        categoryId: category_id,
        path: urlPath,
        viewType: learnViewTypes || type,
        customViewType: 'lesson',
        customViewId: item.id,
        catTitle: categoryTitle,
      })}`
    : `${routes.learnMain}${buildQueryString({
        categoryId: category_id,
        path: urlPath,
        viewType: learnViewTypes || type,
        customViewType: 'course',
        customViewId: item.id,
        catTitle: categoryTitle,
      })}`;

  // Helper function to build query string
  function buildQueryString(params) {
    const queryParams = Object.entries(params)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ''
      )
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&');

    return queryParams.length > 0 ? `?${queryParams}` : '';
  }

  const link = track && track.page_id ? `${path}` : path;

  const setFavoriteLessonAndCourse = (item) => {
    try {
      const { progress } = item;
      const { preferences } = item;
      setFavorite(preferences?.[0]?.isFavorite || progress?.[0]?.is_favorite);
    } catch (e) {
      console.log('parsing-error-preferences', e);
    }
  };

  useEffect(() => {
    const setTrainingInfo = async () => {
      setFavoriteLessonAndCourse(item);
      if (sectionType !== 'course-footer' && !isCourse) {
        if (!props.fromAssignment) {
          try {
            const resp = await LessonService.GetLessonTrackByLessonId(id, {
              self,
            });
            if (resp) {
              const { status, progress } = resp;
              setProgress(progress || 0);
              if (progress === 100 && status === 'completed') {
                setIsCompleted(true);
              }
            }
          } catch (error) {
            console.error('Error fetching lesson track details:', error);
          }
        }
      } else {
        if (!props.fromAssignment) {
          try {
            const { progress = 0 } = await courseService.getCourseProgress(id, {
              self: true,
            });

            setProgress(progress || 0);
            if (progress) {
              setIsCompleted(progress === 100);
            }
          } catch (error) {
            console.error('Error fetching course progress:', error);
          }
        }
        if (!Object.hasOwn(item, 'totalLessons')) {
          try {
            setTotalLessonsLoading(true);
            const data = await courseService.getCourseLessonsById(id);
            setTotalLessons(data?.lessons?.length || 0);
          } catch (error) {
            console.error('Error fetching course lessons:', error);
          } finally {
            setTotalLessonsLoading(false);
          }
        }
      }
    };

    setTrainingInfo();
  }, []);

  async function onHandleFavorite(e) {
    e.preventDefault();
    setFavoriteInProgress(true);

    const isCourseView = isCourse || sectionType === 'course-footer';

    try {
      let favorite = '';

      if (isCourseView) {
        favorite = await courseService.putFavoriteCourseById(id);
      } else {
        favorite = await LessonService.PutFavoriteByLessonId({ id });
      }

      if (setItem) {
        setItem(item);
      } else {
        if (favorite) {
          setFavorite((prevState) => !prevState);

          const message = favorite.isFavorite
            ? `The ${
                isCourseView ? 'course' : 'lesson'
              } has been added to favorites`
            : `The ${
                isCourseView ? 'course' : 'lesson'
              } has been removed from favorites`;

          setSuccessMessage(message);
        }
      }
    } catch (error) {
      console.error('Error handling favorite action:', error);
      setErrorMessage(
        `An error occurred while updating the ${
          isCourse ? 'course' : 'lesson'
        } favorites`
      );
    } finally {
      setFavoriteInProgress(false);
      setRefresh((prevState) => prevState + 1);
    }
  }

  async function onDownload(e) {
    e.preventDefault();

    try {
      const file = await LessonService.PdfLinkByLesson(item.documents);

      if (!file) {
        setErrorMessage('File not found');
        return;
      }

      const data = new Blob([file], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(data);
      window.open(fileUrl);
    } catch (error) {
      setErrorMessage('File not found');
    }
  }

  const isDueDatePassed = (dueAt) => {
    const dueDate = new Date(dueAt);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return dueDate < currentDate;
  };

  const CourseCard = () => {
    const inProgress =
      Number.isFinite(progress) && progress > 0 && !isCompleted;

    return (
      <div className="position-relative course-card-parent">
        <Card className={`setting-item course-card lesson-card rounded-0`}>
          <CardBody className="p-2_1 px-3 position-relative">
            <div className="d-flex flex-column h-100">
              <div className="d-flex flex-column">
                <TextOverflowTooltip
                  textStyle="mt-3 mb-0 fs-6 font-weight-medium course-title"
                  text={item.title || item.name}
                  lineClamp={2}
                />
                <TextOverflowTooltip
                  textStyle="mt-1 mb-0 text-muted"
                  text={item.description}
                  lineClamp={1}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-auto mb-2">
                <TopicIcon
                  filled={false}
                  icon={categoryInfo?.icon || icon || 'category'}
                  iconBg=""
                  iconClasses="font-size-3em text-primary"
                />
                <a
                  onClick={() =>
                    isCompleted && handleRetakeCourse && handleRetakeCourse()
                  }
                  className="fs-7 font-weight-semi-bold text-primary hoverLink-"
                >
                  {isCompleted
                    ? 'Retake Course'
                    : inProgress
                    ? 'Continue Course'
                    : 'Start Course'}
                </a>
              </div>
            </div>
          </CardBody>
          <CardFooter className="p-2_1 px-3 bg-white">
            <Row className="justify-content-between align-items-center">
              <Col className="col-auto d-flex justify-content-between flex-row align-items-center w-100">
                <div className="d-flex justify-content-start gap-2">
                  {item.duration > 0 ? (
                    <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                      <MaterialIcon icon="schedule" />
                      {`${item.duration || 0} min`}
                    </div>
                  ) : null}
                  <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                    {totalLessonsLoading ? (
                      <Skeleton height="10" width="50" />
                    ) : (
                      totalLessons > 0 && (
                        <>
                          <MaterialIcon icon="list_alt" />
                          {`${totalLessons} ${
                            totalLessons === 1 ? 'Lesson' : 'Lessons'
                          }`}
                        </>
                      )
                    )}
                  </div>

                  {props.hasCataLogAccess ? (
                    !favoriteInProgress ? (
                      <TooltipComponent
                        title={favorite ? REMOVE_FROM_FAVORITES : ADD_TO_LESSON}
                      >
                        <button
                          className="btn btn-icon btn-icon-sm icon-ignore btn-sm rounded-circle cursor-pointer"
                          onClick={(e) => onHandleFavorite(e)}
                        >
                          <MaterialIcon
                            filled={favorite}
                            icon="favorite"
                            clazz="text-black"
                          />
                        </button>
                      </TooltipComponent>
                    ) : (
                      <Spinner style={{ width: 16, height: 16 }} />
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="d-flex justify-content-end gap-2">
                  {item.documents && (
                    <TooltipComponent title="Download lessons">
                      <button
                        className="cursor-pointer btn btn-icon icon-ignore btn-icon-sm btn-sm rounded-circle"
                        onClick={(e) => onDownload(e)}
                      >
                        <MaterialIcon icon="download_for_offline" />
                      </button>
                    </TooltipComponent>
                  )}
                  {isCompleted && (
                    <TooltipComponent title="Completed">
                      <button
                        className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default"
                        onClick={(e) => onDownload(e)}
                      >
                        <MaterialIcon
                          filled
                          icon="circle"
                          clazz="text-success font-size-xl"
                        />
                      </button>
                    </TooltipComponent>
                  )}
                  {!isCompleted && inProgress && (
                    <TooltipComponent title="In Progress">
                      <button className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default">
                        <MaterialIcon
                          filled
                          icon="circle"
                          clazz="text-warning font-size-xl"
                        />
                      </button>
                    </TooltipComponent>
                  )}
                  {!isCompleted && !inProgress && (
                    <TooltipComponent title="Not Started">
                      <button className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default">
                        <MaterialIcon
                          filled
                          icon="circle"
                          clazz="text-muted-light font-size-xl"
                        />
                      </button>
                    </TooltipComponent>
                  )}
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
    );
  };

  const LessonCard = () => {
    const inProgress =
      Number.isFinite(progress) && progress > 0 && !isCompleted;
    return (
      <Link to={link}>
        <Card className={`setting-item lesson-card rounded-0 ${selected}`}>
          <CardBody className="p-2_1 px-3">
            <div className="d-flex flex-column h-100">
              <div className="d-flex">
                <TextOverflowTooltip
                  textStyle="mt-3 mb-0 fs-6 font-weight-medium course-title"
                  text={item.title || item.name}
                  lineClamp={2}
                />
              </div>

              <div className="d-flex align-items-center justify-content-between mt-auto mb-2">
                <LessonLabel labels={parsedTags} />
                <a className="fs-7 font-weight-semi-bold text-primary hoverLink-">
                  {isCompleted
                    ? 'Retake Lesson'
                    : inProgress
                    ? 'Continue Lesson'
                    : 'Start Lesson'}
                </a>
              </div>
            </div>
          </CardBody>
          <CardFooter className="p-2_1 px-3 bg-white">
            <Row className="justify-content-between align-items-center">
              <Col className="col-auto d-flex justify-content-between flex-row align-items-center w-100">
                <div className="d-flex justify-content-start gap-2">
                  {item.duration > 0 ? (
                    <div className="d-flex gap-1 align-items-center text-muted font-size-sm2">
                      <MaterialIcon icon="schedule" />
                      {`${item.duration || 0} min`}
                    </div>
                  ) : null}
                  {props.hasCataLogAccess ? (
                    !favoriteInProgress ? (
                      <TooltipComponent
                        title={favorite ? REMOVE_FROM_FAVORITES : ADD_TO_LESSON}
                      >
                        <button
                          className="btn btn-icon btn-icon-sm icon-ignore btn-sm rounded-circle cursor-pointer"
                          onClick={(e) => onHandleFavorite(e)}
                        >
                          <MaterialIcon
                            filled={favorite}
                            icon="favorite"
                            clazz="text-black"
                          />
                        </button>
                      </TooltipComponent>
                    ) : (
                      <Spinner style={{ width: 16, height: 16 }} />
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="d-flex justify-content-end gap-2">
                  {item.documents && (
                    <TooltipComponent title="Download lessons">
                      <button
                        className="cursor-pointer btn btn-icon icon-ignore btn-icon-sm btn-sm rounded-circle"
                        onClick={(e) => onDownload(e)}
                      >
                        <MaterialIcon icon="download_for_offline" />
                      </button>
                    </TooltipComponent>
                  )}
                  {isCompleted && (
                    <TooltipComponent title="Completed">
                      <button
                        className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default"
                        onClick={(e) => onDownload(e)}
                      >
                        <MaterialIcon
                          filled
                          icon="circle"
                          clazz="text-success font-size-xl"
                        />
                      </button>
                    </TooltipComponent>
                  )}
                  {!isCompleted && inProgress && (
                    <TooltipComponent title="In Progress">
                      <button className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default">
                        <MaterialIcon
                          filled
                          icon="circle"
                          clazz="text-warning font-size-xl"
                        />
                      </button>
                    </TooltipComponent>
                  )}
                  {!isCompleted && !inProgress && (
                    <TooltipComponent title="Not Started">
                      <button className="btn btn-icon btn-icon-sm icon-ignore no-hover btn-sm rounded-circle cursor-default">
                        <MaterialIcon
                          filled
                          icon="circle"
                          clazz="text-muted-light font-size-xl"
                        />
                      </button>
                    </TooltipComponent>
                  )}
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Link>
    );
  };

  return (
    <div className="position-relative">
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>

      {layoutCourse ? (
        <CourseCard />
      ) : isCourse ? (
        <>
          <Link to={link}>
            <CourseCard />
          </Link>
        </>
      ) : (
        <LessonCard />
      )}
    </div>
  );
}
