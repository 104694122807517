import ButtonIcon from '../commons/ButtonIcon';
import ButtonIconDropdownWrapper from '../commons/ButtonIconDropdownWrapper';
import React from 'react';
import { getReportPDFName } from './reports.helper.functions';
import { PDF_PAGE_HEIGHT, ReportTypes } from './reports.constants';
const DownloadReportDropdown = ({
  report,
  selectedRpt,
  startDownload,
  setStartDownload,
  downloadOptions,
  exportToCSV,
  csvData,
  linkConfig = {},
  reportType,
}) => {
  return (
    <ButtonIconDropdownWrapper
      options={downloadOptions}
      styleClasses="border-0 no-hover"
      handleOptionSelect={(itemClicked) => {
        const getHeightFromWidth = (width) => {
          const aspectRatio = 8.5 / 11; // Letter paper size aspect ratio
          const height = width / aspectRatio;
          return Math.round(height);
        };
        if (itemClicked.key === 'downloadAsPdf') {
          setStartDownload(true);
          setTimeout(() => {
            const $canvas = document.getElementById('rptPdf');
            const configWidth = $canvas?.getBoundingClientRect();
            const HTML_Width = configWidth?.width;
            window.scrollTo(0, 0);
            const calculatedPageHeight = getHeightFromWidth(HTML_Width);
            const pageHeight =
              calculatedPageHeight < PDF_PAGE_HEIGHT
                ? PDF_PAGE_HEIGHT
                : calculatedPageHeight;

            // eslint-disable-next-line new-cap
            const pdf = new window.jspdf.jsPDF('p', 'pt', [
              HTML_Width,
              pageHeight,
            ]);

            const canvases = [
              ...document.querySelectorAll('#rptPdf > div.px-0'),
            ].map((el) => {
              return window.html2canvas(el, {
                allowTaint: true,
                scale: (window.devicePixelRatio || 1) * 3,
                useCORS: true,
                removeContainer: true,
                imageTimeout: 15000,
              });
            });
            const linkConfigs = linkConfig;
            const links =
              Object.entries(linkConfigs).length === 1 ? null : linkConfigs;
            const Pages = {};
            if (links?.length) {
              for (let k = 0; k < links.length; k++) {
                Pages[links[k].page] = links[k];
              }
            }
            Promise.all(canvases).then((resps) => {
              resps.forEach((canvas, index) => {
                const imgData = canvas.toDataURL('image/jpeg', 0.8);

                const newLinkPage = links?.length
                  ? Pages[index] || {}
                  : linkConfig;
                // experiment for one qr code default widget, currently doing for working capital
                // will have to think to make it a dynamic solution
                // for now hard-coding working capital widget area so that it would be clickable
                // and only the second page of WC report we have QRCode widget
                if (newLinkPage?.url && index === newLinkPage?.page) {
                  // Add a rectangle to represent an area on the map and clickable
                  const { areaX, areaY, areaWidth, areaHeight } =
                    newLinkPage.area;

                  pdf.link(areaX, areaY, areaWidth, areaHeight, {
                    url: newLinkPage.url,
                    target: '_blank',
                  });
                }
                pdf.addPage();
                pdf.addImage(
                  imgData,
                  'JPEG',
                  0,
                  0,
                  HTML_Width,
                  PDF_PAGE_HEIGHT
                );
              });
              pdf.deletePage(1);
              pdf.save(
                `${getReportPDFName(
                  reportType,
                  reportType === ReportTypes.AccountStructure
                    ? selectedRpt?.ReportInfo
                    : report
                )}.pdf`
              );
              setStartDownload(false);
            });
          }, 100);
        } else {
          exportToCSV(csvData);
        }
      }}
    >
      <ButtonIcon
        icon="file_download"
        color="white"
        loading={startDownload}
        classnames="btn-md rounded"
        label=""
      />
    </ButtonIconDropdownWrapper>
  );
};
export default DownloadReportDropdown;
