import React, { useEffect, useReducer, useState } from 'react';
import { PersonalityTypes, SelfAssessmentTypes } from './assessmentConstants';
import { useHistory } from 'react-router-dom';
import AssessmentQuestions from './assessmentQuestions.json';
import AssessmentScoring from './assessmentScoring.json';
import SelfAssessmentService from '../../../services/selfAssessment.service';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import routes from '../../../utils/routes.json';
import SelfAssessmentWrapper from './SelfAssessmentWrapper';
import SelfAssessmentCard from './SelfAssessmentCard';
import useUrlSearchParams from '../../../hooks/useUrlSearchParams';
import SubHeading from '../../../components/subheading';
import { ProgressBar } from 'react-bootstrap';
import MaterialIcon from '../../../components/commons/MaterialIcon';

const PageHeading = 'Communication Self-Assessment';

export default function SelfAssessment() {
  const [defaultAssessment, setDefaultAssessment] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [assessmentResults, setAssessmentResults] = useState({});
  const params = useUrlSearchParams();
  const startAssessment = params.get('start') || false;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [assessment, updateAssessment] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      start: false,
      completed: false,
      showResults: false,
      finalResult: {},
      progress: 0,
    }
  );
  const history = useHistory();
  const isPublic = !history.location.pathname.includes('learn');
  const isFullPage = history.location.pathname.includes(routes.selfAssessment);
  const isAdaptionInstitute = history.location.pathname.includes('adaption');
  const isPublicAndFullPage = !isFullPage && !isPublic;

  const createDefaultAssessment = async () => {
    setLoader(true);
    try {
      const newAssessment = {
        selfAssessmentType: SelfAssessmentTypes.Both,
        questions: [],
      };

      for (let i = 0; i < AssessmentQuestions.fields.length; i++) {
        const currentQuestion = AssessmentQuestions.fields[i];
        const scoringLegend = AssessmentScoring[i + 1];
        const newQuestion = {
          type: 'multipleChoice',
          order: i + 1,
          title: currentQuestion.title.replaceAll('*', ''),
        };
        newQuestion.choices = currentQuestion.properties.choices.map(
          (choice, index) => {
            return {
              id: choice.id,
              answer: choice.label,
              correct: false,
              personality: PersonalityTypes[scoringLegend[index]],
            };
          }
        );
        newAssessment.questions.push(newQuestion);
      }

      const { data } = await SelfAssessmentService.createDefaultQuestions(
        newAssessment
      );
      setDefaultAssessment(data);
    } catch (e) {
      console.log(e);
      setErrorMessage('Error in getting questions.');
    } finally {
      setLoader(false);
    }
  };

  const getAssessments = async () => {
    setLoader(true);
    try {
      const { data } = await SelfAssessmentService.getAssessments();
      if (data?.length > 0) {
        setDefaultAssessment(data[0]);
      } else {
        createDefaultAssessment();
      }
    } catch (e) {
      console.log(e);
      setErrorMessage('Error in getting assessment.');
    } finally {
      setLoader(false);
    }
  };

  const getAssessmentQuestions = async () => {
    const data = await SelfAssessmentService.getAssessmentQuestions(
      defaultAssessment.selfAssessmentId
    );
    setAssessmentQuestions(data || []);
    if (startAssessment) {
      updateAssessment({ start: true });
    }
  };

  useEffect(() => {
    getAssessments();
  }, []);

  useEffect(() => {
    if (defaultAssessment?.selfAssessmentId) {
      getAssessmentQuestions();
    }
  }, [defaultAssessment]);

  const retakeAssessment = () => {
    updateAssessment({
      completed: false,
      start: true,
      finalResult: {},
      progress: 0,
      showResults: false,
    });
    setAssessmentQuestions(assessmentQuestions);
  };

  const resetForm = () => {
    updateAssessment({
      completed: false,
      start: false,
      finalResult: {},
      progress: 0,
      showResults: false,
    });
    setAssessmentQuestions(assessmentQuestions);
  };

  const submitQuestionnaire = async (submissionRequest) => {
    try {
      setLoader(true);
      const data = await SelfAssessmentService.submitAssessment(
        { ...submissionRequest, source: isAdaptionInstitute ? 'adaption' : '' },
        defaultAssessment.selfAssessmentId
      );
      setAssessmentResults(data);
    } catch (e) {
      console.log(e);
      setErrorMessage('Error in submitting questionnaire.');
    } finally {
      setLoader(false);
    }
  };

  const redirectToResults = () => {
    if (isPublic) {
      if (isAdaptionInstitute) {
        history.push(
          `${routes.selfAssessmentAdaption}/${assessmentResults.selfAssessmentSubmissionId}/results`
        );
      } else {
        history.push(
          `${routes.selfAssessmentPublic}/${assessmentResults.selfAssessmentSubmissionId}/results`
        );
      }
    } else {
      history.push(
        `${routes.selfAssessment}/${assessmentResults.selfAssessmentSubmissionId}/results`
      );
    }
  };

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <div className={`px-3 position-relative page-header-divider`}>
        <div className="d-flex align-items-center gap-1">
          {isPublicAndFullPage && assessment.start && !assessment.completed && (
            <a
              className="cursor-pointer icon-hover-bg assess-back-arrow"
              onClick={resetForm}
            >
              <MaterialIcon icon="keyboard_backspace" />{' '}
            </a>
          )}
          <div
            style={{
              paddingLeft:
                isPublicAndFullPage && assessment.start && !assessment.completed
                  ? 25
                  : 0,
            }}
          >
            <SubHeading
              title={`${
                assessmentQuestions?.length &&
                assessment.start &&
                !assessment.completed
                  ? `${PageHeading}: Question ${currentQuestion + 1}/${
                      assessmentQuestions?.length
                    }`
                  : PageHeading
              }`}
              headingStyle={`m-0 p-0`}
            />
          </div>
        </div>
        {!assessment.showResults &&
          !assessment.completed &&
          assessment.start &&
          isPublicAndFullPage && (
            <ProgressBar
              now={assessment.progress}
              className={`w-100 position-absolute flat-progress rounded-0 bottom-0 left-0 ${
                isAdaptionInstitute ? 'ai' : ''
              }`}
            />
          )}
      </div>
      <div className="px-3">
        <SelfAssessmentWrapper isAdaptionInstitute={isAdaptionInstitute}>
          <SelfAssessmentCard
            loader={loader}
            isPublic={isPublic}
            assessment={assessment}
            retakeAssessment={retakeAssessment}
            updateAssessment={updateAssessment}
            redirectToResults={redirectToResults}
            submitQuestionnaire={submitQuestionnaire}
            assessmentQuestions={assessmentQuestions}
            isAdaptionInstitute={isAdaptionInstitute}
            isFullPage={isFullPage}
            setCurrentQuestion={setCurrentQuestion}
          />
        </SelfAssessmentWrapper>
      </div>
    </>
  );
}
