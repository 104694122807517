import React, { useRef } from 'react';
import { useTenantContext } from '../../contexts/TenantContext';
import { Col, FormCheck, Row } from 'react-bootstrap';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';

export const PermissionToggles = ({
  permissionSwitches,
  permissions,
  moduleNoteCheck = () => {},
  ownerAccessSwitch,
  tenantAccessSwitch,
  permissionLevel,
  handlePermissionCategoryChange = () => {},
  handleSwitchEvent = () => {},
  appAccessSwitch,
  permissionsDropdown,
  handleDropdownPermission = () => {},
}) => {
  const { tenant } = useTenantContext();
  const menuRef = useRef();
  useOutsideClickDropDown(
    menuRef,
    permissionsDropdown,
    handleDropdownPermission
  );

  return (
    <>
      {permissions.length > 0 &&
        permissions.map((permission, i) => (
          <div className={`${i !== 0 ? 'mt-3' : ''}`} key={`permissions${i}`}>
            <h4 className="mb-0 pt-3 pb-2 px-20 doubleBorderB">
              {permission.name}
            </h4>
            {permission.inner_permissions.length > 0 &&
              permission.inner_permissions.map((permissionsCategory) => {
                if (
                  moduleNoteCheck(
                    tenant?.modules,
                    permissionsCategory.tenantModule
                  )
                ) {
                  return (
                    <>
                      <Row
                        className="switch-setting-main align-items-center mx-0 pl-0 border-bottom-light py-2 border-light-color"
                        key={permissionsCategory.name}
                      >
                        <Col md={5} className="pl-20">
                          <h5 className="m-0 font-weight-medium">
                            {permissionsCategory.label}
                          </h5>
                        </Col>

                        <Col md={7}>
                          <div
                            className={
                              permissionsCategory.label === 'Insights'
                                ? 'd-flex align-items-center pointer-event'
                                : 'd-flex align-items-center hover-link cursor-pointer'
                            }
                          >
                            <FormCheck
                              id={permissionsCategory.label}
                              type="switch"
                              custom={true}
                              name={permissionsCategory.label}
                              checked={permissionsCategory.isChecked}
                              onChange={() =>
                                handlePermissionCategoryChange(
                                  permission,
                                  permissionsCategory
                                )
                              }
                            />
                            <div>
                              {permissionsCategory.group.length > 0 &&
                                permissionsCategory.isChecked && (
                                  <div
                                    className="switch-setting-1"
                                    onClick={() =>
                                      handleDropdownPermission(
                                        permissionsCategory.name
                                      )
                                    }
                                  >
                                    {permissionsCategory.isChecked &&
                                      permissionsCategory.group.map(
                                        (permissionGroup, index) => {
                                          permissionSwitches?.find((item) => {
                                            return permissionsCategory.name ===
                                              item.collection &&
                                              item.action ===
                                                permissionGroup.name
                                              ? (permissionGroup.isChecked = true)
                                              : (permissionGroup.isChecked = false);
                                          });
                                          return (
                                            <>
                                              {permissionGroup.isChecked && (
                                                <div className="abc">
                                                  <span className="text-capitalize">
                                                    {permissionGroup.label}
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                )}
                              {permissionsCategory.group.length > 0 &&
                                permissionsCategory.name ===
                                  permissionsDropdown && (
                                  <div
                                    ref={menuRef}
                                    className="switch-setting border rounded p-0 shadow-lg"
                                  >
                                    {permissionsCategory.group.map(
                                      (permissionGroup) => {
                                        return (
                                          <>
                                            <label
                                              className="d-flex align-items-center mb-0 p-2 border-bottom-light cursor-pointer bg-hover-gray text-capitalize"
                                              htmlFor={permissionGroup.name}
                                            >
                                              <input
                                                id={permissionGroup.name}
                                                type="checkbox"
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                                checked={permissionSwitches?.find(
                                                  (item) => {
                                                    return permissionsCategory.name ===
                                                      item.collection &&
                                                      item.action ===
                                                        permissionGroup.name
                                                      ? (permissionGroup.isChecked = true)
                                                      : (permissionGroup.isChecked = false);
                                                  }
                                                )}
                                                onChange={(e) => {
                                                  e.stopPropagation();
                                                  handleSwitchEvent(
                                                    e,
                                                    permissionGroup,
                                                    permissionsCategory
                                                  );
                                                }}
                                              />
                                              <span>
                                                {permissionGroup.label}
                                              </span>
                                            </label>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                } else {
                  return '';
                }
              })}
          </div>
        ))}
    </>
  );
};
