import { Skeleton } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import SubHeading from '../subheading';
import { removeAndReplaceParams } from '../../views/Resources/category/constants/Category.constants';

const LessonSkeleton = ({ categoryTitle }) => {
  const location = useLocation();
  const history = useHistory();
  const returnUrl = removeAndReplaceParams(
    location,
    ['customViewType', 'customViewId'],
    {
      categoryId: 'id',
      catTitle: 'title',
    }
  );

  return (
    <>
      <div className="px-3 position-relative page-header-divider">
        <SubHeading
          headingStyle="m-0 p-0"
          title={categoryTitle || ''}
          showArrow
          onClick={() => history.push(returnUrl)}
        />
      </div>
      <Row className="d-flex justify-content-center mx-8- mx-auto mb-2">
        <Col md={8} className="mt-4">
          <div className="mt-3 position-relative">
            <Skeleton variant="rounded" height={400} width={'100%'} />
          </div>
          <div className="mt-3 d-flex flex-column gap-1">
            <Skeleton variant="rounded" height={30} width={'100%'} />
            <Skeleton variant="rounded" height={30} width={'100%'} />
            <Skeleton variant="rounded" height={30} width={'100%'} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LessonSkeleton;
