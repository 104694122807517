import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { capitalizeEachWord } from '../../utils/Utils';

const TooltipComponent = ({
  title = '',
  placement = 'bottom',
  children,
  tooltipTextFont,
  titleLeftAlign,
  image = '',
  capitalizeText = true,
  list = '',
}) => {
  return (
    <>
      {title || list ? (
        <OverlayTrigger
          placement={placement}
          overlay={
            <Tooltip
              className={`font-weight-normal ${tooltipTextFont ? '' : ''} ${
                titleLeftAlign ? 'tooltip-left-align' : 'tooltip-left-align'
              }`}
            >
              {image ? <div className="mb-2">{image}</div> : ''}
              {capitalizeText ? capitalizeEachWord(title) : title}
              {list ? (
                <div className="d-flex flex-column">
                  {list.map((item, i) => {
                    return <span key={i}>{item}</span>;
                  })}
                </div>
              ) : (
                ''
              )}
            </Tooltip>
          }
        >
          {children}
        </OverlayTrigger>
      ) : (
        children
      )}
    </>
  );
};

export default TooltipComponent;
