export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'add':
      return [...state, payload];
    case 'set':
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const ProspectTypes = {
  people: 'people',
  company: 'company',
  domain: 'domain',
};

export const initialState = {
  global: {
    name: [],
  },
  location: { location: [], city: [], radius: '', geo: [] },
  city: '',
  occupation: {
    current_title: '',
    normalized_title: [],
    department: [],
    skills: [],
    years_experience: [],
  },
  employer: {
    company_domain: '',
    company_size: [],
    company_revenue: [],
    sic_codes: '',
    naics_codes: '',
    company_industry: [],
  },
  contact: { contact_method: [] },
  contactSelection: { selection_type: [] },
  education: { major: '', degree: [], school: '' },
  web: { domain: '', contact_info: '', link: '' },
  other: { keyword: '', exclude: [] },
};

export const initialStateCompany = {
  global: {
    name: [],
  },
  location: { location: [], city: [], radius: '', geo: [] },
  city: '',
  employer: {
    employees: [],
  },
  industry: { industry: [], sic_codes: '', naics_codes: '' },
  domain: { domain: '' },
  revenue: { revenue: '' },
  technology: { techstack: [] },
  growth: {
    growth: [],
    time_period: '',
    department: '',
  },
  news: { news_timestamp: [] },
  newsTimeRange: { news_time_range: 'one_week' },
};
export const filters = [
  'General',
  'Occupation',
  'Employer',
  'Contact',
  'Education',
  'Web',
  'Other',
];

export const orgFilters = [
  'General',
  'Industry',
  'Metrics',
  'Competitors',
  'News',
];

export const employeeCountListNew = [
  { key: '1 ⇾ 10', value: '1-10' },
  { key: '11 ⇾ 50', value: '11-50' },
  { key: '51 ⇾ 100', value: '51-100' },
  { key: '101 ⇾ 500', value: '101-500' },
  { key: '501 ⇾ 1,000', value: '501-1000' },
  { key: '1,001 ⇾ 5,000', value: '1001-5000' },
  { key: '5,001 ⇾ 10,000', value: '5001-10000' },
  { key: '10,001 ⇾ 25,000', value: '10001-25000' },
  { key: '25,001 +', value: '25001<' },
];

export const revenueListNew = [
  { key: '< $10M', value: '<10000000' },
  { key: '$10M ⇾ $50M', value: '10000000-50000000' },
  { key: '$50M ⇾ $100M', value: '50000000-100000000' },
  { key: '$100M ⇾ $500M', value: '100000000-500000000' },
  { key: '$500M ⇾ $1B', value: '500000000-1000000000' },
  { key: '$1B ⇾ $5B', value: '1000000000-5000000000' },
  { key: '$5B +', value: '5000000000<' },
];

export const companyGrowthList = [
  { key: '-100% ⇾ -50%', value: '~100-~50' },
  { key: '-50% ⇾ 0%', value: '~50-0' },
  { key: '0% ⇾ 25%', value: '0-25' },
  { key: '25% ⇾ 50%', value: '25-50' },
  { key: '75% ⇾ 100%', value: '75-100' },
  { key: '100% +', value: '100<' },
];

export const degreeList = [
  'Bachelors',
  'Masters',
  'Associates',
  'Doctorates',
  'High School',
];

export const normalizedTitle = [
  '1st Grade Teacher',
  '1st Pressman',
  '1st Pressman On Web Press',
  '21 Dealer',
  '2nd Grade Teacher',
  '2nd Pressman',
  '2 Year Olds Preschool Teacher',
  '3D Animator',
  '3D Artist',
  '3D Designer',
  '3D Modeler',
  '3D Specialist',
  '3rd Grade Reading Teacher',
  '3rd Grade Teacher',
  '3rd Pressman',
  '4-H Youth Development Specialist',
  '4th Grade Math Teacher',
  '4th Grade Teacher',
  '4 Year Olds Kindergarten Teacher',
  '5th Grade Teacher',
  '6th Grade Teacher',
  '7th Grade Teacher',
  '8th Grade Mathematics Teacher',
  '8th Grade Teacher',
  '911 Dispatcher',
  '911 Operator (Nine One One Operator)',
  'Able Bodied Seaman (AB Seaman)',
  'Able Seaman',
  'Abrasives Sales Representative',
  'Academic Advisor',
  'Academic Affairs Dean',
  'Academic Affairs Director',
  'Academic Affairs Vice President',
  'Academic Coordinator',
  'Academic Dean',
  'Academic Director',
  'Academic Support Center Director',
  'Academic Tutor',
  'Accelerator Operator',
  'Access Control Specialist',
  'Access Coordinator',
  'Accessibility Lift Technician',
  'Accessioner',
  'Access Services Assistant',
  'Accident Investigator',
  'Accompanist',
  'Account Adjuster',
  'Account Administrator',
  'Account Analyst',
  'Accountant',
  'Accountant Assistant',
  'Accountant Manager',
  'Account Auditor',
  'Account Clerk',
  'Account Development Manager',
  'Account Director',
  'Account Executive',
  'Account Executive, Key Accounts',
  'Accounting Administrator',
  'Accounting Analyst',
  'Accounting Assistant',
  'Accounting Associate',
  'Accounting Bookkeeper',
  'Accounting Clerk',
  'Accounting Consultant',
  'Accounting Coordinator',
  'Accounting Director',
  'Accounting/Finance Tutor',
  'Accounting Instructor',
  'Accounting Manager',
  'Accounting Officer',
  'Accounting Representative',
  'Accounting Specialist',
  'Accounting Supervisor',
  'Accounting Systems Analyst',
  'Accounting Technician',
  'Account Leader',
  'Account Manager',
  'Account Receivable Associate',
  'Account Representative',
  'Account Service Representative',
  'Accounts Executive',
  'Accounts Manager',
  'Accounts Payable Administrator',
  'Accounts Payable Associate',
  'Accounts Payable Clerk',
  'Accounts Payable Coordinator',
  'Accounts Payable Manager',
  'Accounts Payables Clerk',
  'Accounts Payable Specialist',
  'Accounts Payable Supervisor',
  'Account Specialist',
  'Accounts Receivable Assistant',
  'Accounts Receivable Clerk',
  'Accounts Receivable Manager',
  'Accounts Receivable Specialist',
  'Accounts Receivable Supervisor',
  'Accounts Supervisor',
  'Account Supervisor',
  'Account Underwriter',
  'Accredited Farm Manager (AFM)',
  'Accredited Pharmacy Technician',
  'Accuracy Expert',
  'AC/DC Rewinder',
  'Acetone Recovery Worker',
  'Acid Dipper',
  'Acid Tester',
  'A Class Lineman',
  'Acme - Gridley Tool Setter',
  'Acoustical Engineer',
  'Acquisition Analyst',
  'Acquisitions Assistant',
  'Acquisitions Librarian',
  'Acrobat',
  'Acting Section Chief',
  'Acting Teacher',
  'Activist',
  'Activities Assistant',
  'Activities Concierge',
  'Activities Coordinator',
  'Activities Director',
  'Activities Leader',
  'Activity Coordinator',
  'Activity Director',
  'Activity Therapist',
  'Activity Therapy Specialist',
  'Actor',
  'Actress',
  'Actuarial Analyst',
  'Actuarial Manager',
  'Actuarial Science Professor',
  'Actuary',
  'Acupressurist',
  'Acupuncturist',
  'Acute Care Clinical Nurse Specialist',
  'Acute Care Nurse',
  'Acute Care Nurse Practitioner',
  'Adapted Physical Education or Adapted Physical Activity Specialist',
  'Adapted Physical Education Specialist',
  'Adapted Physical Education Specialist-Workshop Leader',
  'Ad Compositor',
  'Addictions Counselor',
  'Addiction Therapist',
  'Adjunct Business Instructor',
  'Adjunct Communications Faculty Member',
  'Adjunct Faculty',
  'Adjunct Faculty for Medical Terminology',
  'Adjunct Faculty Mathematics Department',
  'Adjunct Instructor',
  'Adjunct Instructor in Economics',
  "Adjunct Instructor of Women's Studies",
  'Adjunct Latin Professor',
  'Adjunct Physical Education Instructor',
  'Adjunct Professor',
  'Adjunct Professor of U.S. History',
  'Adjunct Psychology Faculty Member',
  'Adjunct Writing Instructor',
  'Adjuster',
  'Adjustment Examiner',
  'Adjutant General',
  'Admeasurer',
  'Admin Assistant',
  'Administration Assistant',
  'Administration Dean',
  'Administration Vice President',
  'Administrative Aide',
  'Administrative Analyst',
  'Administrative and Technical Specialist',
  'Administrative Assistant',
  'Administrative Associate',
  'Administrative Clerk',
  'Administrative Coordinator',
  'Administrative Director',
  'Administrative Director, Behavioral Health Services',
  'Administrative Law Judge',
  'Administrative Manager',
  'Administrative Office Assistant',
  'Administrative Officer',
  'Administrative Operations Officer',
  'Administrative Secretary',
  'Administrative Services Assistant',
  'Administrative Services Director',
  'Administrative Services Manager',
  'Administrative Specialist',
  'Administrative Supervisor',
  'Administrative Support Assistant (ASA)',
  'Administrator',
  'Admission Nurse',
  'Admission Nurse Coordinator',
  'Admissions Advisor',
  'Admissions Coordinator',
  'Admissions Counselor',
  'Admissions Director',
  'Admissions Officer',
  'Admissions Representative',
  'Admissions Supervisor',
  'Adobe Layer',
  'Adolescent Counselor',
  'Adoption Agent',
  'Adoption Services Manager',
  'Ad Taker',
  'Adult Basic Education Instructor',
  'Adult Basic Education Manager',
  'Adult Daycare Coordinator',
  'Adult Educator',
  'Adult Health Clinical Nurse Specialist',
  'Adult High School Instructor',
  'Adult Ministries Director',
  'Adult Nurse Practitioner',
  'Adult Parole Officer',
  'Adult Probation Officer',
  'Adult Psychiatric Mental Health Nurse Practitioner',
  'Adult Secondary Education Instructor',
  'Adult Services Librarian',
  'Advance Agent',
  'Advanced Diagnostics Engineering Specialist',
  'Advanced Electronic Field Production Specialist (Advanced EFP Specialist)',
  'Advanced Manufacturing Associate',
  'Advanced Manufacturing Consultant',
  'Advanced Practice Nurse Psychotherapist (APN Psychotherapist)',
  'Advanced Practice Provider',
  'Advanced Research Programs Director',
  'Advanced Seal Delivery System',
  'Adventure Guide',
  'Advertising Account Executive',
  'Advertising Account Manager',
  'Advertising Account Representative',
  'Advertising Agency Manager',
  'Advertising Analyst',
  'Advertising Assistant',
  'Advertising Associate',
  'Advertising Campaign Manager',
  'Advertising Consultant',
  'Advertising Coordinator',
  'Advertising Copywriter',
  'Advertising Designer',
  'Advertising Director',
  'Advertising Executive',
  'Advertising Manager',
  'Advertising Material Distributors Supervisor',
  'Advertising Operations Manager',
  'Advertising Sales Executive',
  'Advertising Sales Manager',
  'Advertising Sales Representative',
  'Advertising Specialist',
  'Advertising Vice President',
  'Advice Clerk',
  'Adviser',
  'Advisor',
  'Advisor To Command In Combat',
  'Advisory Title Officer',
  'Advocate',
  'Ad Writer',
  'Aerial Advertiser',
  'Aerial Crop Duster',
  'Aerial Erector',
  'Aerodynamicist',
  'Aerodynamics Engineer',
  'Aerographer',
  'Aeronautical Drafter',
  'Aeronautical Engineer',
  'Aerospace Control And Warning Systems, Manuel Systems',
  'Aerospace Engineer',
  'Aerospace Technician',
  'Aesthetician',
  'Aesthetics Instructor',
  'Affirmative Action Officer (Aa Officer)',
  'African Studies Professor',
  'After School Tutor',
  'Agency Director',
  'Agency Legal Counsel',
  'Agency Manager',
  'Agency of Transportation Technician (AOT Technician)',
  'Agent',
  'Agility Instructor',
  'Aging Department Supervisor',
  'Agricultural Chemicals Inspector',
  'Agricultural Economist',
  'Agricultural Engineer',
  'Agricultural Engineering Technology Instructor',
  'Agricultural Equipment Design Engineer',
  'Agricultural Mechanic',
  'Agricultural Real Estate Agent',
  'Agricultural Research Director',
  'Agriculture Engineer',
  'Agriculture Manager',
  'Agriculture Research Director',
  'Agriculturist',
  'Agriscience Technology Instructor (Agriculture Science Technology Instructor)',
  'Agronomist',
  'Aide',
  'Air Analysis Engineering Technician',
  'Air and Water Filler',
  'Air Battle Manager',
  'Airborne And Air Delivery Specialist',
  'Airborne Battle Management Systems, Weapons Director',
  'Airborne Missions Systems',
  'Airborne Mission Systems Superintendent',
  'Air Cargo Specialist',
  'Air-Conditioner Window Unit Installer-Servicer',
  'Air-Conditioning Coil Assembler',
  'Air Conditioning Engineer',
  'Aircraft Cabin Cleaner',
  'Aircraft Engineer',
  'Aircraft Inspector',
  'Aircraft Instrument Engineer',
  'Aircraft Maintenance Technician (Aircraft Maintenance Tech)',
  'Aircraft Mechanic',
  'Aircraft Sales Representative',
  'Aircraft Sheet Metal Mechanic',
  'Aircraft Structure Mechanic',
  'Aircraft Structures and Surfaces Assembler',
  'Aircraft Technician',
  'Air Export Agent',
  'Air Export Logistics Manager',
  'Air Export Operations Agent',
  'Air Force Pilot',
  'Airfreight Operations Agent',
  'Air Liaison And Special Staff',
  'Airline Captain',
  'Airline Dispatcher',
  'Airline Pilot',
  'Airline Security Representative',
  'Airman',
  'Airplane Fueler',
  'Airplane Refueler',
  'Airport Maintenance Chief',
  'Airport Manager',
  'Airport Operations Crew Member',
  'Airport Operations Duty Manager',
  'Airport Operations Officer',
  'Airport Ramp Supervisor',
  'Air Quality Chemist',
  'Air Sampling and Monitoring',
  'Airset Molder',
  'Air Support Control Officer',
  'Air Support Operations Operator',
  'Air Traffic Controller, Center',
  'Air Traffic Control Specialist (ATCS)',
  'Alarm Adjuster',
  'Alarm Technician',
  'Alcohol and Drug Abuse Assistance Program Administrator',
  'Alcohol and Drug Counselor',
  'Alderman',
  'Alignment Technician',
  'Allergist/Immunologist',
  'Allergy and Immunology Chief',
  'Allied Health Instructor',
  'All Purpose Clerk (APC)',
  'All Source Intelligence Technician',
  'Alpine Guide',
  'Alternative Financing Specialist',
  'Alto Singer',
  'Aluminum Pourer',
  'Aluminum Welder',
  'Alumni Relations Coordinator',
  'Alumni Secretary',
  'Amalgamator',
  'Ambulance Mechanic',
  'American Board Certified Orthotist (ABC Orthotist)',
  'American Sign Language ASL/English Sign Language Interpreter/RID Certified',
  'AMF Mechanic',
  'Ammunition components Inspector',
  'Amplifier Mechanic',
  'Amusement Park Entertainer',
  'Analog Circuit Designer',
  'Analog Design Engineer',
  'Analysis Tester',
  'Analyst',
  'Analyst Programmer',
  'Analyst Sales',
  'Analytical Chemist',
  'Analytical Chemistry Teacher',
  'Analytical Clerk',
  'Analytical Data Miner',
  'Analytical Research Program Manager',
  'Analytical Sciences Director',
  'Analytics Consultant',
  'Anatomical Embalmer',
  'Anatomist',
  'Anatomy and Physiology Instructor',
  'Anatomy Teacher',
  'Anchor',
  'Anchorman',
  'Ancillary',
  'Anesthesiologist',
  'Anesthesiologist, Pain Management Specialist',
  'Anesthetist',
  'Animal Anatomist',
  'Animal Care Taker',
  'Animal Care Technician',
  'Animal Chiropractor',
  'Animal Control Officer',
  'Animal Feed Products Sales Representative',
  'Animal Health Technician',
  'Animal Nutrition Consultant',
  'Animal Science Section Leader',
  'Animal Shelter Manager',
  'Animation Director',
  'Animation Producer',
  'Animator',
  'Anime Artist',
  'Annealer',
  'Announcer',
  'Annual Giving Officer',
  'Annual Greenhouse Manager',
  'Anodizer',
  'An/Sqq-30 Mine Classifying/Detecting Set Maintenance Technician',
  'An/Sqq-32(V)3 Minehunting Sonar Set Operator (Mss)',
  'An/Sqq-89(V)14 Sonar System Level I Operator',
  'An/Ssn-2 (V) 4 Operator',
  'An/Syq-13 Nav/C2 Operator',
  'Antenna Installer',
  'Antique Dealer',
  'Anti-Tank Missileman',
  'Aoc Information Operations Staff Officer',
  'Aoc Strategy Plans And Operational Assessment Officer',
  'Aoc Strategy Plans And Operational Assessment Officer Chief',
  'Apartment Manager',
  'Apiculturist',
  'Apothecary',
  'Apparel Designer',
  'Apparel Fashion Designer',
  'Apparel Merchandiser',
  'Appeals and Generalist Clerk',
  'Appeals Examiner',
  'Apple Picker',
  'Appliance Repairer',
  'Application Architect',
  'Application Defense Manager',
  'Application Development Director',
  'Application Engineer',
  'Application Integration Engineer',
  'Application Integrator',
  'Application Programer Analyst',
  'Application Programmer Analyst',
  'Applications Analyst',
  'Applications Developer',
  'Applications Engineer',
  'Applications Engineer, Manufacturing',
  'Applications Quality Assurance Specialist',
  'Applications Software Engineering Information Technology Specialist',
  'Applications System Analyst',
  'Applications Systems Analyst',
  'Applications Trainer',
  'Application Systems Administrator',
  'Applied Computer Science Professor',
  'Applied Marine Physics Professor',
  'Applied Mathematician',
  'Applied Science and Technologies Dean',
  'Appraisal Technician',
  'Appraiser',
  'Apprentice',
  'Apprentice Carpenter',
  'Apprentice Electrician',
  'Apprentice/Lineman',
  'Apprentice Lineman Third Step',
  'Apprenticeship and Training Representative',
  'Apprenticeship Consultant',
  'Aquaculture Worker',
  'Aquaculturist',
  'Aquatic Biologist',
  'Aquatic Laborer',
  'Aquatic Performer',
  'Aquatics Director',
  'Arabic Translator',
  'Arbitrator',
  'Arboriculturist',
  'Arborist',
  'Archaeologist',
  'Archery Equipment Hay Sorter',
  'Architect',
  'Architect in Training',
  'Architect Manager',
  'Architectural Designer',
  'Architectural Drafter',
  'Architectural Draftsman',
  'Architectural Hardware Consultant Project Manager',
  'Architectural Intern',
  'Architectural Project Manager',
  'Architectural Technician',
  'Architectural Technologist',
  'Architecture Technician',
  'Archival Records Clerk',
  'Archives Director',
  'Archivist',
  'Arch Support Maker',
  'Arc Welder',
  'Area Coordinator',
  'Area Development Manager',
  'Area Director',
  'Area Field Person',
  'Area Loss Prevention Manager',
  'Area Sales Manager',
  'Area Secretary',
  'Area Supervisor',
  'Armament Weapons Support Equipment (Aswe) Maintenance Manager',
  'Armature Rewinder',
  'Armature Winder',
  'Armed Guard',
  'Armored Transport Service Manager',
  'Armored Truck Driver',
  'Arm Rest Builder',
  'Army Helicopter Pilot',
  'Army Officer',
  'Army Ranger',
  'Aromatherapist',
  'Arranger',
  'Arrowsmith',
  'Art Consultant',
  'Art Coordinator',
  'Art Critic',
  'Art Dealer',
  'Art Department Head',
  'Art Director',
  'Art Editor',
  'Art Educator',
  'Art Framing Manager',
  'Art Gallery Director',
  'Articulation Officer',
  'Artificial Intelligence Specialist',
  'Art Instructor',
  'Artisan',
  'Artist',
  'Artist and Repertoire Manager',
  'Artistic Associate',
  'Artistic Director',
  'Artist Manager',
  'Artist Representative',
  'Art Museum Aide',
  'Arts and Crafts Instructor',
  'Arts and Crafts Teacher',
  'Arts and Humanities Council Director',
  'Arts Manager',
  'Art Teacher',
  'Art Therapist',
  'Asbestos Microscopist',
  'Asbestos Remover',
  'ASE Master Mechanic (Automotive Service Excellence Master Mechanic)',
  'Ash Collector',
  'Asphalt Engineer',
  'Assembled Wood Products Repairer',
  'Assembler',
  'Assembly and Packing Supervisor',
  'Assembly Associate',
  'Assembly Line Worker',
  'Assembly Member',
  'Assembly Supervisor',
  'Assembly Technician',
  'Assembly Worker',
  'Assessment Director',
  'Assessment Specialist',
  'Assessor',
  'Asset Analyst',
  'Asset Manager',
  'Asset Protection Lead',
  'Asset Protection Specialist',
  'Assigner',
  'Assignment Clerk',
  'Assignment Editor',
  'Assistant',
  'Assistant Athletic Trainer',
  'Assistant-at-Surgery',
  'Assistant Attorney General',
  'Assistant Casino Shift Manager',
  'Assistant Chief Train Dispatcher',
  'Assistant Clinical Professor/Medical Director Subacute Rehabilitation',
  'Assistant Coach',
  'Assistant Counsel',
  'Assistant County Engineer',
  'Assistant Dean',
  'Assistant Dean of Students',
  'Assistant Designer',
  'Assistant Director',
  'Assistant Director for Financial Literacy',
  'Assistant Director of Nutrition and Wellness Programs',
  'Assistant Director of Plant Operations',
  'Assistant Director of Residence Life',
  'Assistant Director of Student Activities and Housing',
  'Assistant District Attorney',
  'Assistant Division Chief for Statistical Program Management',
  'Assistant Editor',
  'Assistant Engineer',
  'Assistant Federal Public Defender',
  'Assistant Film Editor',
  'Assistant Football Coach',
  'Assistant Foreman',
  'Assistant GM of Content & Delivery',
  'Assistant Hall Director',
  'Assistant HVAC Mechanic',
  'Assistant Librarian',
  'Assistant Manager',
  'Assistant Manager of Operations',
  'Assistant Manager Quality Management',
  'Assistant Manager Retail',
  'Assistant Operator',
  'Assistant Paralegal',
  'Assistant Plant Control Operator',
  'Assistant Principal',
  'Assistant Producer',
  'Assistant Professor',
  'Assistant Professor in Family Studies',
  'Assistant Professor of Biochemistry',
  'Assistant Professor of Chemistry',
  'Assistant Professor of English',
  'Assistant Professor of Life Sciences',
  'Assistant Professor of Marine Biology',
  'Assistant Professor of Physical Education Teacher Education',
  'Assistant Professor of Psychology',
  'Assistant Professor of Public Administration',
  'Assistant Professor of Radiology',
  'Assistant Professor of Recreation Leadership',
  'Assistant Professor of Religion',
  'Assistant Professor of Speech Communication',
  'Assistant Professor of Theatre and Fine Arts',
  'Assistant Professor Surgical Technology',
  'Assistant Property Manager',
  'Assistant Prosecutor',
  'Assistant Public Defender',
  'Assistant Research Scientist',
  'Assistant Scientist',
  'Assistant Secretary',
  'Assistant State Attorney',
  'Assistant Store Manager',
  'Assistant Superintendent',
  'Assistant Superintendent for Curriculum',
  'Assistant Surveyor',
  'Assistant Teacher',
  'Assistant To The President',
  'Assistant To The Vice President',
  'Assistant Track and Field Coach',
  'Assistant Vice President, Assessment Solutions',
  'Assistant Vice President (AVP)',
  'Assistant Vice President, Investment Analysis',
  'Assisted Living Administrator',
  'Assisted Living Manager',
  'Assistive Technology Trainer',
  'Associate',
  'Associate Attorney',
  'Associate Broker',
  'Associate Counsel',
  'Associate Creative Director ACD Art Director',
  'Associate Curator',
  'Associate Dean',
  'Associate Dean of Students',
  'Associate Dentist',
  'Associate Director',
  'Associate Director of Biostatistics',
  'Associate Director of Sales',
  'Associate Director, QA',
  'Associate Faculty',
  'Associate Financial Representative',
  'Associate Medical Director for Adolescent Services',
  'Associate Producer',
  'Associate Product Integrity Engineer',
  'Associate Professor',
  'Associate Professor Computer Science',
  'Associate Professor of Art History',
  'Associate Professor of Automation',
  'Associate Professor of Biblical Studies',
  'Associate Professor of Church Music',
  'Associate Professor of Communication Arts',
  'Associate Professor of Media Arts',
  'Associate Professor of Medicine',
  'Associate Professor of Political Science',
  'Associate Professor of Theatre',
  'Associate Sales',
  'Associate Teacher',
  'Associate Veterinarian',
  'Associate Vice President',
  'Association Executive',
  'Assorter',
  'Assurance Manager',
  'Assurance Senior',
  'Astrochemist',
  'Astronaut',
  'Astronaut, Mission Specialist',
  'Astronomer',
  'ASW Operations Center Equipment Operator',
  'Athlete Manager',
  'Athlete Marketing Agent',
  'Athletic Coach',
  'Athletic Director',
  'Athletic Instructor',
  'Athletics Director',
  'Athletic Trainer',
  'Athletic Training Education Program Director',
  'At Home Independent Call Center Agent',
  'At Risk Paraprofessional',
  'At-Risk Specialist',
  'Attache',
  'Attendance and Discipline Vice Principal',
  'Attendant',
  'Attending Physiatrist, Residency Program Director',
  'Attending Physician',
  'Attorney',
  'Attorney at Law',
  'Attorney General',
  'Attractions Associate',
  'Auction Clerk',
  'Auctioneer',
  'Audience Coordinator',
  'Audio Engineer',
  'Audiologist',
  'Audiology Assistant',
  'Audiology Director',
  'Audiology Doctor (AUD)',
  'Audiometrist',
  'Audio Operator',
  'Audioprosthologist',
  'Audio/Video Engineer',
  'Audio Video Technician',
  'Audio-Visual Arts Director',
  'Audiovisual Communications Systems Technician',
  'Audio-Visual Director',
  'Audio/Visual Manager',
  'Audio-Visual Production Specialist',
  'Audiovisual Program Productions Sales Representative',
  'Audio-Visual Specialist',
  'Audiovisual Technician',
  'Audio Visual Technician',
  'Audit Associate',
  'Audit Clerk',
  'Audit Clerks Supervisor',
  'Auditing Manager',
  'Audit Manager',
  'Auditor',
  'Auditor-In-Charge',
  'Auditor/Quality',
  'Audit Partner',
  'Au Pair',
  'Auriculotherapist',
  'Author',
  'Authorization Coordinator',
  'Authorizer',
  'Autism Spectrum Disorder Education Specialist',
  'Autism Tutor',
  'Autistic Teacher',
  'Auto Accessories Installer',
  'Auto Body Repair Estimator',
  'AutoCAD Designer',
  'Auto Club Safety Program Coordinator',
  'Auto Clutch Rebuilder',
  'Auto Damage Estimator',
  'Auto Dealer (Automobile Dealer)',
  'Auto Electrician',
  'Auto Fleet Maintenance Manager',
  'Auto Hiker',
  'Auto Locator',
  'Automated Access Systems Technician',
  'Automated Equipment Engineering Technician',
  'Automated Equipment Engineer-Technician',
  'Automated Teller Manager',
  'Automatic Data Processing Planner',
  'Automatic Data Processing Systems Security Specialist (ADP Systems Security)',
  'Automation and Controls Instructor',
  'Automation and Controls Manager',
  'Automation Engineer',
  'Automation Manager',
  'Automation Specialist',
  'Automation Technician',
  'Automobile and Property Underwriter',
  'Automobile Dealer',
  'Automobile Lights Assembler',
  'Automobile Self-Serve Service Station Attendant',
  'Automotive Accessory Installer',
  'Automotive Design Layout Drafter',
  'Automotive Engineer',
  'Automotive Exhaust Emissions Technician',
  'Automotive Generator and Starter Repairer',
  'Automotive Glass Technician (Auto Glass Technician)',
  'Automotive Leasing Sales Representative',
  'Automotive Metalsmith',
  'Automotive Porter',
  'Automotive Power Electronics Engineer',
  'Automotive Production Worker',
  'Automotive Service Manager',
  'Automotive Technician',
  'Automotive Technology Instructor',
  'Automotive Tire-Testing Supervisor',
  'Auto Parts Clerk',
  'Auto Parts Manager',
  'Auto Repair Shop Manager',
  'Autos Disassembler',
  'Auto Service Writer',
  'Auto Specialty Services Manager',
  'Auto Suspension and Steering Mechanic',
  'Auto Technician',
  'Aviation Consultant',
  'Aviation Electrician',
  'Aviation Electronic Warfare Operator',
  'Aviation Maintenance Technician (AMT)',
  'Aviation Manager',
  'Aviation Mechanic',
  'Aviation Operations Specialist',
  'Aviation Resource Management Superintendent',
  'Aviation Tactical Readiness Officer',
  'Aviator',
  'Aviculturist',
  'Avid Editor',
  'Avionics Engineer',
  'Avionics Manager',
  'Avionics Systems Integration Specialist',
  'Avionics Technician',
  'Baby Counselor',
  'Babysitter',
  'Baby Sitter',
  'Background Investigator',
  'Back Hand',
  'Back Hanger',
  'Back Up Machine Operator',
  'Backup Sawyer',
  'Bacon Slicer',
  'Bacteriologist',
  'Bacteriology Lab Specialist',
  'Badger Distiller Operator',
  'Bad Work Gatherer',
  'Bagel Maker',
  'Bagger',
  'Bail Bond Agent',
  'Bail Bondsman',
  'Bait Digger',
  'Baker',
  'Bakery Clerk',
  'Bakery Manager',
  'Baking Assistant',
  'Balance and Hairspring Assembler',
  'Balance Assembler',
  'Ballet Company Artistic Director',
  'Ballet Company Member',
  'Ballet Soloist',
  'Ball Holder',
  'Ballistician',
  'Balloonist',
  'Banana Carrier',
  'B and B Gang Worker',
  'Band Director',
  'Bank Accountant',
  'Bank Analyst',
  'Bank and Savings Securities Trader',
  'Bank Cashier',
  'Bank Clerk',
  'Bank Compliance Officer',
  'Banker',
  'Bank Examiner',
  'Banking Analyst',
  'Banking and Finance Instructor',
  'Banking Center Manager',
  'Banking Manager',
  'Banking Officer',
  'Banking Pin Adjuster',
  'Banking Services Officer',
  'Banking Supervisor',
  'Bankman',
  'Bank Manager',
  'Bank Officer',
  'Bank Operations Officer',
  'Bank President',
  'Bank Reconciliator',
  'Bank Representative',
  'Bankruptcy Assistant',
  'Bank Secrecy Act Anti-Money Laundering Officer (BSA/AML Officer)',
  'Bank Teller',
  'Banner Painter',
  'Banquet Captain',
  'Banquet Chef',
  'Banquet Director',
  'Banquet Manager',
  'Banquet Server',
  'Bar Attendant',
  'Bar Back',
  'Barber',
  'Bar Hostess',
  'Bariatric Weight Loss Clinic Manager and Counselor',
  'Barista',
  'Barkeep',
  'Barmaid',
  'Barman',
  'Bar Manager',
  'Barn Manager',
  'Barrister',
  'Bartender',
  'Bartender Server',
  'Baseball Coach',
  'Base Brander',
  'Basketball Coach',
  'Basketball Referee',
  'Bass Fisher',
  'Bassoonist',
  'Batch and Furnace Manager',
  'Batch Blender',
  'Bath Attendant',
  'Bath Steward',
  'Baton Twirler',
  'Battalion Chief',
  'Battery Assembler',
  'Batteryman',
  'Battery Technician',
  'Bay Stocker',
  'BD Special Education Teacher (Behavioral Disorders Special Education Teacher)',
  'Beach Attendant',
  'Beadworker',
  'Beam Builder',
  'Beautician',
  'Beauty Advisor',
  'Beauty Consultant',
  'Beauty Shop Manager',
  'Beauty Therapist',
  'Bed and Breakfast Innkeeper',
  'Bed Bug Exterminator',
  'Beef Boner',
  'Bee Keeper',
  'Beer Brewer',
  'Behavioral Analyst',
  'Behavioral Health Case Manager',
  'Behavioral Health Consultant',
  'Behavioral Health Technician',
  'Behavioral Interventionist',
  'Behavioral Therapist',
  'Behavior Analyst',
  'Behavior Interventionist',
  'Behavior Specialist',
  'Behavior Support Specialist (BSS)',
  'Behavior Therapist',
  'Bellman',
  'Bellperson',
  'Bell Ringer',
  'Bellstaff',
  'Bench Jeweler',
  'Ben-Day Artist',
  'Bender',
  'Bend Up',
  'Benefit Authorizer',
  'Benefit Director',
  'Benefits Administrator',
  'Benefits Analyst',
  'Benefits Consultant',
  'Benefits Coordinator',
  'Benefits Manager',
  'Benefit Specialist',
  'Benefits Representative',
  'Benefits Specialist',
  'Berry Planter',
  'Best Worker',
  'Beta Tester',
  'Bet Taker',
  'Bibliographer',
  'Bicycle Mechanic',
  'Bid Analyst',
  'Big 6 Dealer',
  'Bike Assembler',
  'Bike Technician',
  'Bilingual School Psychologist',
  'Bilingual Secretary',
  'Bilingual Speech-Language Pathologist',
  'Bilingual Teacher',
  'Bilingual Trainer',
  'Billboard Erector',
  'Biller',
  'Billing Administrator',
  'Billing and Accounting Staff Assistant',
  'Billing and Insurance Coordinator',
  'Billing and Quality Technician',
  'Billing Clerk',
  'Billing Coordinator',
  'Billing Department Supervisor',
  'Billing Manager',
  'Billing Specialist',
  'Billing Supervisor',
  'Bill of Lading Clerk',
  'Bill of Materials Clerk (BOM Clerk)',
  'Billposter',
  'Bindery Operator',
  'Bindery Worker',
  'Bingo Manager',
  'Biodiesel Product Development Manager',
  'Biodiesel Technology Development Manager',
  'Bioengineer',
  'Biofuels Manager',
  'Biogeographer',
  'Bioinformatician',
  'Bioinformatics Developer',
  'Bioinformatics Scientist',
  'Bioinformatics Software Engineer',
  'Bioinformatics Specialist',
  'Biological Science Laboratory Technician',
  'Biological Scientist',
  'Biologist',
  'Biology Instructor',
  'Biology Research Assistant',
  'Biology Teacher',
  'Biomathematician',
  'Bio-Mechanical Engineer',
  'Biomedical Analytical Scientist',
  'Biomedical Engineer',
  'Biomedical Engineering Technician (BMET)',
  'Biomedical Equipment Support Specialist',
  'Biomedical Equipment Technician (BMET)',
  'Biomedical Field Service Engineer',
  'Biomedical Imaging Center Director',
  'Biomedical Manager',
  'Biomedical Scientist',
  'Biomedical Technician',
  'Biometrician',
  'Biometrics Consultant',
  'Biometric Screener',
  'Biometrics Technician',
  'Bioprocess Engineer',
  'Biosolids Management Technician',
  'Biostatistician',
  'Biostatistics Director',
  'Biostatistics Professor',
  'Biotechnician',
  'Birth Attendant',
  'Biscuit Maker',
  'Bit Grinder',
  'Bi Tri Operator',
  'Black Bear Project Leader',
  'Blacksmith',
  'Blade Grinder',
  'Blade Sharpener',
  'Blaster',
  'Blend Technician',
  'Blind Aide',
  'Blindmaker',
  'Blister Rust Eradicator',
  'Block Maker',
  'Blockmason',
  'Block Mason',
  'Blogger',
  'Blow Molder',
  'Blue Crabber',
  'Board Attendant',
  'Board Certified Arts Therapist',
  'Board Certified Family Physician',
  'Board Certified Orthodontist',
  'Board Machine Set Up Operator',
  'Board of Education Secretary',
  'Board Worker',
  'Boat Builder',
  'Boats Renter',
  'Boatswain',
  'Boatwright',
  'Bobbin Winder',
  'Bodily Injury Adjuster',
  'Bodyguard',
  'Body Make-Up Artist',
  'Body Piercer',
  'Boiler Attendant',
  'Boilermaker',
  'Boilermaker Pipe Fitter',
  'Boilermaker Welder',
  'Boiler Operator',
  'Boilers Inspector',
  'Boiler Technician',
  'Bomb Squad Commander',
  'Bonding Supervisor',
  'Bond Underwriter',
  'Bone Picker',
  'Bookbinder',
  'Book Editor',
  'Booker',
  'Book Illustrator',
  'Booking Agent',
  'Booking Clerk',
  'Booking Manager',
  'Bookkeeper',
  'Book Keeper',
  'Bookkeeping Assistant',
  'Book Publisher',
  'Book Reviewer',
  'Books Salesperson',
  'Boomer',
  'Bootblack',
  'Booth Supervisor',
  'Boot Maker',
  'Borough Coordinator',
  'Boss',
  'Boston Cutter',
  'Bosun',
  'Botanist',
  'Bottle and Glass Inspector',
  'Bottled Beverage Inspector',
  'Bottle Washer',
  'Bottling Equipment Sales Representative',
  'Bowling Teacher',
  'Bowman',
  'Bow Rehairer',
  'Box Office Manager',
  'Box Worker',
  'Bqq-5/5 (Series) Submarine Sonar Advanced Maintenance Technician',
  'Brace Maker',
  'Bradley Linebacker Crewmember',
  'Brain Surgeon',
  'Brakes Inspector',
  'Branch Banker',
  'Branch Chief',
  'Branch Lending Officer',
  'Branch Manager',
  'Branch Office Manager',
  'Branch Operation Evaluation Manager',
  'Branch Operations Manager',
  'Brand Advocate',
  'Brand Ambassador',
  'Branding Machine Operator',
  'Brand Manager',
  'Brass Roller',
  'Bread Molder',
  'Break and Load Operator',
  'Break Down Operator',
  'Breakfast Attendant',
  'Breakfast Cook',
  'Break Out Man',
  'Breast Puller',
  'Breeder Complex Manager',
  'Breeding Technician',
  'Brewer',
  'Brewery Worker',
  'Brewing Technician',
  'Brick and Block Mason',
  'Bricklayer',
  'Brickmason',
  'Bridal Consultant',
  'Bridge Crew Member',
  'Bridge Engineer',
  'Bridge Inspector',
  'Bridges Supervisor',
  'Bridge/Structure Inspection Team Leader',
  'Bright Cutter',
  'Broach Grinder',
  'Broadband Installer',
  'Broadcast Engineer',
  'Broadcaster',
  'Broadcasting Equipment Mechanic',
  'Broadcast Journalist',
  'Broadcast News Producer',
  'Broadcast Operations Director',
  'Broadcast Producer',
  'Broadcast Technician',
  'Broiler Chef or Cook',
  'Broker',
  'Brokerage Coordinator',
  'Brokerage Office Manager',
  'Brokerage Purchase-and-Sale Clerk',
  'Broker Assistant',
  'Broker Associate',
  'Broker in Charge',
  'Brownfield and Economic Development Director',
  'Brown Sourer',
  'BSA/AML Compliance Officer',
  'BSA Officer',
  'Bucket Operator',
  'Budget Analyst',
  'Budget and Policy Analyst',
  'Budget Manager',
  'Budget Officer',
  'Bug Worker',
  'Buhr Dresser',
  'Builder',
  'Building Architectural Designer',
  'Building Carpenter',
  'Building Code Administrator',
  'Building Construction Estimator',
  'Building Consultant',
  'Building Contractor',
  'Building Engineer',
  'Building Equipment Operator (BEO)',
  'Building Estimator',
  'Building Inspector',
  'Building Manager',
  'Building Materials or Lumber Yard Supervisor',
  'Building Materials Sales Attendant',
  'Buildings and Grounds Coordinator',
  'Buildings and Grounds Director',
  'Building Supervisor',
  'Build Master',
  'Bulk Coolers Installer',
  'Bulk Loader',
  'Bull Bucker',
  'Bung Dropper',
  'Bunker Worker',
  'Bureau Chief',
  'Bureau Director',
  'Burling and Joining Supervisor',
  'Bursar',
  'Bus and Rail Operator',
  'Bus and Trolley Dispatcher',
  'Busboy',
  'Bus Boy',
  'Bus Driver',
  'Busines Development Representative',
  'Business Administration Program Chair',
  'Business Administrator',
  'Business Agent',
  'Business Analyst',
  'Business Analytics Director',
  'Business Analytics Faculty Member',
  'Business and Economics Librarian',
  'Business and Marketing Teacher',
  'Business and Office Technology Instructor',
  'Business and Services Instructor',
  'Business Banker',
  'Business Banking Officer',
  'Business Communications Instructor',
  'Business Computer Applications Instructor',
  'Business Consultant',
  'Business Continuity and Crisis Management Director',
  'Business Continuity Global Director',
  'Business Continuity Management Director',
  'Business Continuity Manager',
  'Business Continuity Planning Director',
  'Business Continuity Specialist',
  'Business Continuity Strategy Director',
  'Business Coordinator',
  'Business Department Chair',
  'Business Developer',
  'Business Development Analyst',
  'Business Development Assistant',
  'Business Development Director',
  'Business Development Executive',
  'Business Development Manager',
  'Business Development Officer',
  'Business Development Specialist',
  'Business Director',
  'Business Division Chair',
  'Business Economist',
  'Business Editor',
  'Business Education Instructor',
  'Business Education Teacher',
  'Business English Instructor',
  'Business Enterprise Officer',
  'Business Ethics Professor',
  'Business Executive',
  'Business Insight and Analytics Manager',
  'Business Instructor',
  'Business Intelligence Analyst',
  'Business Intelligence Manager',
  'Business Intelligence Systems Manager',
  'Business Law Instructor',
  'Business Law Professor',
  'Business Lawyer',
  'Business Management Analyst',
  'Business Management Consultant',
  'Business Manager',
  'Business Office Manager',
  'Business Operations Analyst',
  'Business Planner',
  'Business Practices Officer',
  'Business Process Analyst',
  'Business Process Consultant',
  'Business Relationship Manager',
  'Business Reporter',
  'Business Representative',
  'Business School Dean',
  'Business Services Representative',
  'Business Services Sales Representative',
  'Business Services Vice President',
  'Business Systems Analyst',
  'Business Taxes Specialist',
  'Business Teacher',
  'Business Unit Manager',
  'Business Writer',
  'Bus Operator',
  'Busser',
  'Butcher',
  'Butcherette',
  'Butler',
  'Button Facing Machine Operator',
  'Button Puncher',
  'Buyer',
  'Buyer Assistant',
  "Buyers' Agent",
  'Buying Agent',
  'C-13 Catapult Operator',
  'C2 Tactical Analysis Technician',
  'C4 Planner',
  'Cabin Cleaner',
  'Cabinet Builder',
  'Cabinetmaker',
  'Cabinet Maker',
  'Cable Assembler and Swager',
  'Cable Engineer',
  'Cable Installation Maintenance and Repair Manager',
  'Cable Installation Technician',
  'Cable Installer',
  'Cab Starter',
  'Cadastral Mapper',
  'CAD Drafter (Computer Aided Design CAD Drafter)',
  'Caddy',
  'Caddymaster',
  'Cadet',
  'Cafeteria Manager',
  'Cafe Worker',
  'Cake Decorator',
  'Calciminer',
  'Calculator Operator',
  'Calibration and Instrumentation Technician',
  'Calibration Laboratory Technician',
  'Calibration Specialist',
  'Calibration Technician',
  'Call Box Wirer',
  'Call Center Agent',
  'Call Center Director',
  'Call Center Manager',
  'Call Center Representative',
  'Call Center Supervisor',
  'Call Center Support Representative',
  'Call Person',
  'Call Worker',
  'Camera Engineer',
  'Cameraman',
  'Camera Operator',
  'Camera Person',
  'Cam Maker',
  'Campaign Director',
  'Campaigner',
  'Campaign Manager',
  'Camp Boss',
  'Camp Counselor',
  'Camp Director',
  'Camp Manager',
  'Campus Administrator',
  'Campus Coordinator',
  'Campus Director',
  'Campus Security Officer',
  'Campus Wellness Coordinator',
  'Canal Equipment Maintenance Supervisor',
  'Can Carrier',
  'Cancer Center Director',
  'Cancer Program Consultant',
  'Cancer Registrar',
  'Cancer Researcher',
  'Candle Cutter',
  'Candlemaker',
  'Cane Splicer',
  'Canine Deputy (K-9 Deputy)',
  'Canine Service Instructor-Trainer',
  'Can Maker',
  'Cannon Crewmember',
  'Canopy Stringer',
  'Canvasser',
  'Capital Campaign Fundraiser',
  'Captain',
  'Carbide Operator',
  'Carbon Capture Power Plant Engineer',
  'Carbon Capture Power Plant Manager',
  'Carbon Paper Coating Supervisor',
  'Carbon Sequestration Plant Manager',
  'Carburetor Rebuilder',
  'Card Clothier',
  'Cardiac Sonographer',
  'Cardiologist',
  'Cardiology Clinical Nurse Specialist',
  'Cardiopulmonary Rehabilitation Respiratory Therapist',
  'Cardiopulmonary Technician and EEG Tech (Cardiopulmonary Technician and Electroencephalogram Technician)',
  'Cardiovascular Clinical Nurse Specialist',
  'Cardiovascular Disease Specialist',
  'Cardiovascular Radiologic Technologist',
  'Cardiovascular Sonographer',
  'Card Player',
  'Card Room Manager',
  'Cardroom Plastic Card Grader',
  'Career Advisor',
  'Career and Technology Education Teacher (CTE Teacher)',
  'Career and Transition Teacher',
  'Career Center Director',
  'Career Coach',
  'Career Consultant',
  'Career Coordinator',
  'Career Counselor',
  'Career Developer',
  'Career Development Coordinator/Teacher',
  'Career Development Director',
  'Career Development Specialist',
  'Career Manager',
  'Career Orientation Teacher',
  'Career Portals Teacher',
  'Career Services Coordinator',
  'Career Services Director',
  'Career Specialist',
  'Career Technical Education Instructor',
  'Career Technical Education Teacher',
  'Caregiver',
  'Care Giver',
  'Care Manager',
  'Care Provider',
  'Caretaker',
  'Care Taker',
  'Care Transitions Nurse',
  'Cargo Agent',
  'Cargo and Ramp Services Manager',
  'Cargo Broker',
  'Cargo Supervisor',
  'Cargo Surveyor',
  'Car Head Liner Installer',
  'Car Inspection and Repair Manager',
  'Car Inspector',
  'Carpenter',
  'Carpenter Foreman',
  'Carpenter/Labor',
  'Carpenter Supervisor',
  'Carpentry',
  'Carpet Cleaning Technician',
  'Carpet Installer',
  'Car Porter',
  'Car Rental Agent',
  'Carrier',
  'Carrier Associate',
  'Carry All Driver',
  'Carry Out Clerk',
  'Car Salesman',
  'Car Supplier',
  'Cartoonist',
  'Cartperson',
  'Cartridge Maker',
  'Carver',
  'Car Wash Manager',
  'Cascade Operator',
  'Case Aide',
  'Case Coordinator',
  'Case Management Assistant',
  'Case Manager',
  'Case Reviewer',
  'Case Supervisor',
  'Caseworker',
  'Case Worker',
  'Cash Accountant',
  'Cash Checker',
  'Cashier',
  'Cash Manager',
  'Cash Office Worker',
  'Casino Banker',
  'Casino Games Dealer',
  'Casino Gaming Worker',
  'Casino Manager',
  'Casino Supervisor',
  'Casket Trimmer',
  'Cast Associate',
  'Casting Associate',
  'Casting Director',
  'Castings Trimmer',
  'Casualty Claims Supervisor',
  'Cataloger',
  'Catalog Librarian',
  'Catalogue Clerk',
  'Catalyst Operator',
  'Cat and Dog Bather',
  'Catechist',
  'Category Development Manager',
  'Category Manager',
  'Caterer',
  'Catering and Convention Services Manager',
  'Catering Assistant',
  'Catering Coordinator',
  'Catering Manager',
  'Caterpillar Mechanic',
  'Catheterization Laboratory Senior Manager (Cath Lab Senior Manager)',
  'Cathode Maker',
  'Catholic Priest',
  'Cat Mechanic',
  'Cattle Feeder',
  'Cattleman',
  'Cause Analyst',
  'CDL Truck Driver (Commercial Drivers License Truck Driver)',
  'Cd Technician',
  'Celebrity Manager',
  'Cell Biology Scientist',
  'Cellist',
  'Cell Preparer',
  'Cell Repairer',
  'Cement Finisher',
  'Cement Gun Operator',
  'Censor',
  'Census Enumerator',
  'Census Taker',
  'Center Administrator',
  'Center Director',
  'Centerless Grinder',
  'Central Communications Specialist',
  'Central Lab Technician (CLT)',
  'Central Office Equipment Engineer',
  'Central Office Facilities Planning Engineer',
  'Central Office Operator',
  'Central Office Technician',
  'Central Office Worker (CO Worker)',
  'Central Processing Technician (CPT)',
  'Central Service Supply Distributor',
  'Central Sterile Supply Technician (CSS Technician)',
  'Central Supply Manager',
  'Central Supply Supervisor',
  'Centrifugal Chiller Technician',
  'CEO/LEED Ap, O & M, Director of Sustainability (Chief Environmental Officer/Leadership in Energy and Environmental Design Applications, Operations and Management, Director of Sustainability)',
  'Ceramic Designer',
  'Ceramics Instructor',
  'Cereal Supervisor',
  'Certificate of Clinical Competence in Audiology Licensed Audiologist (CCC-A Licensed Audiologist)',
  'Certification and Selection Specialist',
  'Certification Officer',
  'Certified Athletic Trainer',
  'Certified Corporate Travel Executive',
  'Certified Dental Assistant (CDA)',
  'Certified Diabetes Educator',
  'Certified Dietary Manager (CDM)',
  'Certified Driver Examiner (CDE)',
  'Certified Financial Planner (CFP)',
  'Certified First Assistant (CFA)',
  'Certified Flight Instructor (CFI)',
  'Certified Income Tax Preparer (CTP)',
  'Certified Indoor Environmentalist',
  'Certified Information Systems Security Professional (CISSP)',
  'Certified Legal Secretary Specialist',
  'Certified Massage Therapist (CMT)',
  'Certified Master Locksmith (CML)',
  'Certified Master Safecracker (CMS)',
  'Certified Medical Assistant (CMA)',
  'Certified Meeting Professional',
  'Certified Novell Administrator (CNA)',
  'Certified Nurse Aide (CNA)',
  'Certified Nursing Assistant (CNA)',
  'Certified Nursing Assistant Instructor (CNA Instructor)',
  'Certified Occupational Therapist Assistant (COTA)',
  'Certified Occupational Therapy Assistant (COTA)',
  'Certified Ophthalmic Assistant',
  'Certified Ophthalmic Technician',
  'Certified Pharmacy Technician (CPhT)',
  'Certified Physician Assistant (PA-C)',
  'Certified Prosthetist, Certified Pedorthist',
  'Certified Prosthetist (CP)',
  'Certified Public Accountant',
  'Certified Public Accountant (CPA)',
  'Certified Real Estate Appraiser',
  'Certified Registered Dental Assistant',
  'Certified Registered Nurse Practitioner',
  'Certified Surgical Technologist (CST)',
  'Certified Veterinary Technician (CVT)',
  'Certified Vision Rehabilitation Therapist',
  'Certified Welding Inspector (CWI)',
  'Certified Wellness Program Coordinator',
  'Certified Wellness Program Manager',
  'Chain, Rod, or Ax Survey Worker',
  'Chair',
  'Chairman',
  'Chairperson',
  'Chair Springer',
  'Chambermaid',
  'Chamber of Commerce Division Manager',
  'Chamber of Commerce Executive Vice President',
  'Chamber Walker',
  'Champion of Sustainable Design',
  'Chancellor',
  'Change House Attendant',
  'Change Over',
  'Channeler',
  'Channel Manager',
  'Channel Sales Director',
  'Channel Supervisor',
  'Chaperone',
  'Chaplain',
  'Character Impersonator',
  'Charcoal Burner',
  'Chargeback Specialist',
  'Charge Nurse',
  'Chart Clerk',
  'Charter',
  'Charter Driver',
  'Chartered Accountant',
  'Chassis Mechanic',
  'Chauffeur',
  'Checker',
  'Checker/Stocker',
  'Check Out Clerk',
  'Cheerleading Coach',
  'Cheesemaker',
  'Cheese Maker',
  'Chef',
  'Chef Concierge',
  'Chef Concierge/Director of Guest Relations',
  'Chef de Cuisine',
  'Chef De Partie',
  'Chef, Instructor',
  'Chef Manager',
  'Chemical/Analytical Lab Technician',
  'Chemical, Biological, Radiological, And Nuclear (CBRN) Officer',
  'Chemical Compounder',
  'Chemical Dependency Counselor',
  'Chemical Detection Expert',
  'Chemical Economist',
  'Chemical Engineer',
  'Chemical Engineering Technician',
  'Chemical Equipment Controller',
  'Chemical Munitions And Materiel Management',
  'Chemical Operations And Training',
  'Chemical Operator',
  'Chemical Plant Technical Director',
  'Chemical Process Engineer',
  'Chemical Treatment Plant Technician',
  'Chemist',
  'Chemistry Instructor',
  'Chemistry Quality Control Analyst',
  'Chemistry Teacher',
  'Chemistry Technician',
  'Chemotherapist',
  'Chest Painting and Sealing Supervisor',
  'Chick Grader',
  'Chief Accountant',
  'Chief Administrative Officer',
  'Chief Airport Guide',
  'Chief Bank Examiner',
  'Chief Catalyst Operator',
  'Chief Communications Officer',
  'Chief Compliance Officer',
  'Chief Concierge (Head Concierge)',
  'Chief Controller',
  'Chief Cook',
  'Chief Deputy',
  'Chief Deputy Sheriff',
  'Chief Design Branch',
  'Chief Design Engineer',
  'Chief Diversity Officer (CDO)',
  'Chief Engineer',
  'Chief, Engineering Division',
  'Chief Executive Officer (CEO)',
  'Chief Financial Officer (CFO)',
  'Chief, General Pediatric Clinic',
  'Chief Hospital Administrator',
  'Chief Information Officer',
  'Chief Innovation Officer',
  'Chief Inspector',
  'Chief Jailer',
  'Chief Knowledge Officer',
  'Chief Learning Officer',
  'Chief Lifestyle Officer',
  'Chief Mechanical Officer (CMO)',
  'Chief Medical Officer',
  'Chief Meteorologist',
  'Chief Nuclear Medicine Technologist',
  'Chief Nurse Executive',
  'Chief Nursing Officer',
  'Chief of Hospital Medicine',
  'Chief of Planning',
  'Chief of Police',
  'Chief of Safety and Protection',
  'Chief of Staff',
  'Chief Operating Officer (COO)',
  'Chief Operator',
  'Chief Passenger Ship Steward/Stewardess',
  'Chief Pilot',
  'Chief Program Officer',
  'Chief Radiation Therapist (Chief RT)',
  'Chief Risk Officer',
  'Chief Safety Officer',
  'Chief School Finance Officer',
  'Chief Scientific Officer',
  'Chief Security and Safety Officer (CSO)',
  'Chief Security Officer (CSO)',
  'Chief Service Dispatcher',
  'Chief Specialist, LEED (Chief Specialist, Leadership in Energy and Environmental Design)',
  'Chief Technical Officer',
  'Chief Technology Officer',
  'Chief Technology Officer (CTO)',
  'Chief Wellness Officer',
  'Chief Writer',
  'Child & Adolescent Psychiatrist',
  'Child Advocate',
  'Child and Adolescent Therapist',
  'Child and Family Counselor',
  'Child and Family Services Worker',
  'Child Care Assistant',
  'Child Care Center Administrator',
  'Child Care Center Director',
  'Childcare Provider',
  'Child Care Provider',
  'Childcare Worker',
  'Child Care Worker',
  'Child Life Specialist',
  'Child Protective Investigator',
  'Child Psychiatrist',
  "Children's Attendant",
  "Children's Author",
  "Children's Librarian",
  "Children's Ministries Director",
  "Children's Ministry Director",
  'Child Study Team Director',
  'Child Support Agent',
  'Child Welfare Social Worker',
  'Child Welfare Specialist',
  'Chimney Sweeper',
  'China Painter',
  'Chinese Instructor',
  'Chinese Teacher',
  'Chin Strap Cutter',
  'Chip Person',
  'Chiropodist',
  'Chiropractic Care',
  'Chiropractic Doctor (DC)',
  'Chiropractic Neurologist',
  'Chiropractic Physician',
  'Chiropractor',
  'Chlorinator',
  'Chocolate and Cocoa Processing Supervisor',
  'Chocolate Temperer',
  'Chocolatier',
  'Choir Director',
  'Choirmaster',
  'Chop Saw Operator',
  'Choral Director',
  'Christian Counselor',
  'Christmas Tree Farm Manager',
  'Chrome Polisher',
  'Chromium Plater',
  'Chronic Disease Manager',
  'Church Communications Administrator',
  'Church Organist',
  'Church Worker',
  'CIA Agent (Central Intelligence Agency Agent)',
  'Cigarette Seller',
  'Cigarette Stamper',
  'Cigar Roller',
  'Cinema Operator',
  'Cinematographer',
  'Circle Beveler',
  'Circuit Design Engineer',
  'Circuit Rider',
  'Circuits Engineer',
  'Circulation Assistant',
  'Circulation Clerk',
  'Circulation Director',
  'Circulation Manager',
  'Circus Clown',
  'Cisco Certified Internetwork Expert (CCIE)',
  'Cisco Certified Network Professional (CCNP)',
  'Citrus Picker',
  'City Attorney',
  'City Auditor',
  'City Clerk',
  'City Comptroller',
  'City Councilman',
  'City Council Member',
  'City Designer',
  'City Editor',
  'City Engineer',
  'City Letter Carrier',
  'City Manager',
  'City Recorder',
  'City Superintendent of Schools',
  'City Surveyor',
  'City Treasurer',
  'City Wellness Coordinator',
  'Civil and Environmental Engineering Librarian',
  'Civil Designer',
  'Civil Division Deputy Sheriff',
  'Civil Engineer',
  'Civil Engineering Manager',
  'Civil Engineering Technician',
  'Civilian Technician',
  'Claim Examiner',
  'Claim Representative',
  'Claims Adjuster',
  'Claims Analyst',
  'Claims Assistant',
  'Claims Consultant',
  'Claims Director',
  'Claims Examiner',
  'Claims Manager',
  'Claim Specialist',
  'Claims Representative',
  'Claims Specialist',
  'Claims Supervisor',
  'Claims Technician',
  'Claims Vice President',
  'Clairvoyant',
  'Clam Digger',
  'Clam Shucker',
  'Clarinetist',
  'Clark Driver',
  'Class B Driver',
  'Classics Professor',
  'Classifications Officer CC/CM',
  'Classified Advertising Supervisor',
  'Classroom Aide',
  'Classroom Teacher',
  'Clay Modeler',
  'Clean Energy Policy Analyst',
  'Cleaner',
  'Cleaning and Maintenance Worker',
  'Cleaning and Washing Equipment Operator',
  'Cleaning Handyman',
  'Cleaning Supervisor',
  'Clean in Places Operator (CIP Operator)',
  'Clean Up Crew Worker',
  'Clearance Cutter',
  'Clearing Supervisor',
  'Clerical Aide',
  'Clerical Assigner',
  'Clerical Assistant',
  'Clerical Office Worker',
  'Clerk',
  'Clerk of Superior Court',
  'Clerk Typist',
  'C Level Crew Chief',
  'Client Administrator',
  'Client Associate',
  'Client Manager',
  'Client Relations Specialist',
  'Client Service and Consulting Manager',
  'Client Service Associate',
  'Client Service Coordinator',
  'Client Service Representative',
  'Client Services Director',
  'Client Services Manager',
  'Client Services Representative',
  'Client Service Supervisor',
  'Client Services Vice President',
  'Client Support Professional',
  'Climate Change Risk Assessor',
  'Climatologist',
  'Clinical Analyst',
  'Clinical Appeals Reviewer',
  'Clinical Applications Specialist',
  'Clinical Assistant',
  'Clinical Audiologist',
  'Clinical Biostatistics Director',
  'Clinical Coordinator',
  'Clinical Coordinator of Respiratory Therapy',
  'Clinical Coordinator, Pediatric Genetics',
  'Clinical Cytogeneticist Scientist (CCS)',
  'Clinical Data Analyst',
  'Clinical Data Coordinator',
  'Clinical Data Management Associate Director',
  'Clinical Data Manager',
  'Clinical Data Specialist',
  'Clinical Dietitian',
  'Clinical Director',
  'Clinical Documentation Consultant',
  'Clinical Documentation Improvement Specialist (CDIS)',
  'Clinical Education for Athletic Training Coordinator',
  'Clinical Educator',
  'Clinical Engineer',
  'Clinical Engineering Director',
  'Clinical Fellow',
  'Clinical Informatics Specialist',
  'Clinical Information Systems Director',
  'Clinical Instructor',
  'Clinical Instructor in Special Education',
  'Clinical Laboratory Manager',
  'Clinical Laboratory Medical Director',
  'Clinical Laboratory Science Professor',
  'Clinical Laboratory Scientist',
  'Clinical Liaison',
  'Clinical Manager',
  'Clinical Mental Health Counselor',
  'Clinical Microbiologist',
  'Clinical Molecular Geneticist',
  'Clinical Molecular Genetics Laboratory Director',
  'Clinical Nurse',
  'Clinical Nurse Educator',
  'Clinical Nurse Specialist',
  'Clinical Nutritionist',
  'Clinical Operations Specialist',
  'Clinical Pharmacist',
  'Clinical Professor',
  'Clinical Program Coordinator',
  'Clinical Program Manager',
  'Clinical Project Manager',
  'Clinical Psychologist',
  'Clinical Psychologist-Licensed',
  'Clinical Psychologist, Private Practice',
  'Clinical Psychology Professor',
  'Clinical Quality Assurance Associate',
  'Clinical Quality Assurance Specialist',
  'Clinical Research Analyst',
  'Clinical Research Assistant',
  'Clinical Research Associate',
  'Clinical Research Associate (CRA)',
  'Clinical Research Coordinator',
  'Clinical Research Director',
  'Clinical Research Manager',
  'Clinical Research Nurse Coordinator',
  'Clinical Services Director',
  'Clinical Site Coordinator and Supervisor',
  'Clinical Social Worker',
  'Clinical Specialist',
  'Clinical Statistical Programmer',
  'Clinical Study Manager',
  'Clinical Supervisor',
  'Clinical Supervisor, Epilepsy Monitoring Unit',
  'Clinical Support Associate',
  'Clinical Support Specialist',
  'Clinical Therapist',
  'Clinical Trial Associate',
  'Clinical Trial Coordinator',
  'Clinical Trial Manager',
  'Clinical Trials Data Coordinator',
  'Clinical Trials Manager',
  'Clinical Trials Systems Administrator',
  'Clinic Coordinator',
  'Clinic Director',
  'Clinician',
  'Clinic MD Associate (Clinic Medical Doctor Associate)',
  'Clinic Nurse',
  'Clockmaker',
  'Clock Smith',
  'Closer',
  'Closet Organizer',
  'Closing Agent',
  'Closing Coordinator',
  'Cloth Doffer',
  'Clothes Model',
  'Clothier',
  'Clothing and Textiles Teacher',
  'Clothing Man',
  'Cloth Measurer',
  'Cloth Reeler',
  'Cloud Physicist',
  'Club Attendant',
  'Clubhouse Manager',
  'Club Manager',
  'Clutch Rebuilder',
  'CNC Machinist (Computer Numerically Controlled Machinist)',
  'CNC Process Control Programmer',
  'Coach',
  'Coach (Career Transition and Performance)',
  'Coal Miner',
  'Coal Shooter',
  'Coastal and Estuary Specialist',
  'Coat Agent',
  'Coating and Baking Operator',
  'Coating Inspector',
  'COBOL Programmer (Common Business Oriented Language Programmer)',
  'Cob Sawyer',
  'Cocktail Lounge Manager',
  'Cocktail Server',
  'Code and Test Clerk',
  'Code Inspector',
  'Code Official',
  'Coder',
  'Codifier',
  'Coding Clerk',
  'Co-Director of Sports Medicine and Team Physician',
  'Coffee Attendant',
  'Coffee Roaster',
  'Coffee Supervisor',
  'Coin Collector',
  'Coke Loader',
  'Cold Storage Supervisor',
  'Cold Type Composing Machine Operator',
  'Collaborative Teacher',
  'Collateral and Safekeeping Clerk',
  'Collateral Clerk',
  'Collection Agent',
  'Collection and Distribution Systems Manager',
  'Collection Manager',
  'Collections and Archives Director',
  'Collections Clerk',
  'Collections Curator',
  'Collections Manager',
  'Collections Officer',
  'Collection Specialist',
  'Collections Representative',
  'Collections Specialist',
  'Collection Systems Modeler',
  'Collective Bargaining Specialist',
  'Collector',
  'Collector of Internal Revenue',
  'College Admissions Counselor',
  'College Athlete',
  'College Coach',
  'College Counselor',
  'College Dean',
  'College Director',
  'College Instructor',
  'College of Education Dean',
  'College or University Business Manager',
  'College or University Faculty Member',
  'College or University Registrar',
  'College Physics Instructor',
  'College President',
  'College Professor',
  'College Recruiter',
  'College Scouting Coordinator',
  'Color and Materials Designer',
  'Colorectal Surgeon',
  'Color Expert',
  'Colorist Formulator',
  'Color or Color Laboratory Technician',
  'Columnist',
  'Combat Direction Finding System (An/Srs-1) Operator',
  'Combat Engineer',
  'Combat Information Center Officer',
  'Combination Man',
  'Combustion Engineer',
  'Comedian',
  'Comic Book Artist',
  'Command And Control',
  'Command And Control Systems Integrator',
  'Commandant',
  'Commentator',
  'Commercial Agent',
  'Commercial Airline Pilot',
  'Commercial Appraiser',
  'Commercial Banker',
  'Commercial Correspondent',
  'Commercial Credit Head',
  'Commercial Credit Lead',
  'Commercial Credit Reviewer',
  'Commercial Customer Service Representative',
  'Commercial Director',
  'Commercial Diver',
  'Commercial Energy Auditor',
  'Commercial Floor Covering Installer',
  'Commercial Interior Designer',
  'Commercial Lender',
  'Commercial Lending Vice President',
  'Commercial Lines Account Manager',
  'Commercial Lines Assistant',
  'Commercial Lines Underwriter',
  'Commercial Loan Collection Officer',
  'Commercial Loan Officer',
  'Commercial Loan Reviewer',
  'Commercial Manager',
  'Commercial Mortgage Broker',
  'Commercial Parts Professional',
  'Commercial Photographer',
  'Commercial Pilot',
  'Commercial Producer',
  'Commercial Production Editor',
  'Commercial Property Manager',
  'Commercial Property Services Coordinator',
  'Commercial Real Estate Appraiser',
  'Commercial Representative',
  'Commercial Sales Manager',
  'Commercial Solar Sales Consultant',
  'Commercial Specialist',
  'Commercial Subcontractor',
  'Commercial Trailer Truck Driver',
  'Commercial Underwriter',
  'Commerical Artist',
  'Commission Broker',
  'Commissioner',
  'Commissioner of Internal Revenue',
  'Commissioning Engineer',
  'Commission Sales Associate',
  'Commodities Broker',
  'Commodities Trader',
  'Commodity Buyer',
  'Commodity Manager',
  'Commodity Trader',
  'Commonwealth Attorney',
  'Communication Assistant',
  'Communication Center Coordinator',
  'Communication Coordinator',
  'Communication Equipment Sales Representative',
  'Communication Lecturer',
  'Communication Manager',
  'Communications Analyst',
  'Communications and Signals Supervisor',
  'Communications Assistant',
  'Communications Associate',
  'Communications Consultant',
  'Communications Coordinator',
  'Communications Department Chair',
  'Communications Department Head',
  'Communications Director',
  'Communications Editor',
  'Communications Equipment Installer',
  'Communications Maintainer',
  'Communications Manager',
  'Communications Officer',
  'Communication Specialist',
  'Communications Specialist',
  'Communications Supervisor',
  'Communications Technician',
  'Communicator',
  'Community Advocate',
  'Community Association Manager',
  'Community-Based Public Artist',
  'Community Center Director',
  'Community Coordinator',
  'Community Development Director',
  'Community Development Technician',
  'Community Educator',
  'Community Health Advisor',
  'Community Health Consultant',
  'Community Health Education Coordinator',
  'Community Health Educator',
  'Community Health Nursing Director',
  'Community Health Nursing Educational Director',
  'Community Health Outreach Worker',
  'Community Health Planning Director',
  'Community Health Program Coordinator',
  'Community Health Representative',
  'Community Liaison',
  'Community Life Director',
  'Community Manager',
  'Community Music Therapist',
  'Community Organization Director',
  'Community Organizer',
  'Community Outreach Coordinator',
  'Community Program Assistant',
  'Community Relations Coordinator',
  'Community Relations Director',
  'Community Relations Manager',
  'Community Resource Consultant',
  'Community Resource Officer',
  'Community Services Officer',
  'Community Support Specialist',
  'Community Support Worker',
  'Community Worker',
  'Commuter Pilot',
  'Compact Assembler',
  'Companion',
  'Company Dancer',
  'Company Driver',
  'Company Laborer',
  'Compass Operator',
  'Compensation Analyst',
  'Compensation and Benefits Analyst',
  'Compensation / Benefits Specialist',
  'Compensation Consultant',
  'Compensation Coordinator',
  'Compensation Director',
  'Compensation Expert',
  'Compensation Manager',
  'Compensation Specialist',
  'Competency Evaluated Nurse Aide (CENA)',
  'Competitive Athlete',
  'Competitive Intelligence Analyst',
  'Competitive Shopper',
  'Competitor',
  'Compiler',
  'Complaint Inspector',
  'Completion Engineer',
  'Completions Engineer',
  'Compliance Analyst',
  'Compliance Auditor',
  'Compliance Coordinator',
  'Compliance Director',
  'Compliance Examiner',
  'Compliance Manager',
  'Compliance Officer',
  'Compliance Specialist',
  'Compliance Vice President',
  'Component Assembler',
  'Composer',
  'Composite Manufacturer',
  'Composition Weatherboard Applier',
  'Compositor',
  'Compounder',
  'Compound Mixer',
  'Comprehensive Advisor',
  'Comprehensive Ophthalmologist',
  'Compressor',
  'Comptroller',
  'Computational Biologist',
  'Computational Linguist',
  'Computed Tomography/Magnetic Resonance Imaging Technologist (CT/MRI Technologist)',
  'Computed Tomography Technologist (CT Technologist)',
  'Computer-Aided Design Designer (CAD Designer)',
  'Computer-Aided Design Operator (CAD Operator)',
  'Computer-Aided Design Technician (CAD Technician)',
  'Computer Architect',
  'Computer Assistant',
  'Computer Consultant',
  'Computer Customer Support Specialist',
  'Computer Engineer',
  'Computer Forensic Examiner',
  'Computer Graphics Illustrator',
  'Computer Hardware Developer',
  'Computer Hardware Technician',
  'Computer Help Desk Representative',
  'Computer Information Science Professor',
  'Computer Information Systems Department Chair',
  'Computer Information Systems Instructor (CIS Instructor)',
  'Computer Information Technology Instructor',
  'Computer Instructor',
  'Computer Integrated Manufacturing Operator (CIM Operator)',
  'Computerized Forwarding System Supervisor',
  'Computer Lab Assistant',
  'Computer Networker',
  'Computer Networking Instructor',
  'Computer Operations Manager',
  'Computer Operations Supervisor',
  'Computer Operator',
  'Computer or Computer Laboratory Technician',
  'Computer Peripheral Equipment Operator',
  'Computer Programmer',
  'Computer Programmer Analyst',
  'Computer Project Manager',
  'Computer Repair Technician',
  'Computer Scientist',
  'Computer Security Coordinator',
  'Computer Security Specialist',
  'Computer Specialist',
  'Computer Support Specialist',
  'Computer Support Technician',
  'Computer Systems Administrator',
  'Computer Systems Design Analyst',
  'Computer Systems Director of Information',
  'Computer Systems Hardware Analyst',
  'Computer Systems Manager',
  'Computer Systems Security Administrator',
  'Computer Systems Software Architect',
  'Computer Systems Software Engineer',
  'Computer Systems Support Specialist',
  'Computer System Technician',
  'Computer Teacher',
  'Computer Technician',
  'Computing Services Director',
  'Computing Tutor',
  'Com Writer',
  'Concept Artist',
  'Concert Manager',
  'Concert Promoter',
  'Concessionaire',
  'Concierge',
  'Conciliator',
  'Concrete Craftsman',
  'Concrete Finisher',
  'Concrete Pavement Installer',
  'Concrete Worker',
  'Condenser Winder',
  'Conditioner',
  'Conductor',
  'Conductor/Engineer',
  'Cone Picker',
  'Conference Director',
  'Conference Interpreter',
  'Conference Manager',
  'Conference Planner',
  'Conference Producer',
  'Conference Service Coordinator',
  'Conferences or Conventions Reservationist',
  'Confessor',
  'Confidential Secretary',
  'Configuration Management Analyst',
  'Configuration Manager',
  'Congregational Care Pastor',
  'Congressional Assistant',
  'Congressman',
  'Connection Worker',
  'Connector',
  'Conservation Assistant',
  'Conservationist',
  'Conservation Officer',
  'Conservation Planner',
  'Consignee',
  'Console Operator',
  'Consolidator',
  'Constable',
  'Construction Area Manager',
  'Construction Code Administrator',
  'Construction Consultant',
  'Construction Coordinator',
  'Construction Director',
  'Construction Engineer',
  'Construction Estimator',
  'Construction Foreman',
  'Construction Framer',
  'Construction Health and Safety Technician',
  'Construction Inspector',
  'Construction Job Cost Estimator',
  'Construction Laborer',
  'Construction Management Assistant',
  'Construction Manager',
  'Construction Materials Testing Technician',
  'Construction Project Manager',
  'Construction Skills Teacher',
  'Construction Superintendent',
  'Construction Supervisor',
  'Construction Technology Instructor',
  'Construction Worker',
  'Constructor',
  'Consultant',
  'Consultant/Associate Professor of Biostatistics',
  'Consultant in Ergonomics and Safety',
  'Consultant, Strategic Business and Technology Intelligence',
  'Consulting Actuary',
  'Consulting Engineer',
  'Consulting Hr Professional',
  'Consulting Nurse',
  'Consulting Property Manager',
  'Consumer Advocate',
  'Consumer Affairs Director',
  'Consumer Credit Counselor',
  'Consumer Education Specialist',
  'Consumer Loan Officer',
  'Consumer Recruiter',
  'Consumer Studies Professor',
  'Contact Agent',
  'Contact and Service Clerks Supervisor',
  'Contact Officer',
  'Contact Person',
  'Container Coordinator',
  'Containers Sales Representative',
  'Contemporary English Literature Professor',
  'Content Coordinator',
  'Content Curator',
  'Content Editor',
  'Content Manager',
  'Content Producer',
  'Content Specialist',
  'Content Strategist',
  'Continuing Education Dean',
  'Continuing Education Director',
  'Continuous Improvement Manager',
  'Continuous Improvement Specialist',
  'Continuous Miner Operator (CMO)',
  'Continuous Mining Machine Company Miner',
  'Contortionist',
  'Contract Administrator',
  'Contract Analyst',
  'Contract Coordinator',
  'Contracting Manager',
  'Contracting Officer',
  'Contract Manager',
  'Contract Negotiator',
  'Contractor',
  'Contract Preparer',
  'Contract Recruiter',
  'Contracts Administrator',
  'Contracts Director',
  'Contract Sheltered Workshop Supervisor',
  'Contracts Law Professor',
  'Contracts Manager',
  'Contract Specialist',
  'Contracts Specialist',
  'Contract Writer',
  'Control Engineer',
  'Controller',
  'Controller, Operations, and HR Manager',
  'Control Room Agent',
  'Control Room Operator',
  'Control Room Operator (CRO)',
  'Controls Engineer',
  'Control Specialist',
  'Control Systems Engineer',
  'Control Tower Operator',
  'Convenience Recycle Center Tech',
  'Convenience Store Manager',
  'Conventional Machinist',
  'Convention Manager',
  'Convention Services Manager (CSM)',
  'Conversion Man',
  'Converter Skimmer',
  'Converting Supervisor',
  'Cook',
  'Cook at School',
  'Cookie Padder',
  'Cooking Chef',
  'Cook Manager',
  'Cooling Tower Technician',
  'Cool Roofing Installer',
  'Cooper',
  'Co-op Librarian',
  'Coordinator',
  'Coordinator, Chronic Disease Prevention Program',
  'Coordinator Mining Products',
  'Coordinator of Learning Resources',
  'Coordinator of Library Services',
  'Coordinator of Online Programs',
  'Coordinator, Tobacco-Free Wellness Center',
  'Coordinator, Tobacco Prevention and Cessation Coalition',
  'Cop',
  'Co Pilot',
  'Copper',
  'Coppersmith',
  'Copy and Print Associate',
  'Copy Center Operator',
  'Copy Coordinator',
  'Copy Editor',
  'Copyholder',
  'Copyist',
  'Copy Reader',
  'Copyright Expert',
  'Copywriter',
  'Cordwainer',
  'Core Analysis Operator',
  'Core Analyst',
  'Core Inspector',
  'Core Winder',
  'Corner Former',
  'Cornetist',
  'Corn Popper',
  'Coronary Care Unit Nurse (CCU Nurse)',
  'Coroner/Medical Examiner',
  'Corporate Accountant',
  'Corporate Administrator',
  'Corporate Attorney',
  'Corporate Banking Officer',
  'Corporate Communications Specialist',
  'Corporate Compliance Director',
  'Corporate Compliance Officer',
  'Corporate Controller',
  'Corporate Counsel',
  'Corporate Director, Talent Assessment',
  'Corporate Event Planner',
  'Corporate Executive',
  'Corporate Executive Chef',
  'Corporate Financial Analyst',
  'Corporate Fitness Program Coordinator',
  'Corporate Law Assistant',
  'Corporate Lawyer',
  'Corporate Legal Assistant',
  'Corporate Manager',
  'Corporate Meeting Planner',
  'Corporate Officer',
  'Corporate Operations Compliance Manager',
  'Corporate Paralegal',
  'Corporate Physical Security Supervisor',
  'Corporate Recruiter',
  'Corporate Safety Director',
  'Corporate Secretary',
  'Corporate Securities Research Analyst',
  'Corporate Security Manager',
  'Corporate Sustainability Process Manager',
  'Corporate Tax Preparer',
  'Corporate Trainer',
  'Corporate Travel Agent',
  'Corporate Travel Consultant',
  'Corporate Travel Expert',
  'Corporation Counsel',
  'Corporation Lawyer',
  'Corporation Officer',
  'Correctional Corporal',
  'Correctional Facility Industries Superintendent',
  'Correctional Leisure Activities Specialist',
  'Correctional Officer',
  'Correction Officer',
  'Corrections Emergency Response Team Sergeant (CERT Sergeant)',
  'Corrections Officer (CO)',
  'Corrections Sergeant',
  'Correspondence Dictator',
  'Correspondent',
  'Corridor Redevelopment Manager',
  'Corrosion Control Fitter',
  'Corrosion Engineer',
  'Corrugator',
  'Corsetier',
  'Cosmetic Consultant',
  'Cosmetic Dentist',
  'Cosmetics Supervisor',
  'Cosmetic Surgeon',
  'Cosmetologist',
  'Cost Accountant',
  'Cost Analyst',
  'Cost Controller',
  'Cost Control Supervisor',
  'Cost Engineer',
  'Cost Estimating Engineer',
  'Cost Estimator',
  'Cost Recovery/Investment Recovery Technician',
  'Costume Cutter',
  'Costume Designer',
  'Co-Supervisor Grounds and Landscape',
  'Co-Teacher',
  'Cotton Factor',
  'Cotton Opener',
  'Councilman',
  'Council Member',
  'Council On Aging Director',
  'Councilperson',
  'Counsel',
  'Counseling Aide',
  'Counseling Program Leader',
  'Counselor',
  'Counselor at Law',
  'Counter',
  'Countersinker',
  'Country Singer',
  'County Administrator',
  'County Assessor',
  'County Auditor',
  'County Commissioner',
  'County Law Library Coordinator',
  'County Sheriff',
  'County Superintendent of Schools',
  'County Supervisor',
  'County Surveyor',
  'County Treasurer',
  'Couple and Family Therapist, Staff Therapist',
  'Couples Therapist',
  'Coupon Clerk',
  'Courier',
  'Course Developer',
  'Courseware Developer',
  'Court Administrator',
  'Court Clerk',
  'Court Deputy',
  'Courtesy Clerk',
  'Court Operations Clerk',
  'Court Reporter',
  'Court Security Officer',
  'Court Specialist',
  'Court Stenographer',
  'Couture Dressmaker',
  'Couturiere',
  'Cover Stitch Machine Operator',
  'Cowboy',
  'Cowpuncher',
  'Cpr Instructor',
  'CPS Team Lead (Chip Placement Solution Team Lead)',
  'Craft Center Director',
  'Craft Coordinator, (Estimator, Manage, Plan, Schedule, Hire and Fire)',
  'Craft Demonstrator',
  'Crane Follower',
  'Crane Mechanic',
  'Crane Operator',
  'Crankshaft Grinder',
  'Crash, Fire, and Rescue Fire Fighter',
  'Crate Builder',
  'Creative/Art Director',
  'Creative Arts Music Therapist',
  'Creative Arts Therapist',
  'Creative Designer',
  'Creative Director',
  'Creative Guru',
  'Creative Manager',
  'Creative Services Coordinator',
  'Creative Services Director',
  'Creative Services Manager',
  'Creative Services Producer',
  'Creative Writer',
  'Creative Writing English Professor',
  'Credentialing Coordinator',
  'Credentialing Specialist',
  'Credentials Specialist',
  'Credit Administration Manager',
  'Credit Administrator',
  'Credit Analyst',
  'Credit and Collection Manager',
  'Credit and Collections Analyst',
  'Credit and Loan Collections Supervisor',
  'Credit Assistant',
  'Credit Balance Specialist',
  'Credit Card Clerk',
  'Credit Card Control Clerk',
  'Credit Collections Manager',
  'Credit Coordinator',
  'Credit Director',
  'Credit Manager',
  'Credit Office Manager',
  'Credit Officer',
  'Credit Review Officer',
  'Credit Risk Analyst',
  'Credit Risk Management Director',
  'Credit Risk Officer',
  'Credit Risk Review Officer',
  'Credit Specialist',
  'Credit Union Examiner',
  'Credit Union Field Examiner',
  'Credit Union Manager',
  'Crew Chief',
  'Crew Chief Ii',
  'Crew Lead',
  'Crew Leader',
  'Crew Manager',
  'Crew Member',
  'Crew Trainer',
  'Crime Analyst',
  'Crime Scene Investigator (CSI)',
  'Criminal Investigations Division Investigator',
  'Criminalist',
  'Criminal Justice Instructor',
  'Criminal Justice Lawyer',
  'Criminal Lawyer',
  'Criminologist',
  'Criminology Professor',
  'Crisis Intervention Counselor',
  'Crisis Intervention Specialist',
  'Crisis Worker',
  'Critic',
  'Critical Care Clinical Nurse Specialist',
  'Critical Care Nurse',
  'Critical Care Nurse Specialist',
  'Critical Care Registered Nurse (CCRN)',
  'Critical Care Unit Manager',
  'CRM Developer (Customer Relationship Management Developer)',
  'Crop Puller',
  'Crop Specialist',
  'Cross Country and Track and Field Coach',
  'Cross Country Truck Driver',
  'Croupier',
  'Crown Ceramist',
  'Crude Oil Treater',
  'Crude Tester',
  'Cruise Director',
  'Crumb Packer',
  'Crusher',
  'Cryptanalyst',
  'Cryptographic Vulnerability Analyst',
  'Cryptologic Digital Network Technician/Analyst',
  'Cryptologist',
  'Cryptozoologist',
  'Crystal Calibrator',
  'Crystallizer Operator',
  'Crystallographer',
  'Culinary Chef',
  'Cultivator',
  'Cultural Anthropology Professor',
  'Cultured Marble Products Maker',
  'Culture Media Laboratory Assistant',
  'Cup Operator',
  'Curate',
  'Curator',
  'Curatorial Assistant',
  'Curator of Collections',
  'Curator of Photography and Prints',
  'Curb Machine Operator',
  'Currency Counter',
  'Curriculum and Assessment Coordinator',
  'Curriculum and Assessment Director',
  'Curriculum and Instruction Director',
  'Curriculum Coordinator',
  'Curriculum Designer',
  'Curriculum Developer',
  'Curriculum Director',
  'Curriculum Facilitator',
  'Curriculum Manager',
  'Curriculum Specialist',
  'Curve Cleaner',
  'Cushion Sewer',
  'Custodial Manager',
  'Custodial Supervisor',
  'Custodian',
  'Custody Assistant',
  'Custom Designer',
  'Customer Account Specialist',
  'Customer Account Technician',
  'Customer Advocate',
  'Customer Agent',
  'Customer Assistant',
  'Customer Care Manager',
  'Customer Care Representative (CCR)',
  'Customer Care Specialist',
  'Customer Complaint Service Supervisor',
  'Customer Contact Specialist',
  'Customer Data Technician',
  'Customer Engagement Manager',
  'Customer Engineer',
  'Customer Leader',
  'Customer Liaison',
  'Customer Logistics Manager',
  'Customer Program Manager',
  'Customer Relationship Specialist',
  'Customer Relations Representative',
  'Customer Service Administrator',
  'Customer Service Agent',
  'Customer Service Assistant',
  'Customer Service Associate',
  'Customer Service Associate (CSA)',
  'Customer Service Clerk',
  'Customer Service Consultant',
  'Customer Service Coordinator',
  'Customer Service Engineer',
  'Customer Service Manager',
  'Customer Service Officer',
  'Customer Service Representative',
  'Customer Service Representative (CSR)',
  'Customer Service Representative (Customer Service Rep)',
  'Customer Services Coordinator',
  'Customer Service Security Officer',
  'Customer Service Specialist',
  'Customer Service Supervisor',
  'Customer Support Analyst',
  'Customer Support Engineer',
  'Customer Support Executive',
  'Customer Support Information Technology Specialist',
  'Customer Support Manager',
  'Customer Support Professional',
  'Customer Support Representative',
  'Custom Leather Products Maker',
  'Customs Broker',
  'Customs Compliance Manager',
  'Customs Import Specialist',
  'Customs Officer',
  'Customs Port Director',
  'Cut Filer',
  'Cutter Grind Tool Technician',
  'Cutting and Printing Machine Operator',
  'Cv/Cvn Cv-Tsc System Operator',
  'Cyberathlete',
  'Cyber Forensic Specialist',
  'Cyber Security Engineer',
  'Cyber Security Specialist',
  'Cycle Counter',
  'Cylinder Grinder',
  'Cytologist',
  'Cytopathologist',
  'Cytotechnologist',
  'Dairy and Food Laboratory Assistant',
  'Dairy Manager',
  'Dampproofer',
  'Dance Director',
  'Dance Instructor',
  'Dancer',
  'Dance Studio Manager',
  'Dance Teacher',
  'Data Abstractor',
  'Data Acquisition Technician',
  'Data Analysis Assistant',
  'Data Analyst',
  'Data Architect',
  'Database Administration Project Manager',
  'Database Administrator (DBA)',
  'Database Analyst',
  'Database Architect',
  'Database Coordinator',
  'Database Design Analyst',
  'Database Developer',
  'Database Development and Administration Project Manager',
  'Database Development Project Manager',
  'Database Engineer',
  'Database Manager',
  'Database Marketing Analyst',
  'Database Modeler',
  'Database Security Expert',
  'Database Specialist',
  'Databases Software Consultant',
  'Data Capture Clerk',
  'Data Capture Specialist',
  'Data Center Manager',
  'Data Center Operator',
  'Data Collector',
  'Data Communications Software Consultant',
  'Data Conversion Operator',
  'Data Coordinator',
  'Data Entry Associate',
  'Data Entry Clerk',
  'Data Entry Machine Operator',
  'Data Entry Operator',
  'Data Entry Specialist',
  'Data Management Associate',
  'Data Management Information Technology Specialist',
  'Data Management Manager',
  'Data Manager',
  'Data Modeler',
  'Data Modeling Specialist',
  'Data Officer',
  'Data Operations Director',
  'Data Processing Auditor',
  'Data Processing Consultant',
  'Data Processing Control Clerk',
  'Data Processing Services Sales Representative',
  'Data Processing Systems Analyst',
  'Data Processing Systems Project Planner',
  'Data Processor',
  'Data Recovery Planner',
  'Data Reviewer',
  'Data Scientist',
  'Data Security Administrator',
  'Data Security Analyst',
  'Data Specialist',
  'Data Storage Specialist',
  'Data Systems Manager',
  'Data Technician',
  'Data Warehouse Analyst',
  'Data Warehouse Architect',
  'Data Warehouse Developer',
  'Data Warehouse Specialist',
  'Data Warehousing Engineer',
  'Data Warehousing Manager',
  'Date Pitter',
  'Day Care Assistant',
  'Day Care Home Mother',
  'Day Care Home Provider',
  'Daycare Manager',
  'Daycare Provider',
  'Daycare Teacher',
  'Day Light Relief Operator',
  'Day Spa Manager',
  'Day Worker',
  'Deaconess',
  'Deaf/Hard of Hearing Specialist',
  'De-Alcholizer',
  'Dealer',
  'Dealership Manager',
  'Dealer Support Technician',
  'Dean',
  'Dean of Girls',
  'Dean of Men',
  'Dean of Students',
  'Dean of Student Services',
  'Dean of Women',
  'Debate Director',
  'Debit Agent',
  'Deboner',
  'Debrander',
  'Debt and Budget Counselor',
  'Debt Collector',
  'Debt Management Counselor',
  'Deburrer',
  'Decision Analyst',
  'Decision Support Analyst',
  'Deck Cadet',
  'Decker',
  'Deckhand',
  'Deck Lid Fitter',
  'Deck Officer',
  'Decorator',
  'Deejay',
  'Deep Fat Fry Cook',
  'Deep Well Contractor',
  'Deer Hunter',
  'Defect Cutter',
  'Defense Analyst',
  'Defense Attorney',
  'Defensive Driving Instructor',
  'Degreaser',
  'Dehydrator',
  'Deicer Finisher',
  'Delegate',
  'Deli/Bakery Associate',
  'Deli Clerk (Delicatessen Clerk)',
  'Deli Manager',
  'Delineator',
  'Delinquency Prevention Social Worker',
  'Deliver Driver',
  'Delivery and Installation Subcontractor',
  'Delivery Driver',
  'Delivery Driver/Customer Service',
  'Delivery Manager',
  'Delivery of Shopping News',
  'Delivery Supervisor',
  'Deli Worker (Delicatessen Worker)',
  'Delta System Freight Car Cleaner',
  'Demand Generation Manager',
  'Demand Planner',
  'Demographer',
  'Demolitionist',
  'Demonstrator',
  'Dental Assistant (DA)',
  'Dental Detail Representative',
  'Dental Hygienist',
  'Dental, Mental, and School Community Health Aide',
  'Dental Nurse',
  'Dental Officer',
  'Dental Service Chief',
  'Dental Surgeon',
  'Dental Surgery Doctor (DDS)',
  'Dental Technician (Dental Tech)',
  'Dentist',
  'Dentist/Owner',
  'Dentistry Professor',
  'Deodorizer',
  'Departmental Buyer',
  'Departmental Secretary',
  'Department Chair',
  'Department Director',
  'Department Head',
  'Department Manager',
  'Department Sales Manager',
  'Department Secretary',
  'Department Store General Manager',
  'Department Supervisor',
  'Deployment Engineer',
  'Deposit Clerk',
  'Depot Agent',
  'Deputy',
  'Deputy Chief Counsel',
  'Deputy Clerk',
  'Deputy Clerk of Superior Court',
  'Deputy Commissioner',
  'Deputy Director',
  'Deputy Director of Clinical Specialty Services',
  'Deputy Fire Marshal',
  'Deputy Insurance Commissioner',
  'Deputy Jailer',
  'Deputy Sheriff',
  'Deputy Treasurer',
  'Deputy United States Marshal',
  'Dermatologist',
  'Dermatology Teacher',
  'Dermatopathologist',
  'Derrickman',
  'Derrick Man',
  'Design Analyst',
  'Design and Sales Consultant',
  'Design/Animation Instructor',
  'Design Architect',
  'Design Assistant',
  'Designated Broker',
  'Design Cell Engineer',
  'Design Chief',
  'Design Consultant',
  'Design Director',
  'Design Drafter',
  'Design Draftsman',
  'Design Editor',
  'Design Engineer',
  'Design Engineering Manager',
  'Designer',
  'Designer/Writer',
  'Design Manager',
  'Design Technician',
  'Desk Officer',
  'Desk Reporter',
  'Desktop Analyst',
  'Desktop Operator',
  'Desktop Publishing Associate',
  'Desktop Support Analyst',
  'Desktop Support Engineer',
  'Desktop Support Specialist',
  'Desktop Support Technician',
  'Desktop Technician',
  'Destination Coordinator',
  'Detail',
  'Detailer',
  'Detasseler',
  'Detective',
  'Detective Lieutenant',
  'Detention Officer',
  'Developer',
  'Developer Advocate',
  'Developmental Diabetes Secure Care Treatment Aide (DDSCTA)',
  'Developmental Education Instructor',
  'Developmental Therapist',
  'Development and Housing Director',
  'Development Associate',
  'Development Coordinator',
  'Development Director',
  'Development Engineer',
  'Development Manager',
  'Development Officer',
  'Development System Efficiency Manager',
  'Device Engineer',
  'Device Test Engineer',
  'Diabetes Educator',
  'Diagnostician',
  'Diagnostic Medical Sonographer',
  'Diagnostic Radiologic Technologist',
  'Diagnostic Radiologist',
  'Diagnostic Radiologist, Active Staff Physician at Community Hospital',
  'Diagrammer',
  'Dial-Screw Assembler',
  'Dialysis Patient Care Technician',
  'Dialysis Technician',
  'Diamond Polisher',
  'Diamond Setter',
  'Didactic Program in Dietetics Director',
  'Die Cast Die Maker',
  'Die Casting Machine Operator',
  'Diesel Mechanic',
  'Diesel Technician',
  'Diet Aide',
  'Dietary Aid',
  'Dietary Aide',
  'Dietary Manager',
  'Diet Clerk',
  'Dietetic Intern',
  'Dietician',
  'Dietitian',
  'Diffusion Furnace Operator',
  'Digital Archivist',
  'Digital Artist',
  'Digital Computer Systems Analyst',
  'Digital Content Coordinator',
  'Digital Court Reporter',
  'Digital Editor',
  'Digital Librarian',
  'Digital Marketing Strategist',
  'Digital Measurement Advisor',
  'Digital Media Coordinator',
  'Digital Media Designer',
  'Digital Media Manager',
  'Digital Media Producer',
  'Digital Music Instructor',
  'Digital Photographer',
  'Digital Photo Printer',
  'Digital Pre Press Operator',
  'Digital Print Operator',
  'Digital Producer',
  'Digital Production Manager',
  'Digital Publishing Specialist',
  'Digital Retoucher',
  'Digital Service Engineer',
  'Digital Strategist',
  'Digital Tech (Digital Technician)',
  'Dimensional Engineer',
  'Dining Room Host/Hostess',
  'Dining Room Manager',
  'Dining Room Supervisor',
  'Dining Services Director',
  'Diploma Maker',
  'Direct Care Professional',
  'Direct Care Worker',
  'Directional Driller',
  'Direct Mail Coordinator',
  'Director',
  'Director Career Services',
  'Director Clinical Information Services',
  'Director, Clinical Pharmacology',
  'Director Construction Services',
  'Director, Corporate Security',
  'Director Design',
  'Director, Educational Research and Product Strategy',
  'Director, Emergency Ophthalmology Services',
  'Director Employee Safety and Health',
  'Director Engineering',
  'Director, Global Ethics & Compliance and Assistant General Counsel',
  'Director, Global Intelligence',
  'Director, Hydrogen Storage Engineering',
  'Director, Informatics',
  'Director, Inpatient Headache Program',
  'Director, Learning and Development',
  'Director, Medical Writing',
  'Director, Metabolism',
  'Director Multiple Sclerosis Center',
  'Director of Academic Support',
  'Director of Administration',
  'Director of Admissions',
  'Director of Adult Epilepsy',
  'Director of Application Development',
  'Director of Athletics',
  'Director of Audience Generation, Search, & Analytics',
  'Director of Audio Visual Aids',
  'Director of Bioinformatics and Trait Discovery',
  'Director of Business Development',
  'Director of Capital Giving',
  'Director of Catering',
  'Director of Catering and Convention Services',
  'Director of Cell Biology',
  'Director of Child Care Center',
  'Director of Child Welfare Services',
  'Director of Civil Defense',
  'Director of Clinical Education',
  'Director of Clinical Services',
  'Director of Collections and Archives',
  'Director of Communications',
  'Director of Community Center',
  'Director of Community Life',
  'Director of Compensation',
  'Director of Compliance',
  'Director of Conference Services',
  'Director of Conference Services and Catering',
  'Director of Content and Programming',
  'Director of Convention Services',
  'Director of Creative Services, Consumer Products',
  'Director of Dance',
  'Director of Data Operations',
  'Director of Development and Public Relations',
  'Director of District Office',
  'Director of Educational Services',
  'Director of Employee Development',
  'Director of Employer Services',
  'Director of Engineering, Quality & Program Management.',
  'Director of Enterprise Strategy',
  'Director of Entertainment',
  'Director of Events',
  'Director of Exhibit Development',
  'Director of Family Service Center',
  'Director of Field Coordination',
  'Director of Field Service',
  'Director of Finance',
  'Director of Food and Beverage',
  'Director of Food and Nutrition Services',
  'Director of Front Office',
  'Director of Fundraising',
  'Director of Group Counseling Program',
  'Director of Group Sales',
  'Director of Guidance in Public Schools',
  'Director of Health Education',
  'Director of Health Services',
  'Director of Housing',
  'Director of Housing and Energy Services',
  'Director of Human Resources',
  'Director of Industrial Relations',
  'Director of Information Services',
  'Director of Information Technology',
  'Director of Institutional Research',
  'Director of Landscape Architecture and Planning',
  'Director of Maintenance',
  'Director of Manufacturing',
  'Director of Market Analysis',
  'Director of Marketing and Public Relations',
  'Director of Market Intelligence',
  'Director of Materials',
  'Director of Music Therapy',
  'Director of Neighborhood Service Center',
  'Director of Network',
  'Director of Nurses Registry',
  'Director of Nursing',
  'Director of Nursing (DON)',
  'Director of Nursing Service',
  'Director of Occupational Therapy',
  'Director of Online Marketing Strategy & Performance',
  'Director of Operations',
  'Director of Operations and Creative Services',
  'Director of Personnel',
  'Director of Photography',
  'Director of Physical Therapy',
  'Director of Placement',
  'Director of Preclinical Research',
  'Director of Product Development',
  'Director of Professional Services',
  'Director of Programming and Special Events',
  'Director of Public Affairs',
  'Director of Public Information',
  'Director of Purchasing',
  'Director of Quality',
  'Director of Quantitative Research',
  'Director of Records Management',
  'Director of Rehabilitation',
  'Director of Research and Development',
  'Director of Research Center',
  'Director of Residence Life',
  'Director of Respiratory Therapy',
  'Director of Safety',
  'Director of Sales',
  'Director of Sales and Market Development',
  'Director of Sales Marketing',
  'Director of Search Engine Optimization (Director of SEO)',
  'Director of Securities and Real Estate',
  'Director of Social Services',
  'Director of Spa and Guest Experience',
  'Director of Special Events',
  'Director of Sports Medicine, Partner, Orthopedic and Spine Specialists',
  'Director of Staff Development',
  'Director of Strategic Sourcing',
  'Director of Student Affairs',
  'Director of Student Financial Aid',
  'Director of Sustainability',
  'Director of Technology',
  'Director of Testing',
  'Director of the Biophysics Facility',
  'Director of Therapeutic Activities',
  'Director of Therapeutic Recreation',
  'Director of Training',
  'Director of Veterans Affairs',
  'Director of Vital Statistics',
  'Director of Vocational Evaluations/Rehabilitation Counselor',
  'Director of Volunteer Services',
  'Director of Workforce Development',
  'Director, Product Safety',
  'Director, Search Marketing Strategies',
  'Director, Security Risk Management',
  'Director, Selection and Administration',
  'Director Water and Waste Services',
  'Directory Assistance Operator',
  'Directory Operator',
  'Direct Response Consultant',
  'Direct Selling Counselor',
  'Direct Service Professional',
  'Direct Service Provider',
  'Direct Support Profesional',
  'Direct Support Professional',
  'Direct Support Specialist',
  'Direct Support Staff',
  'Direct Support Staff Member',
  'Disability Hearing Officer',
  'Disability Program Navigator',
  'Disability Specialist',
  'Disassembler',
  'Disaster Recovery Manager',
  'Disaster Recovery Specialist',
  'Disaster Response Director',
  'Disbursing Officer',
  'Discharge Planner',
  'Disc Jockey (DJ)',
  'Discount Clerk',
  'Discovery Guide',
  'Disc Sander',
  'Dishwasher',
  'Disintegrator',
  'Dismantler',
  'Dispatch Clerk',
  'Dispatcher',
  'Dispatcher (Dispatch)',
  'Dispatch Manager',
  'Dispatch Supervisor',
  'Dispensary Attendant',
  'Dispensing Lead',
  'Display Advertising Sales Representative',
  'Display Designer',
  'Display Director',
  'Display Manager',
  'Disposal Operator',
  'Disposition Clerk',
  'Dispute Resolution Analyst',
  'Dispute Resolution Specialist',
  'Dispute Specialist',
  'Distance Education Director',
  'Distance Learning Program Coordinator',
  'Distance Learning Unit Leader',
  'Distillation Operator',
  'Distiller',
  'Distillery Manager',
  'Distributed Energy Systems Consultant',
  'Distributed Generation Project Manager',
  'Distributing Clerk',
  'Distribution Center Manager',
  'Distribution Coordinator',
  'Distribution Dispatcher',
  'Distribution Engineering Technologist',
  'Distribution Manager',
  'Distribution Operations Manager',
  'Distribution Operations Supervisor',
  'Distribution Supervisor',
  'Distribution Warehouse Manager',
  'Distributor',
  'District Attorney',
  'District Court Administrator',
  'District Engineer',
  'District Fire Management Officer',
  'District Leader',
  'District Loss Prevention Manager',
  'District Manager',
  'District or District Office Director',
  'District Sales Coordinator',
  'District Sales Manager',
  'District Sales Representative',
  'Ditch Digger',
  'Divemaster',
  'Diver',
  'Diversified Crops Farmer',
  'Diversity Manager',
  'Dive Superintendent',
  'Divisional Storekeeper',
  'Division Chair',
  'Division Chief',
  'Division Director',
  'Division Engineer',
  'Division Head',
  'Division Manager',
  'Division Merchandise Manager (DMM)',
  'Division Officer, Weapons Department',
  'Division Order Technician',
  'Division Supervisor',
  'Divorce Lawyer',
  'DNA Sequencing Associate',
  'Do All Operator',
  'Docent',
  'Dockmaster',
  'Dock Supervisor',
  'Dockworker',
  'Dock Worker',
  'Doctor',
  'Doctor Assistant',
  'Doctor (Dr)',
  'Doctor of Optometry (OD)',
  'Doctor of Pharmacy',
  'Doctor of Veterinary Medicine (DVM)',
  "Doctor's Assistant",
  'Documentary Script Writer',
  'Documentation Designer',
  'Documentation Engineer',
  'Documentation Specialist',
  'Documentation Specialist/Technical Writer',
  'Document Clerk',
  'Document Controller',
  'Document Control Specialist',
  'Document Management Consultant',
  'Document Manager',
  'Document Restorer',
  'Document Reviewer',
  'Document Specialist',
  'Dog Groomer',
  'Dog Sitter',
  'Dog Trainer',
  'Dog Walker',
  'Domestic Helper',
  'Donation Worker',
  'Donor Relations Officer',
  'Donor Specialist',
  'Doorman',
  'Doorperson',
  'Door Technician',
  'Door to Door Salesman',
  'Door to Door Selling Agent',
  'Dosimetrist',
  'Dot Etcher',
  'Double',
  'Doughnut Fryer',
  'Doula',
  'Downstream Biomanufacturing Technician',
  'Drafter',
  'Drafting Detailer',
  'Drafting Technician',
  'Draftsman',
  'Draftsperson',
  'Drainage Design Coordinator',
  'Drainage Engineer',
  'Drama Director',
  'Drama Teacher',
  'Dramatist',
  'Draper',
  'Draughtsman',
  'Drawing Operator',
  'Drawing Tracer',
  'Dredgemaster',
  'Dresser',
  'Dressmaker',
  'Driller',
  'Drilling and Production Superintendent',
  'Drilling Assistant',
  'Drilling Engineer',
  'Drilling Fluids Specialist',
  'Drilling Foreman',
  'Drilling Manager',
  'Drilling Supervisor',
  'Drink Box Mechanic',
  'Drive Away Driver',
  'Drive Man',
  'Driver',
  'Driver/Guide',
  'Driver Manager',
  'Driver/Merchandiser',
  'Driver Messenger',
  "Driver's Education Instructor",
  'Driver Trainer',
  'Driving Instructor',
  'Drug Clerk',
  'Drug Coordinator',
  'Drug Discovery Informatics Specialist',
  'Druggist',
  'Drug Regulatory Affairs Specialist',
  'Drug Safety Scientist',
  'Drummer',
  'Dry Cleaner',
  'Dry Goods Clerk',
  'Drywall Application Supervisor',
  'Drywall Hanger',
  'DTP Operator',
  'Durability Technician',
  'Durable Medical Equipment Technician (DME Tech)',
  'Duty Officer',
  'Dyer',
  'Dynamic Balancer',
  'Dyno Technician',
  'Early Childhood Development Manager',
  'Early Childhood Education Instructor',
  'Early Childhood Services Coordinator',
  'Early Childhood Services Director',
  'Early Childhood Special Educator (EC Special Educator)',
  'Early Childhood Teacher',
  'Early Head Start Director',
  'Early Interventionist',
  'Earth Mover',
  'Earth Observations Chief Scientist (NASA)',
  'Earth Science Faculty Member',
  'Eating Disorder Psychologist',
  'E-Business Project Manager',
  'Echocardiologist',
  'Echo Tech (Echocardiographic Technician)',
  'Eco-Industrial Development Consultant',
  'Ecologist',
  'Ecology Teacher',
  'E-Commerce Analyst',
  'E-Commerce Developer',
  'E-Commerce Director',
  'E-Commerce Manager',
  'Ecommerce Marketing Manager',
  'E-Commerce Project Manager',
  'E-Commerce Specialist',
  'E-commerce Strategist',
  'Econometrician',
  'Economic Analysis Director',
  'Economic Analyst',
  'Economic Developer',
  'Economic Development Coordinator',
  'Economic Development Specialist',
  'Economic Research Analyst',
  'Economics Department Chair',
  'Economics Instructor',
  'Economics Professor',
  'Economics Teacher',
  'Economist',
  'Ecotherapist',
  'Eddy-Current Inspector',
  'Edging Supervisor',
  'Editing Clerk',
  'Editor',
  'Editorial Assistant',
  'Editorial Director',
  'Editorial Specialist',
  'Editorial Writer',
  'Editor in Chief',
  'Educational Assistant',
  'Educational Director',
  'Educational Program Director',
  'Educational Programming Director',
  'Educational Resource Coordinator',
  'Educational Specialist',
  'Education and Development Manager',
  'Education and Information Coordinator',
  'Education and Training Manager',
  'Education Consultant',
  'Education Coordinator',
  'Education Counselor',
  'Education Courses Sales Representative',
  'Education Dean',
  'Education Department Chair',
  'Education Director',
  'Education Finance Processor',
  'Education Specialist',
  'Education Supervisor',
  'Educator',
  'Efficiency Analyst',
  'Efficiency Expert',
  'Efficiency Manager',
  'Egg Breaking Machine Operator',
  'Egg Candler',
  'Egyptologist',
  'Elastic Tape Inserter',
  'Elder',
  'Elderly Companion',
  'E-Learning Coordinator',
  'E-Learning Designer',
  'E-Learning Developer',
  'E-Learning Manager',
  'Election Assistant',
  'Electric Accounting Machine Operator (EAM Operator)',
  'Electrical and Electronics Installer and Repairer',
  'Electrical and Instrumentation Mechanic',
  'Electrical and Instrument Mechanic',
  'Electrical Apprentice',
  'Electrical Assistant',
  'Electrical Contacts Adjuster',
  'Electrical Contractor',
  'Electrical Controls Engineer',
  'Electrical Design Engineer',
  'Electrical Designer',
  'Electrical Design Technologist',
  'Electrical/Electronic Sales Representative',
  'Electrical Electronics Engineer',
  'Electrical Energy Distribution Technician',
  'Electrical Energy Storage/Distribution Technician',
  'Electrical Engineer',
  'Electrical Engineering Manager and Product Development Project Manager',
  'Electrical Engineering Technician',
  'Electrical Estimator',
  'Electrical Foreman',
  'Electrical Helper',
  'Electrical High Tension Tester',
  'Electrical Inspector',
  'Electrical Installation Supervisor',
  'Electrical Maintenance Technician',
  'Electrical Mechanic',
  'Electrical Panel Builder',
  'Electrical Power Station Technician',
  'Electrical Products Sales Engineer',
  'Electrical Project Engineer',
  'Electrical Superintendent',
  'Electrical Supervisor',
  'Electrical Systems Designer',
  'Electrical System Specialist',
  'Electrical Technician',
  'Electrical Tech/Project Manager',
  'Electrical Troubleshooter',
  'Electric Car Operator',
  'Electric Distribution Department Manager',
  'Electric-Gas Appliances Demonstrator',
  'Electric Golf Cart Repairers',
  'Electrician',
  'Electric Installer',
  'Electricity Trading Analyst',
  'Electric Power Superintendent',
  'Electric Utility Lineworker',
  'Electric Vehicle Electrician',
  'Electrochemist',
  'Electroformer',
  'Electroless Plater',
  'Electrologist',
  'Electrolysist',
  'Electro Mechanic',
  'Electromechanical Assembly Technician',
  'Electro-Mechanical Engineer',
  'Electronic Assembler',
  'Electronic Component Processor',
  'Electronic components Assembler',
  'Electronic Data Interchange System Developer (EDI System Developer)',
  'Electronic Data Processing Auditor (EDP Auditor)',
  'Electronic Funds Transfer Coordinator',
  'Electronic Home Entertainment Equipment Repairer',
  'Electronic Musical Instrument Repairer',
  'Electronic News Gathering Camera-Person (ENG Camera-Person)',
  'Electronic Publications Specialist',
  'Electronic Resources Librarian',
  'Electronic Sales and Service Technician',
  'Electronics Design Engineer',
  'Electronics Engineer',
  'Electronics Engineering Manager',
  'Electronics Engineering Technician',
  'Electronic Sensing Equipment Assembler',
  'Electronic Signals Intelligence Exploitation',
  'Electronic Specialist',
  'Electronics Technician',
  'Electronics Technology Department Chair',
  'Electronic System Engineer',
  'Electronic Systems Security Assessment',
  'Electronic Systems Technician (EST)',
  'Electronic Technician',
  'Electronic Train Control Technician',
  'Electronic Transaction Implementer',
  'Electronic Warfare Technician (Advanced Application)',
  'Electron Microscopist',
  'Electro-optics Engineer',
  'Electroplater',
  'Electrostatic Powder Coating Technician',
  'Electrotherapist',
  'Electrotyper',
  'Elementary Art Teacher',
  'Elementary Education Teacher',
  'Elementary Principal',
  'Elementary School Band Director',
  'Elementary School Counselor',
  'Elementary School Principal',
  'Elementary School Teacher',
  'Elementary School Tutor',
  'Elementary Teacher',
  'Element Burner',
  'Elephant Tamer',
  'Elevators, Escalators, and Dumbwaiters Sales Representative',
  'Eligibility and Occupancy Interviewer',
  'Eligibility Specialist',
  'Eligibility Worker',
  'e-mail System Administrator',
  'Embalmer',
  'Embedded Systems Software Developer',
  'Embosser',
  'Embroiderer',
  'Embroidery Finisher',
  'Embryologist',
  'Emergency Department RN (Emergency Department Registered Nurse)',
  'Emergency Management Coordinator',
  'Emergency Management System Director (EMS Director)',
  'Emergency Medical Service Coordinator',
  'Emergency Medical Service Manager',
  'Emergency Medical Technician (EMT)',
  'Emergency Medicine Nurse Practitioner',
  'Emergency Medicine Physician',
  'Emergency Planning and Response Manager',
  'Emergency Preparedness Coordinator',
  'Emergency Response Team Leader',
  'Emergency Room Doctor (ER Doctor)',
  'Emergency Room Nurse',
  'Emergency Room RN',
  'Emergency Services Director',
  'Emergency Vehicle Operations Instructor',
  'Emerging Solutions Executive',
  'Emissions Inspector',
  'Emotional Support Teacher',
  'Employee Benefits Coordinator',
  'Employee Benefits Director',
  'Employee Benefits Manager',
  'Employee Benefits Specialist',
  'Employee Benefits Supervisor',
  'Employee Development Manager',
  'Employee Development Specialist',
  'Employee Health Maintenance Program Specialist',
  'Employee Relations Assistant',
  'Employee Relations Manager',
  'Employee Service Officer',
  "Employee's Representative",
  'Employee Training Specialist',
  'Employee Wellness/Fitness Coordinator',
  'Employer Relations Representative',
  'Employment Advisor',
  'Employment Attorney',
  'Employment Benefits or Pensions Retirement Plan Specialist',
  'Employment Consultant',
  'Employment Coordinator',
  'Employment Counselor',
  'Employment Instructional Associate (EIA)',
  'Employment Recruiter',
  'Employment Research and Planning Director',
  'Employment Services Director',
  'Employment Specialist',
  'Employment Supervisor',
  'EMS Driver (Emergency Medical Services Driver)',
  'EMT-I/99',
  'EMT-P',
  'Encapsulator',
  'End Maker',
  'Endocrinologist',
  'Endodontist',
  'Endoscopy RN (Endoscopy Registered Nurse)',
  'Endoscopy Technician',
  'End-User Support Specialist',
  'Energy Analyst',
  'Energy and Sustainability Manager',
  'Energy Broker',
  'Energy Conservation Director',
  'Energy Conservation Engineer',
  'Energy Conservation Representative',
  'Energy Consultant',
  'Energy Director',
  'Energy Efficiency Finance Manager',
  'Energy Engineer',
  'Energy Manager',
  'Energy Projects Lead',
  'Energy Sales Broker',
  'Energy Scheduler',
  'Energy Specialist',
  'Energy Trader',
  'Energy Trading Analyst',
  'Enforcement Officer',
  'Engagement Director',
  'Engineer',
  'Engineer Assistant',
  'Engineer/Conductor',
  'Engineering Aide',
  'Engineering Assistant',
  'Engineering Associate',
  'Engineering Construction Inspector',
  'Engineering Consultant',
  'Engineering Department Chair',
  'Engineering Director',
  'Engineering Geologist',
  'Engineering Group Manager',
  'Engineering Inspection Assistant',
  'Engineering Instructor',
  'Engineering Lab Technician',
  'Engineering Leader',
  'Engineering Manager',
  'Engineering Officer',
  'Engineering Physical Science Technician',
  'Engineering Planning Survey Technician',
  'Engineering Production Liaison',
  'Engineering Production Operations Director',
  'Engineering Program Manager',
  'Engineering Project Manager',
  'Engineering Research Manager',
  'Engineering Scientist',
  'Engineering Specialist',
  'Engineering Supervisor',
  'Engineering Systems Analyst',
  'Engineering Technical Analyst',
  'Engineering Technician',
  'Engineering Technician (Engineering Tech)',
  'Engineering Vice President',
  'Engineer of System Development',
  'Engineer Technical Staff',
  'Engineman',
  'Engine Mechanic',
  'English Instructor',
  'English Language Learner Tutor (ELL Tutor)',
  'English Professor',
  'English Teacher',
  'English Tutor',
  'Engraver',
  'Engrosser',
  'Enlisted Advisor',
  'Enrolled Agent',
  'Enrollment Coordinator',
  'Enrollment Counselor',
  'Enrollment Management Director',
  'Enrollment Management Vice President',
  'Enrollment Services Dean',
  'Enrollment Services Vice President',
  'Enrollment Specialist',
  'Enterprise Architect',
  'Enterprise Integration Manager',
  'Enterprise Records Analyst',
  'Enterprise Resource Planner',
  'Enterprise Sales Person',
  'Enterprise Systems Administrator',
  'Enterprise Systems Engineer',
  'Entertainer',
  'Entertainment Director',
  'Entertainment Manager',
  'Entomologist',
  'Entrepreneurial Finance Professor',
  'Enumerator',
  'Envelope Stuffer',
  'Environmental Affairs Corporate Director',
  'Environmental Affairs, Safety, and Security Manager',
  'Environmental Assistant (Housekeeping)',
  'Environmental Business Development Associate',
  'Environmental Change Analyst',
  'Environmental Conservation Officer',
  'Environmental Construction Program Manager',
  'Environmental Consultant',
  'Environmental Educator',
  'Environmental Engineer',
  'Environmental Field Office Manager',
  'Environmental Field Professional',
  'Environmental Geology Laboratory Instructor',
  'Environmental Health Aide',
  'Environmental Health and Safety Coordinator (EHS Coordinator)',
  'Environmental Health and Safety Director (EHS Director)',
  'Environmental, Health, and Safety EHS Leader',
  'Environmental Health and Safety Manager',
  'Environmental Health and Safety Specialist',
  'Environmental Health and Safety Specialist (EHS Specialist)',
  'Environmental Health, Safety, and Engineering Manager',
  'Environmental Health Specialist',
  'Environmental Issues Instructor',
  'Environmentalist',
  'Environmental Journalist',
  'Environmental Marketer',
  'Environmental Planner',
  'Environmental Programs Specialist',
  'Environmental Projects Advisor',
  'Environmental Protection Officer',
  'Environmental Protection Specialist',
  'Environmental Research Project Manager',
  'Environmental Resource Specialist',
  'Environmental Restoration Program Manager (ERP Manager)',
  'Environmental Science Program Director',
  'Environmental Science Research Center Director',
  'Environmental Scientist',
  'Environmental Services Director',
  'Environmental Services Manager',
  'Environmental Services Supervisor (EVS)',
  'Environmental Specialist',
  'Environmental Studies Department Chair',
  'Environmental Sustainability Manager',
  'Environmental Technician',
  'Environment Coordinator',
  'Environment Friendly Landscape Designer',
  'Epidemiologist',
  'Epidemiology Investigator',
  'Epoxy Coatings Installer',
  'Equal Employment Opportunity Investigator',
  'Equal Employment Opportunity Representative',
  'Equalizer',
  'Equestrian',
  'Equipment Engineer',
  'Equipment Installation Professional',
  'Equipment Installer',
  'Equipment Manager',
  'Equipment Operator',
  'Equipment Operator (EO)',
  'Equipment Service Lead',
  'Equipment Specialist',
  'Equipment, Supplies, and Tools Purchasing Agent',
  'Equipment Validation Engineer',
  'Equities Trader',
  'Equity Analyst',
  'Equity Research Analyst',
  'Equity Trader',
  'Eradicator',
  'Escrow Agent',
  'Escrow Assistant',
  'Escrow Officer',
  'Escrow Representative',
  'ESL Instructor (English as a Second Language Instructor)',
  'ESL Teacher (English as a Second Language Teacher)',
  'ESL Tutor (English as a Second Language Tutor)',
  'ESOL Instructor (English for Speakers of Other Languages Instructor)',
  'Establishment Guide',
  'Estate Administrator',
  'Estate Planning Attorney',
  'Estate Planning Counselor',
  'Esthetician',
  'Esthetician/Owner',
  'Esthetician Spa',
  'Estimator',
  'Estimator Project Manager',
  'Ethanol Quality Leader',
  'Ethics Manager',
  'Ethics Officer',
  'ETL Developer',
  'Evaluation Engineer',
  'Evaluation Manager',
  'Evaluator',
  'Evaporator',
  'Event AV Operator',
  'Event Coordinator',
  'Event Decorator and Designer',
  'Event Designer',
  'Event Manager',
  'Event Organizer',
  'Event Planner',
  'Event Planning Manager',
  'Event Promoter',
  'Events Manager',
  'Event Specialist',
  'Event Staff',
  'Event Staff Member',
  'Evidence Technician',
  'Eviscerator',
  'Examination Proctor',
  'Examiner',
  'Examiner of Currency',
  'Examining Officer',
  'Exam Proctor',
  'Ex Assistant/Program Director',
  'Excavator',
  'Exceptional Children Teacher (EC Teacher)',
  'Exceptional Student Education Aide (ESE Aide)',
  'Exchange Operator',
  'Exec. Creative Director',
  'Executive Administrative Assistant',
  'Executive Administrator',
  'Executive Assistant',
  'Executive Associate',
  'Executive Chef',
  'Executive Chef (Ex Chef)',
  'Executive Coach',
  'Executive Director',
  'Executive Director of Content Development',
  'Executive Director of Development and Alumni Relations',
  'Executive Director of Development, Gift Planning',
  'Executive Editor',
  'Executive Housekeeper',
  'Executive Officer, Special Warfare Team',
  'Executive Producer',
  'Executive Recruiter',
  'Executive Relations Specialist',
  'Executive Secretary',
  'Executive Sous Chef',
  'Executive Staff Assistant',
  'Executive Vice President',
  'Executive Vice President (EVP)',
  'Executive Vice President of Sales',
  'Executive Wellness Programs Director',
  'Exercise Physiologist',
  'Exercise Physiologist Certified (EPC)',
  'Exercise Physiologist, Lifestyle and Weight Management Consultant',
  'Exercise Science Instructor',
  'Exercise Specialist',
  'Exhibition Designer',
  'Exhibitions and Collections Manager',
  'Exhibitions Curator',
  'Exhibitor',
  'Exhibits Manager',
  'Expanded Function Dental Assistant',
  'Expanding Machine Operator',
  'Expansion Joint Builder',
  'Expediter',
  'Expedition Supervisor',
  'Expeditor',
  'Experience Planning Strategist',
  'Experimental Technician',
  'Expert Medical Writer',
  'Exploration Geologist',
  'Exploration Manager',
  'Explorer',
  'Export Administrator',
  'Export Clerk',
  'Export Coordinator',
  'Exporter',
  'Export Manager',
  'Export Specialist',
  'Express Clerk',
  'Extended Resource Developmentally Delayed Assistant (Extended Resource DD Assistant)',
  'Extension Educator',
  'Extension Service Specialist-in-Charge',
  'Extension Specialist',
  'Exterior Designer',
  'Exterminator',
  'External Grinder',
  'External Relations Director',
  'Extra',
  'Extraction Operator',
  'Extra Vehicular Activity Safety Engineer (EVA Engineer)',
  'Eye Clinic Manager',
  'Eye Surgeon',
  'FAA Certified Powerplant Mechanic',
  'Fabric and Accessories Estimator',
  'Fabrication and Layout Craftsman',
  'Fabrication Welder',
  'Fabricator',
  'Fabric Designer',
  'Fabrics and Material Cutter',
  'Face Boss',
  'Facialist',
  'Facilitator',
  'Facilities and Grounds Director',
  'Facilities Coordinator',
  'Facilities Director',
  'Facilities Engineer',
  'Facilities Maintenance Manager',
  'Facilities Manager',
  'Facilities Mechanical Design Engineer',
  'Facilities Operator',
  'Facilities Planner',
  'Facilities Supervisor',
  'Facilities Technician',
  'Facility Coordinator',
  'Facility Designer',
  'Facility Maintenance Technician',
  'Facility Manager',
  'Facility Supervisor',
  'Facility Technician',
  'Fact Checker',
  'Factor',
  'Factory Machine Computer Operator',
  'Factory Manager',
  'Factory Worker',
  'Faculty Dean',
  'Faculty Member',
  'Faculty Research Assistant',
  'Failure Analysis Technician (FA Technician)',
  'Fairing Man',
  'Faith Doctor',
  'Faith Healer',
  'Family Advocate',
  'Family and Consumer Sciences Professor (FACS Professor)',
  'Family Case Coordinator',
  'Family Consumer Science/Lifeskills Educator',
  'Family Consumer Science Teacher',
  'Family Counselor',
  'Family Day Care Provider',
  'Family Independence Case Manager',
  'Family Life Educator',
  'Family Living Educator',
  'Family Manager',
  'Family Medicine/Sports Medicine Specialist/Team Physician',
  'Family Nurse Practitioner',
  'Family Physician',
  'Family Practice MD (Family Practice Medical Doctor)',
  'Family Practice Medical Doctor (FP MD)',
  'Family Practice Physician',
  'Family Practice Physician Assistant',
  'Family Preservation Officer',
  'Family Resource Management Professor',
  'Family Resource Management Specialist',
  'Family Service Center Director',
  'Family Services Assistant (FSA)',
  'Family Support Specialist',
  'Family Support Worker',
  'Family Therapist',
  'Fan Installer',
  'Fans Clerk',
  'Farm Agent',
  'Farm Consultant',
  'Farm Contractor',
  'Farmer',
  'Farm Hand',
  'Farming',
  'Farm Manager',
  'Farm Mortgage Agent',
  'Farm Owner Operator',
  'Farm Product Purchaser',
  'Farm Worker',
  'Fashion Buyer',
  'Fashion Consultant',
  'Fashion Consultant, Selling',
  'Fashion Designer',
  'Fashion Director',
  'Fashion Editor',
  'Fashion Model',
  'Fashion Patternmaker',
  'Fashion Show Director',
  'Fast Food Restaurant Manager',
  'Fast Food Services Manager',
  'FBI Investigator',
  'Features Editor',
  'Feature Writer',
  'Federal Agent',
  'Federal Appellate Law Clerk',
  'Federal Mediator',
  'Fee Clerk',
  'Feed and Farm Management Adviser',
  'Feed Grinder',
  'Fence Installer',
  'Fermentation Engineer',
  'Fermenter',
  'Fermentologist',
  'Fiberglasser',
  'Fiberglass Finisher',
  'Fiberglass Roller',
  'Fiber Optic Assembly Worker',
  'Fiber Optic Splicer',
  'Fiber Technician',
  'Fiction Writer',
  'Field Adjuster',
  'Field Administrator',
  'Field Advisor',
  'Field Agent',
  'Field Artillery Officer',
  'Field Assistant',
  'Field Coordinator',
  'Field Court Researcher',
  'Field Director',
  'Field Engineer',
  'Field Geologist',
  'Field Hockey and Lacrosse Coach',
  'Field Inspector',
  'Field Installer',
  'Field Investigator',
  'Field Manager',
  'Field Marketing Representative',
  'Field Mechanical Meter Tester',
  'Field Mechanic/Site Lead',
  'Field Merchandiser',
  'Field Operations Farm Manager',
  'Field Operations Supervisor',
  'Field Operator',
  'Field Organizer',
  'Field Party Manager',
  'Field Radio Operator',
  'Field Reimbursement Manager',
  'Field Reporter',
  'Field Representative',
  'Field Research Associate',
  'Field Sales Representative',
  'Field Sampling Technician',
  'Field Service Engineer',
  'Field Service Manager',
  'Field Service Representative',
  'Field Services Director',
  'Field Service Technician',
  'Field Specialist',
  'Field Superintendent',
  'Field Supervisor',
  'Field Support Technician',
  'Field Technician',
  'Field Test Engineer',
  'Field Training Agent',
  'Field Training Officer (FTO)',
  'Fifth Hand',
  'Fig Caprifier',
  'Fight Manager',
  'Figure Clerk',
  'File Clerk',
  'File Cutter',
  'Filing Clerk',
  'Filling Operator',
  'Film Composer',
  'Film Critic',
  'Film Developer',
  'Film Developing Machine Operator',
  'Film Drying Machine Operator',
  'Film Editor',
  'Film Maker',
  'Film Producer',
  'Film Replacement Orderer',
  'Film Technician',
  'Film Touch-Up Inspector',
  'Filter Changing Technician',
  'Filters Assembler',
  'Final Assembler',
  'Finance Analyst',
  'Finance Assistant',
  'Finance Associate',
  'Finance Clerk',
  'Finance Consultant',
  'Finance Director',
  'Finance Manager',
  'Finance Officer',
  'Finance Professor',
  'Finance Specialist',
  'Finance Vice President',
  'Financial Accountant',
  'Financial Administrator',
  'Financial Adviser',
  'Financial Advisor',
  'Financial Aid Administrator',
  'Financial Aid Advisor',
  'Financial Aid Counselor',
  'Financial Aid Director',
  'Financial Aid Officer',
  'Financial Analyst',
  'Financial Assistance Specialist',
  'Financial Auditor',
  'Financial Center Manager',
  'Financial Compliance Examiner',
  'Financial Compliance Officer',
  'Financial Consultant',
  'Financial Controller',
  'Financial Coordinator',
  'Financial Counselor',
  'Financial Director',
  'Financial Economist',
  'Financial Institution Assistant Branch Manager',
  'Financial Institution Branch Manager',
  'Financial Institution President',
  'Financial Institution Vice President',
  'Financial Manager',
  'Financial Officer',
  'Financial Operations Clerk',
  'Financial Planner',
  'Financial Processing Clerk',
  'Financial Reporting Accountant',
  'Financial Representative',
  'Financial Responsibility Division Director',
  'Financial Retirement Plan Specialist',
  'Financial Secretary',
  'Financial Service Representative',
  'Financial Services Associate',
  'Financial Services Director',
  'Financial Services Representative',
  'Financial Services Sales Representative',
  'Financial Specialist',
  'Financial Supervisor',
  'Financial Systems Analyst',
  'Financial Writer',
  'Financier',
  'Financing Analyst',
  'Fine Artist',
  'Fine Arts Model',
  'Finger Lift Operator',
  'Fingernail Former',
  'Fingerprinter',
  'Finishing Area Operator',
  'Finishing Department Supervisor',
  'Finishing Manager',
  'Finish Opener',
  'Fire and Explosion Investigator',
  'Firearms Instructor',
  'Fire Captain',
  'Fire Chief',
  'Firefighter',
  'Fire Fighter',
  'Firefighter/EMT (Firefighter/Emergency Medical Technician)',
  'Firefighter/Medic',
  'Firefighter/Paramedic',
  'Fire Fighting Equipment Specialist',
  'Fire Information Officer',
  'Fire Inspections Coordinator',
  'Fire Investigator',
  'Fire Marshal',
  'Fire Protection Specialist',
  'Fire Safety Inspector',
  'Fire Suppression Captain',
  'Firmware Engineer',
  'First Assistant',
  'First Grade Teacher',
  'First-Line Production Supervisor',
  'First Mate',
  'First Officer',
  'First Responder',
  'Fiscal Accountant',
  'Fiscal Analyst',
  'Fiscal Manager',
  'Fiscal Officer',
  'Fiscal Technician',
  'Fish and Game Club Manager',
  'Fish and Wildlife Biologist',
  'Fisheries Biologist',
  'Fisheries Manager',
  'Fishery Biologist',
  'Fish Pitcher',
  'Fish Receiver',
  'Fitness and Wellness Manager',
  'Fitness Director',
  'Fitness Instructor',
  'Fitness Manager',
  'Fitness Trainer',
  'Fitter',
  'Fitter-Welder',
  'Fittings Finisher',
  'Fitting Supervisor',
  'Fixed Capital Clerk',
  'Fixed Income Director',
  'Fixed Income Portfolio Manager',
  'Fixed Income Trading Vice President',
  'Flag Decorator',
  'Flame Channeler',
  'Flash Welder',
  'Flat Clothier',
  'Flatwork Finisher',
  'Flavor Extractor',
  'Flavorings Compounder',
  'Fleet Manager',
  'Fleet Mechanic',
  'Fleet Technician',
  'Fletcher',
  'Flex O Writer Operator',
  'Flight Attendant',
  'Flight Attendant/Inflight Manager',
  'Flight Attendant, Inflight Services',
  'Flight Crew Time Clerk',
  'Flight Dispatcher',
  'Flight Engineer',
  'Flight Engineer, Performance Qualified',
  'Flight Instructor',
  'Flight Operation Coordinator',
  'Flight Operations Inspector',
  'Flight Operations Manager',
  'Flight Operations Specialist',
  'Flight Service Agent',
  'Flight Simulator Teacher',
  'Flight Test Data Acquisition Technician',
  'Flight Test Shop Mechanic',
  'Floating Operator',
  'Float Operator',
  'Flood Control Engineer',
  'Floor Covering Contractor',
  'Floorhand',
  'Flooring Professional',
  'Floor Installer',
  'Floor Manager',
  'Floorperson',
  'Floor Space Allocator',
  'Floor Supervisor',
  'Floor Tech (Floor Technician)',
  'Floorwalker',
  'Floral Arranger',
  'Floral Clerk',
  'Floral Department Specialist',
  'Floral Designer',
  'Floral Manager',
  'Floriculturist',
  'Florist',
  'Flour Broker',
  'Flower Arranger',
  'Flowmeter Test and Certification Mechanic',
  'Flow Trader',
  'Fluid Dynamicist',
  'Flute Teacher',
  'Flux Mixer',
  'Fly Finisher',
  'FM Announcer',
  'Focusing Machine Operator',
  'Follow Up Clerk',
  'Food and Beverage Controller',
  'Food and Beverage Director',
  'Food and Beverage Manager',
  'Food and Beverage Order Clerk',
  'Food and Beverage Supervisor',
  'Food and Drug Research Scientist',
  'Food and Nutrition Services Assistant',
  'Food Clerk',
  'Food Consultant',
  'Food Critic',
  'Food Handler',
  'Food Inspector',
  'Food Manager',
  'Food Order Delivery Runner',
  'Food Preparer',
  'Food Processing Plant Manager',
  'Food Product Inspector',
  'Food Production Manager',
  'Food Quality Technician',
  'Food Runner',
  'Food Safety Director',
  'Food Server',
  'Food Service Director',
  'Food Service Manager',
  'Food Service Worker',
  'Food Stylist',
  'Food Supervisor',
  'Food Taster',
  'Food Technologist',
  'Food Writer',
  'Foot and Ankle Surgeon',
  'Football Coach',
  'Footwear Machinery Instructor',
  'Footwear Stitcher',
  'Force Deployment Planning And Execution Officer',
  'Forecaster',
  'Foreign Clerk',
  'Foreign Correspondent',
  'Foreign Exchange Dealer',
  'Foreign Exchange Position Clerk',
  'Foreign Exchange Services Manager',
  'Foreign Exchange Trader',
  'Foreign Law Consultant',
  'Foreign Service Officer',
  'Foreign Student Adviser Teacher',
  'Foreign Trade Teacher',
  'Foreman',
  'Foreman/Pile Driving and Erection',
  'Forensic Psychologist',
  'Forensic Scientist',
  'Forensics (Speech and Debate) Team Director',
  'Forest and Conservation Worker',
  'Forester',
  'Forest Law and Policy Professor',
  'Forest Manager',
  'Forest Products Gatherer',
  'Forestry Technician',
  'Forex Trader',
  'Forge Operator',
  'Forklift Driver',
  'Forklift Operator',
  'Fork Operator',
  'Fork Truck Driver',
  'Format Director Inspiration Format Operations Manager',
  'Formation Testing Operator',
  'Format Proofreader',
  'Form Carpenter',
  'Form Designer',
  'Forming Department End Finder',
  'Formulation Technician',
  'Forward Air Controller/Air Officer',
  'Foster Winder',
  'Foundation Director',
  'Foundation Engineer',
  'Foundation Program Director',
  'Founder',
  'Foundry Hand',
  'Foundry Superintendant',
  'Fountain Manager',
  'F',
];

export const yearsOfExperienceList = [
  { key: '0 ⇾ 1', value: '0-1' },
  { key: '1 ⇾ 3', value: '1-3' },
  { key: '3 ⇾ 5', value: '3-5' },
  { key: '5 ⇾ 10', value: '5-10' },
  { key: '10 +', value: '10<' },
];

export const newsList = [
  { key: 'Funding', value: 'Funding' },
  {
    key: 'Mergers & Acquisitions',
    value: 'Mergers & Acquisitions',
  },
  { key: 'Executive Changes', value: 'Executive Changes' },
];

export const newsTimeRangeList = [
  { key: 'One Week', value: 'one_week' },
  {
    key: 'One Month',
    value: 'one_month',
  },
  { key: 'Three Months', value: 'three_months' },
  { key: 'Six Months', value: 'six_months' },
  { key: 'All Time', value: 'all_time' },
];

export const contactMethodsList = [
  { key: 'Mobile', value: 'Mobile' },
  {
    key: 'Phone',
    value: 'Phone',
    tooltipText: 'Includes work, home, office, and unknown phone numbers',
  },
  { key: 'Personal Email', value: 'Personal Email' },
  { key: 'Work Email', value: 'Work Email' },
];

export const TAX_ID_KEY = 'tax id';
export const CUSTOMER_ID_KEY = 'customer id';
export const LEAD_COMPOSITE_ID_KEY = 'lead composite id';

export const isEmptyFilter = (filter = {}) => {
  const filterKeys = Object.keys(filter);

  const validKeys = filterKeys.filter(
    (key) =>
      key !== 'selection_type' && key !== 'news_time_range' && key !== 'radius'
  );

  return validKeys.length === 0;
};
