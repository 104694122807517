import React, { useEffect, useState } from 'react';
import OrganizationService from '../../services/organization.service';
import { ReportTypes } from '../../components/reports/reports.constants';

const useFetchSubOrganizations = (
  organization,
  onLoad = true,
  limit = 10,
  page = 1,
  type = ReportTypes.Merchant
) => {
  const [loading, setLoading] = useState(false);
  const [subOrganizations, setSubOrganizations] = useState([]);
  const [search, setSearch] = useState();
  const MappedSubOrganizations = (sub) => {
    return {
      ...sub,
      customElement: <div className="pl-3">{sub.name}</div>,
    };
  };
  const fetchSubOrganizationsPromise = async () => {
    const parentOrganizationId = organization.id;
    return OrganizationService.getSubOrganizations(
      parentOrganizationId,
      search,
      page,
      limit
    );
  };
  const fetchSubOrganizations = async () => {
    try {
      setLoading(true);
      const { data } = await fetchSubOrganizationsPromise();
      setSubOrganizations(data.map(MappedSubOrganizations));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    organization?.id &&
      onLoad &&
      type === ReportTypes.Merchant &&
      fetchSubOrganizations();
  }, [organization, search]);

  return {
    search,
    loading,
    setSearch,
    subOrganizations,
    setSubOrganizations,
    fetchSubOrganizations,
    fetchSubOrganizationsPromise,
  };
};

export default useFetchSubOrganizations;
