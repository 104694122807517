import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import InputSearch from './InputSearch';
import InputDefault from './InputDefault';
import {
  degreeList,
  normalizedTitle,
  revenueListNew,
  employeeCountListNew,
  yearsOfExperienceList,
} from '../constants';
import CategoryMenu from '../CategoryMenu';
import InputRange from './InputRange';
import { INDUSTRIES_STORAGE_KEY } from '../../../../utils/Utils';
import TechnologiesSearch from './TechnologiesSearch';
import LocationSearch from './LocationSearch';
import ContactMethodType from './ContactMethodType';

const stepItems = {
  General: [
    {
      components: [
        {
          component: (
            <InputDefault
              name="Name"
              keyType="global"
              keyFilter="name"
              placeholder="Enter name search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <LocationSearch
              name="Location"
              keyType="location"
              keyFilter="location"
              placeholder="Enter a location..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              type="number"
              name="Zip Code"
              keyType="location"
              keyFilter="geo"
              placeholder="Enter zip code..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: <InputRange keyType="location" keyFilter="radius" />,
        },
      ],
      id: 1,
      icon: 'filter_alt',
      keyFilter: ['name', 'city', 'location', 'geo', 'radius'],
      titles: ['Name', 'City', 'Location', 'Zip Code'],
    },
  ],
  Occupation: [
    {
      components: [
        {
          component: (
            <InputDefault
              name="Job Title"
              keyType="occupation"
              keyFilter="current_title"
              placeholder="Enter job title search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputSearch
              name="Normalized Job Title"
              list={normalizedTitle}
              keyType="occupation"
              keyFilter="normalized_title"
              placeholder="Enter normalized job title search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              name="Department"
              keyType="occupation"
              keyFilter="department"
              placeholder="Enter department search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              name="Skills"
              keyType="occupation"
              keyFilter="skills"
              placeholder="Enter skills search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputSearch
              name="Years of Experience"
              list={yearsOfExperienceList}
              keyType="occupation"
              keyFilter="years_experience"
              customKey="key"
              placeholder="Enter years of experience search..."
              showLabelColon={false}
            />
          ),
        },
      ],
      id: 2,
      icon: 'business_center',
      keyFilter: [
        'current_title',
        'normalized_title',
        'department',
        'skills',
        'years_experience',
      ],
      titles: [
        'Job Title',
        'Normalized Job Title',
        'Department',
        'Skills',
        'Years of Experience',
      ],
    },
  ],
  Employer: [
    {
      components: [
        {
          component: (
            <InputDefault
              name="Company Name or Domain"
              keyType="employer"
              keyFilter="current_employer"
              placeholder="Enter company name or domain search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputSearch
              name="Employee Count"
              limit={9}
              keyType="employer"
              keyFilter="company_size"
              customKey="key"
              list={employeeCountListNew}
              placeholder="Enter employee count search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputSearch
              name="Revenue"
              limit={7}
              list={revenueListNew}
              customKey="key"
              keyType="employer"
              keyFilter="company_revenue"
              placeholder="Enter revenue search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <TechnologiesSearch
              name="Industry"
              keyType="employer"
              keyFilter="company_industry"
              placeholder="Enter a industry search..."
              showLabelColon={false}
              callKey={INDUSTRIES_STORAGE_KEY}
              callType="getIndustries"
            />
          ),
        },
      ],
      id: 3,
      icon: 'domain',
      keyFilter: [
        'current_employer',
        'company_size',
        'company_revenue',
        'company_industry',
      ],
      titles: ['Company Name', 'Employee Count', 'Revenue', 'Industry'],
    },
  ],
  Contact: [
    {
      components: [
        {
          component: (
            <ContactMethodType
              keyType="contact"
              keyFilter="contact_method"
              typeKeyType="contactSelection"
              typeKeyFilter="selection_type"
            />
          ),
        },
      ],
      id: 4,
      icon: 'group',
      titleWrapper: 'Contact Method',
      keyFilter: ['contact_method'],
      titles: ['Contact Method'],
    },
  ],
  Education: [
    {
      components: [
        {
          component: (
            <InputDefault
              name="Major"
              keyType="education"
              keyFilter="major"
              placeholder="Enter major search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              name="School"
              keyType="education"
              keyFilter="school"
              placeholder="Enter school search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputSearch
              name="Degree"
              limit={9}
              keyType="education"
              placeholder="Enter degree search..."
              keyFilter="degree"
              list={degreeList}
              showLabelColon={false}
            />
          ),
        },
      ],
      id: 5,
      icon: 'school',
      keyFilter: ['degree', 'school', 'major'],
      titles: ['Degree', 'School', 'Major'],
    },
  ],
  Web: [
    {
      components: [
        {
          component: (
            <InputDefault
              name="Domain"
              keyType="domain"
              keyFilter="domain"
              placeholder="Enter domain search..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              name="Contact Info"
              keyType="domain"
              keyFilter="contact_info"
              placeholder="Enter Email or Phone..."
              showLabelColon={false}
            />
          ),
        },
        {
          component: (
            <InputDefault
              name="Link"
              keyType="domain"
              keyFilter="link"
              placeholder="Enter Linkedin URL or Twitter handle..."
              showLabelColon={false}
            />
          ),
        },
      ],
      id: 6,
      icon: 'language',
      keyFilter: ['domain', 'contact_info', 'link'],
      titles: ['Domain', 'Contact Info', 'Link'],
    },
  ],
  Other: [
    {
      components: [
        {
          component: (
            <InputDefault
              name="Description"
              keyType="other"
              keyFilter="keyword"
              placeholder="Enter a keyword or Linkedin URL..."
              showLabelColon={false}
            />
          ),
        },
      ],
      id: 7,
      icon: 'more_horiz',
      keyFilter: ['keyword'],
      titles: ['Description'],
    },
  ],
};

const FilterItemMenu = ({
  title,
  data,
  setData,
  onEnter,
  active,
  setActive,
  selctedFilter,
}) => {
  const [activeTap] = useState(true);

  return (
    <div className="w-100">
      <Collapse in={activeTap}>
        <div>
          {Array.isArray(stepItems[title]) ? (
            <CategoryMenu
              stepItems={stepItems}
              title={title}
              data={data}
              setData={setData}
              onEnter={onEnter}
              active={active}
              setActive={setActive}
              selctedFilter={selctedFilter}
            />
          ) : (
            <></>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default FilterItemMenu;
